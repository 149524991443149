import React, {useContext, useEffect, useRef, useState} from "react";
import "./ProjectExplorerWidget.scss";

//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../hooks/useOnScreen";
import GoldenSparrowHorizontalPageTransition
    from "../PageTransition/GoldenSparrowHorizontalPageTransition/GoldenSparrowHorizontalPageTransition";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
gsap.registerPlugin(SplitText);
//----------------------------------------------------------------------------------------------------------------------


const ProjectExplorerWidget = () =>{

    const ref = useRef(null);
    const refRollOverFirstTitle = useRef(null);
    const refFlipUpFirstTitle = useRef(null);
    const refFlipUpSecondTitle = useRef(null);
    const onScreen = useOnScreen(ref);
    const GoldenSparrowHorizontalPageTransitionTl = gsap.timeline();
    const [isCurtainOpen, setIsCurtainOpen] = useState(true);
    const {setSize, setText, setVideoSource, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);
    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const VScrollIcon = GSHOMEDATA.gsBrandLogoArrowV;

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    useEffect(()=>{
        setTimeout(()=>{
            if(onScreen){
                setIsCurtainOpen(true);
                gsap.to(ref.current,{
                    backgroundColor: '#fff7eb',
                    ease: "Power4.easeInOut",
                    duration: 0.5
                })
                let subTitle = refRollOverFirstTitle.current;
                let splitSubTitle = new SplitText(subTitle, {type: "chars, words"});
                gsap.from(
                    splitSubTitle.chars,
                    {
                        duration: 2,
                        delay: 2,
                        scale:0,
                        display: 'none',
                        transformOrigin: '100% 50%',
                        ease: "Back.easeOut",
                        stagger: 0.025,
                        scrollTrigger: {
                            trigger: ref.current,
                            start: 'left 10%',
                            scrub: false,
                            toggleActions: 'play none none reverse',
                            // markers: true,
                            scroller: "#main-container",
                        }
                    }
                )
                //-----------------------------------------------------------------------------------------------
                let flipUpText = refFlipUpFirstTitle.current;
                let flipUpTextLine = new SplitText(flipUpText,{type: "lines"} );
                gsap.set(flipUpText, {perspective: 400});
                gsap.from(
                    flipUpTextLine.lines,
                    {
                        duration: 1,
                        delay: 2.5,
                        opacity:0,
                        rotationX:-120,
                        force3D:true,
                        transformOrigin:"50% 0% -150",
                        stagger: 0.1,
                        scrollTrigger: {
                            trigger: ref.current,
                            start: 'left 10%',
                            scrub: false,
                            toggleActions: 'play none none reverse',
                            // markers: true,
                            scroller: "#main-container",
                        }
                    }
                );
                //-------------------------------------------------------------------------------------------------
                let subTitle2 = refFlipUpSecondTitle.current;
                let splitSubTitle2 = new SplitText(subTitle2, {type: "chars, words"});
                gsap.from(
                    splitSubTitle2.chars,
                    {
                        duration: 2,
                        delay: 2.5,
                        scale:0,
                        display: 'none',
                        transformOrigin: '50% 100%',
                        ease: "Back.easeOut",
                        stagger: 0.025,
                        scrollTrigger: {
                            trigger: ref.current,
                            start: 'left 10%',
                            scrub: false,
                            toggleActions: 'play none none reverse',
                            scroller: "#main-container",
                        }
                    }
                )
            }
            else{
                setIsCurtainOpen(false);
                gsap.set(ref.current,{
                    backgroundColor: 'black'
                })
            }
        },1000)
    },[onScreen])

    return(
        <>
            {
                isCurtainOpen ? <GoldenSparrowHorizontalPageTransition timeline={GoldenSparrowHorizontalPageTransitionTl}/> : <></>
            }
            <div className="project-explorer-widget" ref={ref}
                    onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",VScrollIcon)}
                    onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
            >
                <div className="big-titles-wrapper">
                    <h1 className="project-explorer-title" ref={refRollOverFirstTitle}><span className="special-case-word">Let's Surf </span> the Exquisite</h1>
                    <h1 className="floating-title" ref={refFlipUpFirstTitle}>some of</h1>
                    <h1 className="project-explorer-title" ref={refFlipUpSecondTitle}>projects<span className="special-case-word">together</span></h1>
                </div>
                <div className="scroll-button-container">
                    <div className="scroll-button">
                        <div className="img-container">
                            {
                                isCurtainOpen ? <img src={GSHOMEDATA.cartoon} alt="" className="cartoon-gif"/> : <div/>
                            }
                        </div>
                    </div>
                </div>
                <div className="sub-title-container">
                    <span className="special-case-word">friendly warning!</span>
                    <span className="special-case-word">what you see next is gonna blow your mind</span>
                </div>
            </div>
        </>
    )

}
export default ProjectExplorerWidget;

