import React, {useContext, useEffect, useRef, useState} from "react";
import "./ProjectsDisplayPlayground.scss";


//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../hooks/useOnScreen";
gsap.registerPlugin(ScrollTrigger, SplitText);
//----------------------------------------------------------------------------------------------------------------------


const ProjectsDisplayPlayground = () =>{
    return(
        <div className="projects-display-playground">

        </div>
    )
}

export default ProjectsDisplayPlayground;
