

const BA_HomePage_VideoData = {
    "AAndSHomePageVideoData": [
        {
            id:0,
            CoffeeGrindingVid: "https://firebasestorage.googleapis.com/v0/b/barrelaged-8a64d.appspot.com/o/videos%2FCoffeeBeansGrinding.mp4?alt=media&token=3a898129-9469-48cf-96ac-804980ca1b9d" //sagarwarje2@gmail.com BarrelAged
        },
        {
            id:1,
            FluidShotVid: "https://firebasestorage.googleapis.com/v0/b/barrelaged-8a64d.appspot.com/o/videos%2FFluidShotHD.mp4?alt=media&token=7d2d80fb-e22e-40b5-a765-e9c1a5e71258"
        },
        {
            id:2,
            // BarrelAgedVid: "https://firebasestorage.googleapis.com/v0/b/barrelaged-8a64d.appspot.com/o/videos%2FAllFlaboursShot2k.mp4?alt=media&token=636b3b3d-e751-467d-90e8-ae4a4e377a27"
            BarrelAgedVid: "https://firebasestorage.googleapis.com/v0/b/barrelaged-8a64d.appspot.com/o/videos%2FAllFlaboursShotHD.mp4?alt=media&token=677e4587-b922-4661-95e2-6dbd173fce98"
        },
        {
            id:3,
            EndingVid: "https://firebasestorage.googleapis.com/v0/b/barrelaged-8a64d.appspot.com/o/videos%2FLastFireBlastScene.mp4?alt=media&token=5aa3f071-dc36-4dcb-b38c-91460b25391d"
        }
    ]
}

export default BA_HomePage_VideoData;
