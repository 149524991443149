import React, {useContext, useEffect, useState} from "react";
import "./WideContentList.scss";
import WideContentList_Data from "./WideContentList_Data";


//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import WideContentCard from "./WideContentCard/WideContentCard";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
gsap.registerPlugin(SplitText);
//----------------------------------------------------------------------------------------------------------------------



const WideContentListWidget = () =>{

    const [allProjectsData, setAllProjectData] = useState(WideContentList_Data.project_details);
    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const HorizontalScrollIconURI = GSHOMEDATA.gsBrandLogoArrowH;

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
        setFilterImplementation("grayScale(0%)")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    useEffect(() => {
        setTimeout(()=>{
                let splitTextForCloud = new SplitText("#cloudSplitChars", {type:"words"}),
                    tl =  gsap.timeline({repeat: -1, yoyo: true}),
                    numWords = splitTextForCloud.words.length;

                for(let i = 0; i < numWords; i++){
                    tl.from(splitTextForCloud.words[i],  {force3D:true,duration: 1, scale:Math.random() >0.5 ? 0 : 2, opacity:0}, Math.random());
                }
        })
    }, []);


    return(
        <div className="wide-content-list"
                onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",HorizontalScrollIconURI)}
                onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
        >
            <div className="abs-text-container">
                <h1 className="abs-text">stic Designs</h1>
                <h1 className="abs-text">usive Ideas</h1>
            </div>
            <div className="text-cloud-container">
                <h1 id="cloudSplitChars" className="cloud-para">
                    Art Design Technology - "Creativity is key to the future." 3D Animation - "illusion artist". Blender - Houdini React-JS Photoshop AWS Amplify
                    Redux Technology Bending reality. Realflow Flutter Maya AWS Substance Painter Firebase Android Studio Cinema4D PostgreSQL Embergen Android Studio Keyshot MongoDB MYSQL Davinci Resolve React-Native After Effects Hitfilm Pro .
                </h1>
            </div>
            <div className="layered-container">
                <div className="scrolling-tiles-wall" id={"scrolling-wall"}>
                    <div className="scrolling-tile-row" data-scroll data-scroll-direction="horizontal" data-scroll-speed="10" data-scroll-target="#scrolling-wall">
                        {
                            allProjectsData.map(eachProject => {
                                return(
                                    <WideContentCard key={eachProject.id} projectData={eachProject}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )

}
export default WideContentListWidget;
