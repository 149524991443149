import React, {Fragment} from "react";
import ReactDOM from "react-dom";
import styles from "./GoldenSparrowAboutPageModal.scss";

const AboutBackDrop = props =>{
    return <div className={styles.aboutBackdrop}/>
}

const AboutModalOverlay = props => {
    return(
        <div className={styles.aboutModal}>
            <div className={styles.aboutContent}>{props.children}</div>
        </div>
    )
}

const portalElements_About = document.getElementById('overlays-for-about-page-gs');

const AboutModal = (props) => {
    return(
        <Fragment>
            {ReactDOM.createPortal(<AboutBackDrop/>,portalElements_About)}
            {ReactDOM.createPortal(<AboutModalOverlay>{props.children}</AboutModalOverlay>,portalElements_About)}
        </Fragment>
    )
}
export default AboutModal;
