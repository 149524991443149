import React, {Fragment, useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import "./GoldenSparrowMainMenuModal.scss";
import {gsap} from "gsap";


const GoldenSparrowMainMenuModal_Backdrop = props =>{
    return <div className="golden-sparrow-main-menu-backdrop"/>
}

const GoldenSparrowMainMenuModalOverlay = props =>{


    return(
        <div className="golden-sparrow-main-menu-modal" >
            <div className="golden-sparrow-main-menu-content">
                {props.children}
            </div>
        </div>
    )
}

//
const goldenSparrowMainMenu_PortalElements = document.getElementById('overlays-for-golden-sparrow-studio-main-menu');

const GoldenSparrowMainMenuModal = (props) =>{
    return(
        <Fragment>
            {ReactDOM.createPortal(<GoldenSparrowMainMenuModal_Backdrop/>, goldenSparrowMainMenu_PortalElements)}
            {ReactDOM.createPortal(<GoldenSparrowMainMenuModalOverlay>{props.children}</GoldenSparrowMainMenuModalOverlay>,goldenSparrowMainMenu_PortalElements)}
        </Fragment>
    )
}

export default GoldenSparrowMainMenuModal
