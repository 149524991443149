import React,{useEffect, useRef} from "react";

import "./BarrelAgedBottomProgressBar.scss";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const BarrelAgedBottomProgressBar = () =>{






    return(
        <div className="barrel-aged-progressbar-container">
            <h1 className="brand-text">BARREL AGED</h1>
            <div className="progress-bar-bottom">
                <progress max="100" value="0" className={"progress-bar"} >
                </progress>
            </div>
            <h1 className="website-developed-by">GOLDEN SPARROW STUDIO</h1>
            <button className="shop-now-button">
                <h1 className="shop-button-txt">SHOP NOW</h1>
            </button>
        </div>
    )
}

export default BarrelAgedBottomProgressBar;
