import React from "react";
import "./ProductFeatures_AgingTechniques.scss";
import BA_HomePage_ImageData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_ImageData";



const ProductFeatures_AgingTechniques = () =>{
    return(
        <div className="features-aging-techniques">
            <div className="feature-details-pane">
                <h1 className="feature-title"><span className="special-font">U</span>n<span className="special-font">A</span>dulterated <span className="special-font">B</span>it based <span className="special-font">E</span>cstasy</h1>
                <h1 className="feature-desc">Barrel Aged comes in two forms - Ready to shake canned beverage if you like it cold or if you want to make hot brew coffee you can make it with powered form.Whichever you choose you get the same aroma and flavour.</h1>
                <div className="area-spacer"/>
            </div>
            <div className="feature-reference-content-pane">
                <div className="big-content-area">
                    <div className="image-wrapper">
                        <img src={BA_HomePage_ImageData.BAHomePageData[11].StrongFlavourImage} alt="" className="big-product-img"/>
                    </div>
                </div>
                <div className="small-content-area">
                    <img src={BA_HomePage_ImageData.BAHomePageData[10].MethodShownImage} alt="" className="display-image"/>
                </div>
            </div>
        </div>
    )
}

export default ProductFeatures_AgingTechniques;
