import React, {useContext} from "react";
import "./FlipBookSection.scss";
import FlipBookComponent from "./FlipBookComponent/FlipBookComponent";
import {ArnoldAndSonCursorContext} from "../../Widgets/Cursors/ArnoldAndSon_CustomCursor/ArnoldAndSonCursorManager";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";


const FlipBookSection = () =>{

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const dragImg = GSHOMEDATA.swipeLeftIcon;

    const { setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(ArnoldAndSonCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode, imageURI) =>{
        setSize(Size)
        setText(Text)
        setBlendMode(BlendMode)
        setImgSource(imageURI)
        setFilterImplementation("grayScale(0%)")
        // setImgSource("https://firebasestorage.googleapis.com/v0/b/shadowfightarena-99fe0.appspot.com/o/ezgif.com-gif-maker.gif?alt=media&token=d503b7ac-2f06-4d37-a24b-711e4b596a5a")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode, imageURI) =>{
        setSize(Size)
        setText(Text)
        setVideoSource("")
        setBlendMode(BlendMode)
        setImgSource(imageURI)
        // setImgSource("")
    }

    return(
        <div  className={"flipBookSection"}>
            <FlipBookComponent/>
        </div>
    )

}

export default FlipBookSection;
