import React from "react";
import "./ArtBlock.scss";

const ArtBlock = ({forumData}) =>{

    return(
        <div className="art-block">
            <img src={forumData.forumImage} alt="" className="forum-art"/>
            <a className="forum-name" href={forumData.forumLink} target={"_blank"}>{forumData.forumName}</a>
        </div>
    )
}
export default ArtBlock;
