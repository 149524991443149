import React,{useState,useEffect,useRef} from "react";
import "./IntroTransitionVideoForBarrelAged.scss";


import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
const  BrandVid = "https://firebasestorage.googleapis.com/v0/b/barrelaged-f6f88.appspot.com/o/videos%2FBarrelAged2k.mp4?alt=media&token=38232804-38f2-4026-8a13-26a4b234ca3b";  //sagarwarje3@gmail.com   BarrelAged
const BrandVidQuadHD = "https://firebasestorage.googleapis.com/v0/b/barrelaged-f6f88.appspot.com/o/videos%2FBarrel_QuadHD.mp4?alt=media&token=507ede69-befe-4878-9286-49eaabecb674" //sagarwarje3@gmail.com   BarrelAged

gsap.registerPlugin(ScrollTrigger)

const IntroTransitionVideoForBarrelAged = ({onBarrelAgedVideoFinishedCallBack}) =>{

    const ref = useRef(null);
    const [videoFinishedCallBack, setVideoFinishedCallBack] = useState(false);

    const [nativeScreenSize,setNativeScreenSize] = useState("");

    useEffect(()=>{
        if(videoFinishedCallBack){
            onBarrelAgedVideoFinishedCallBack(videoFinishedCallBack)
        }
    },[videoFinishedCallBack])


    useEffect(() => {
        setTimeout(()=>{
            getScreenResolution()
            //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            let direction = -1; // 1 = forward, -1 = backward scroll
            const   roll1 = roll(".rollingText-intro-screen", {duration: 8},  true),
                    roll2 = roll(".rollingText02-intro-screen", {duration: 8}, true),
                    scroll = ScrollTrigger.create({
                        // scroller: "#main-container",
                        // horizontal: true,
                        onUpdate(self) {
                            if (self.direction !== direction) {
                                direction *= -1;
                                gsap.to([roll1,roll2], {timeScale: direction, overwrite: true});
                            }
                        }
                    });
            // helper function that clones the targets, places them next to the original, then animates the xPercent in a loop to make it appear to roll across the screen in a seamless loop.
            function roll(targets, vars, reverse) {
                const tl = gsap.timeline({
                    repeat: -1,
                    onReverseComplete() {
                        this.totalTime(this.rawTime() + this.duration() * 10); // otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
                    }
                });
                vars = vars || {};
                vars.ease || (vars.ease = "none");
                gsap.utils.toArray(targets).forEach(el => {
                    let clone = el.cloneNode(true);
                    // el.parentNode.appendChild(clone);
                    gsap.set(clone, {position: "absolute", top: el.offsetTop, left: el.offsetLeft + (reverse ? -el.offsetHeight : el.offsetHeight)});
                    tl.to([el, clone], {yPercent: reverse ? 100 : -100, ...vars}, 0);
                });
                return tl;
            }
            //-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            gsap.set(ref.current,{transformOrigin: "center", scale: 0, opacity: 0});
            const tl = gsap.timeline({
                // repeat: -1,
                // yoyo: true,
                // repeatDelay: 0.5,
                defaults: { ease: "power3.inOut", duration: 0.8 }
            });
            tl.to(ref.current, { duration: 2,delay: 2, rotation: -720, scale: 0.8, width: "75vw",height: "80vh", opacity: 1 });
            tl.to(ref.current, { duration: 2, y: '60vh', width: "75vw",height: "80vh", opacity: 1 });
            tl.to(ref.current, { duration: 3, rotation: 720, scale: 1, y: '0vh', width: "75vw",height: "80vh", opacity: 1 });
            tl.add(proceedToStartVideo);
        })
    }, []);

    const getScreenResolution = () =>{
        // console.log(window.screen.width, window.screen.height, "Native Resolution!!!")
        if(window.screen.width <= 1920){
            // console.log("its Full HD ", window.screen.width)
            setNativeScreenSize("FULLHD")
        }else{
            // console.log("Its More than Full HD ", window.screen.width)
            setNativeScreenSize("ABOVEFULLHD")
        }
    }

    const proceedToStartVideo = () =>{
        setTimeout(startVideo,50);
    }
    const loadHomeScreenForBarrelProject = async() =>{
        await setVideoFinishedCallBack(true)
    }
    const handleLeaveBarrelAgedVideoScreen = async () =>{
        await setTimeout(stopVideo,1);
        await setTimeout(loadHomeScreenForBarrelProject,100)
    }
    const startVideo = async () => {
        const video = document.querySelector('#rotating_vid_barrel_aged');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = document.querySelector('#rotating_vid_barrel_aged');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const handleRightClick = (event) =>{
        event.preventDefault()
    }

    return(
        <div className="intro-transition-video-for-barrel-aged" onContextMenu={handleRightClick}>
            <div className="front-vertical-pillar-intro-screen" id={"idea-box-frontend"}>
                <div className="rollingText-intro-screen">
                    <h1 className="section-title-intro-screen"><span className="special-char-intro-screen">B</span>arrel Aged Barrel Aged Barrel Aged</h1>
                </div>
                <div className="rollingText02-intro-screen">
                    <h1 className="section-title-intro-screen"><span className="special-char-intro-screen">B</span>arrel Aged Barrel Aged</h1>
                </div>
            </div>
            <div className="transitioning-video-container-for-barrel-aged" ref={ref}>
                {
                    nativeScreenSize === "ABOVEFULLHD" ?
                        <video id={"rotating_vid_barrel_aged"} className="StartingSplashVideo-barrel-aged"  playsInline onEnded={handleLeaveBarrelAgedVideoScreen}>
                            <source src={BrandVid} type="video/mp4"/>
                        </video>
                        :
                        <video id={"rotating_vid_barrel_aged"} className="StartingSplashVideo-barrel-aged"  playsInline onEnded={handleLeaveBarrelAgedVideoScreen}>
                            <source src={BrandVidQuadHD} type="video/mp4"/>
                        </video>
                }
            </div>
            <button className="skip-video" onClick={handleLeaveBarrelAgedVideoScreen}>Skip Intro</button>
        </div>
    )
}

export default IntroTransitionVideoForBarrelAged;
