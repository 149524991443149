
//sagar.goldenhummingbird2@gmail.com BarrelAGED

const BA_HomePage_ImageData = {
    "BAHomePageData" :[
        {
            id:0,
            BigSplashImage : "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FBarrelAgedBigSplash.png?alt=media&token=e7b717f2-ae8c-435d-9580-a7b43d79b805"
        },
        {
            id:1,
            CoffeeGrindingImage : "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FCoffeeGrinding.jpg?alt=media&token=64a44470-20d1-4398-b3d7-68a4723aa839"
        },
        {
            id:2,
            BarrelImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2Fbarrel.jpg?alt=media&token=53c98478-a4fa-4738-9ff6-63eb1cf92a31"
        },
        {
            id:3,
            FruityImage : "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FFruity.png?alt=media&token=6f77317b-f4e6-4d3c-9382-dc5097c568b8"
        },
        {
            id:4,
            TwoCansImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FTwoCans.png?alt=media&token=22e348fd-e2d8-4415-9279-40e3e94f6363"
        },
        {
            id:5,
            BarrelAgedFluidShotVideo: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FBarrelAgedBlueBerry_Transparent2.png?alt=media&token=54315fa2-45f4-4141-b1ab-82046ea37420"
        },
        {
            id:6,
            BestCoffeeBean: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FHawaianCoffeeFruit.jpg?alt=media&token=9f182bfa-a15a-43cf-847a-e07aa8efe2db"
        },
        {
            id:7,
            ShakeImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2Fshake.png?alt=media&token=d555b02a-3599-4f01-abbd-1ea7214809a1"
        },
        {
            id:8,
            BarrelImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2Fbarrel_stored.jpg?alt=media&token=74d66d11-4826-4174-891d-3b8b43768090"
        },
        {
            id:9,
            MethodOfMakingImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FbarrelAged_method.png?alt=media&token=fdbc8a55-b320-41d7-8993-f1a0a0d8980d"
        },
        {
            id:10,
            MethodShownImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FBarrelAged_MethodShown.png?alt=media&token=80a5af83-9c1b-4503-b4b5-2b0eb0617e0e"
        },
        {
            id:11,
            StrongFlavourImage: "https://firebasestorage.googleapis.com/v0/b/barrelaged-de6ce.appspot.com/o/images%2FStrongFlavour_BarrelAged.png?alt=media&token=1bb7ea0d-66ad-4e1e-a4ef-f24cc5caf7e0"
        }
    ]
}

export default BA_HomePage_ImageData;
