import React from "react";

import "./ProductFeatures_AgedBarrel.scss";
import BA_HomePage_ImageData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_ImageData";



const ProductFeatures_AgedBarrel = () =>{
    return(
        <div className="feature-aged-barrel">
            <div className="feature-details-pane">
                <h1 className="feature-title">M<span className="special-font">OOO</span>re than <span className="special-font">J</span>ust Coffee, Its'  C<span className="special-font">O</span>ffe<span className="special-font">E + L</span>IQUOR</h1>
                <h1 className="feature-desc">Shake it and it will give you elixir to enjoy in shorts  - These are coffees aged in wooden barrels where excellent whiskeys, wines, rum and, of course, cachaça rested before, because nothing is more refreshing than a good cup of coffee.</h1>
                <div className="area-spacer"/>

            </div>
            <div className="feature-reference-content-pane">
                <div className="small-content-area">
                    <img src={BA_HomePage_ImageData.BAHomePageData[8].BarrelImage} alt="" className="display-image"/>
                </div>
                <div className="big-content-area">
                    <div className="image-wrapper">
                        <img src={BA_HomePage_ImageData.BAHomePageData[7].ShakeImage} alt="" className="big-product-img"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductFeatures_AgedBarrel;
