import React, {useContext, useEffect, useRef} from "react";

import "./DesignImportanceScreen.scss";

//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../hooks/useOnScreen";
import FlipBookSection from "../../components/FlipBookSection/FlipBookSection";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
gsap.registerPlugin(ScrollTrigger, SplitText);

//----------------------------------------------------------------------------------------------------------------------


const DesignImportanceScreen = () =>{

    const refImportance = useRef(null);
    const onScreenImportance = useOnScreen(refImportance);

    const refLeftQuotePanel = useRef(null);
    const onScreenLeftQuotePanel = useOnScreen(refLeftQuotePanel);

    const refRightQuotePanel = useRef(null);
    const onScreenRightQuotePanel = useOnScreen(refRightQuotePanel);

    const vw = (coef) => window.innerWidth * (coef/100);
    const vh = (coef) => window.innerHeight * (coef/100);
    const ww = window.innerWidth;
    const wh = window.innerHeight;

    const circlesBox = useRef(null);
    const circles = useRef([]);

    const refWhatWeDo = useRef(null);
    const onScreenWhatWeDo = useOnScreen(refWhatWeDo);

    const refRow1 = useRef(null);
    const refRow2 = useRef(null);
    const refRow3 = useRef(null);
    const refRow4 = useRef(null);
    const refRow5 = useRef(null);

    const createCirclesRefs = (circle, index) => {
        circles.current[index] = circle;
    };

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const MagicIconURI = GSHOMEDATA.magicIcon;
    const EyeIcon = GSHOMEDATA.eyeIcon;
    const dragIcon = GSHOMEDATA.swipeLeftIcon;

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
        setFilterImplementation("grayScale(0%)")
        // setImgSource("https://firebasestorage.googleapis.com/v0/b/shadowfightarena-99fe0.appspot.com/o/ezgif.com-gif-maker.gif?alt=media&token=d503b7ac-2f06-4d37-a24b-711e4b596a5a")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
        // setImgSource("")
    }

    useEffect(() => {
        setTimeout(()=>{
            if(onScreenImportance){
                playText()
            }
        })
    }, [onScreenImportance]);

    useEffect(()=>{
        setTimeout(()=>{
            const quotes = document.querySelectorAll(".first-text");
            function setupSplits() {
                quotes.forEach(quote => {
                    // Reset if needed
                    if(quote.anim) {
                        quote.anim.progress(1).kill();
                        quote.split.revert();
                    }
                    quote.split = new SplitText(quote, {
                        type: "lines,words,chars",
                        linesClass: "split-line"
                    });
                    // Set up the anim
                    quote.anim = gsap.from(quote.split.chars, {
                        scrollTrigger: {
                            trigger: quote,
                            horizontal: true,
                            toggleActions: "restart pause resume reverse",
                            start: "left 80%",
                            scroller: "#main-container",
                            scrub: true
                        },
                        duration: 2,
                        ease: "circ.out",
                        x:   -500 ,//  gsap.utils.wrap([-300,300]),
                        // rotation:gsap.utils.wrap([-100, 100]),
                        opacity:0,
                        stagger:{each:1, from:"start"}
                    });
                });
            }
            if(onScreenLeftQuotePanel){
                setupSplits()
            }
        })
    },[onScreenLeftQuotePanel])

    useEffect(() => {
        setTimeout(()=>{
            if(onScreenRightQuotePanel){
                const secondQuotes = document.querySelectorAll(".last-text");
                function setupSplitsForLast() {
                    secondQuotes.forEach(quote => {
                        // Reset if needed
                        if(quote.anim) {
                            quote.anim.progress(1).kill();
                            quote.split.revert();
                        }
                        quote.split = new SplitText(quote, {
                            type: "lines,words,chars",
                            linesClass: "split-line"
                        });
                        // Set up the anim
                        quote.anim = gsap.from(quote.split.chars, {
                            scrollTrigger: {
                                trigger: quote,
                                horizontal: true,
                                toggleActions: "restart pause resume reverse",
                                start: "left 80%",
                                scroller: "#main-container",
                                scrub: true
                            },
                            duration: 2,
                            ease: "circ.out",
                            x:   -500 ,//  gsap.utils.wrap([-300,300]),
                            // rotation:gsap.utils.wrap([-100, 100]),
                            opacity:0,
                            stagger:{each:1, from:"start"}
                        });
                    });
                }
                setupSplitsForLast()
            }
        })
    }, [onScreenRightQuotePanel]);



    useEffect(()=>{
        setTimeout(()=>{
            if(onScreenWhatWeDo){
                // playTextForMessage();
                // const wrappers = document.querySelectorAll(".image-circle");
                var animateCirclesTL = gsap.timeline({
                    scrollTrigger:{
                        trigger: circlesBox.current,
                        scrub: true,
                        horizontal: true,
                        start: "left 75%",
                        end: "+=100%",
                        toggleActions: "play none none reverse",
                        // invalidateOnRefresh: true
                    }
                })
                animateCirclesTL.fromTo(circles.current,{
                    x: i => -(ww / 5) * (i + 1),
                    transformOrigin: "center",
                    }, {
                        x: i => ((ww / 5) * (i + 1))/6,
                        transformOrigin: "center",
                    }
                )
            }
        })
    },[onScreenWhatWeDo])


    const playText = () =>{
        var mySplitText = new SplitText("#pure-design", {type:"chars, words"}),
            tl =  gsap.timeline(),
            numChars = mySplitText.chars.length;
        for(var i = 0; i < numChars; i++){
            //random value used as position parameter
            tl.from(mySplitText.chars[i],
                {
                    opacity:0,
                    duration: 2
                },
                Math.random() * 2 ,
            );
        }
    }

    return(
        <div className="design-importance-screen"
             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",BrandLogoURI)}
             onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",EyeIcon)}
        >
            <div className="cover-up" ref={refImportance}>
                <h1 className="design-importance-statement" id={"pure-design"}>
                    Once a pure design has taken hold of your brain its almost impossible to irradiate.
                    It can grow your business, it can provide bespoke identity to your brand.
                </h1>
            </div>
            <div className="what-we-do" ref={refWhatWeDo}>
                <h1 className="small-letter-box" ref={refRow1}>We understand this principle</h1>
                <h1 className="medium-letter-box" ref={refRow2}>That's why we at Golden Sparrow Studio</h1>
                <h1 className="medium-letter-box" ref={refRow3}>Create fusion between technology and our magic of Imagination</h1>
                <h1 className="medium-letter-box" ref={refRow4}>That gives your brand</h1>
                <h1 className="medium-letter-box" ref={refRow5}>Bespoke Identity!</h1>
                <div className="circles-box" ref={circlesBox}>
                    <img src={GSStudio_HomePageData[0].mermaidImage} alt="" className="image-circle" ref={(e) => createCirclesRefs(e,1)}/>
                    <img src={GSStudio_HomePageData[0].shake} alt="" className="image-circle" ref={(e) => createCirclesRefs(e,2)}/>
                    <img src={GSStudio_HomePageData[0].gold} alt="" className="image-circle" ref={(e) => createCirclesRefs(e,3)}/>
                    <img src={GSStudio_HomePageData[0].barrelBigSplash} alt="" className="image-circle" ref={(e) => createCirclesRefs(e,4)}/>
                    <img src={GSStudio_HomePageData[0].crystals} alt="" className="image-circle" ref={(e) => createCirclesRefs(e,5)}/>
                </div>
            </div>
            <div className="three-dimensional-content">
                <div className="left-text-panel" ref={refLeftQuotePanel}>
                    <div className="small-quote">
                        <h1 className="first-text">3D ANIMATION TO</h1>
                    </div>
                </div>
                <div className="center-content-panel"
                     onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",dragIcon)}
                     onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                >
                    <FlipBookSection/>
                </div>
                <div className="right-text-panel" ref={refRightQuotePanel}>
                    <div className="small-quote">
                        <h1 className="last-text">ENHANCE PRODUCT EXPERIENCE</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesignImportanceScreen;
