import React,{useState,useEffect,useRef} from "react";


import "./FlipBook_SoftPage.scss";

const FlipBook_SoftPage = React.forwardRef((props,ref) =>{
    return(
        <div className="page" ref={ref}>
            <div className="page-content">
                <h2 className="page-header">Page header - {props.number}</h2>
                <div className="page-image"></div>
                <div className="page-text">{props.children}</div>
                <div className="page-footer">{props.number + 1}</div>
            </div>
        </div>
    )
});

export default FlipBook_SoftPage;
