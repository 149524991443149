import React, {useContext, useRef, useState,useEffect} from "react";
import GoldenSparrowContactUsPageModal from "../GoldenSparrowContactUsPageModal";
import {gsap} from "gsap";
import GoldenSparrowPageTransition
    from "../../../PageTransition/GoldenSparrowPageTransition/GoldenSparrowPageTransition";
import GoldenSparrowContactUsPageShownContext from "../../../../Context/GoldenSparrowContactUsPageShownContext";
import {GoldenSparrowStudioCursorContext} from "../../../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import GSLOGO from "../../../../assets/images/GSBrandLogo.png";
import {toast, ToastContainer} from "react-toastify";
import "./GoldenSparrowContactUsPage.scss";
import {GSStudio_HomePageData} from "../../../../Pages/HomePage/HomePage_Data";



const GoldenSparrowContactUsPage = () =>{


    useEffect(() => {
        console.log("Contact US Page Opened!!!!!!!")
    }, []);


    const refCopyEmailAddress = useRef(null);
    const ctx = useContext(GoldenSparrowContactUsPageShownContext);
    const [playMenuCurtain, setPlayMenuCurtain] = useState(false);
    const GoldenSparrowPageTransitionTl = gsap.timeline();

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const MagicIconURI = GSHOMEDATA.magicIcon;
    const SendEmailIcon = GSHOMEDATA.sendEmail;
    const LeftClickIcon = GSHOMEDATA.leftClick;

    const handleCloseGoldenSparrowContactUsMenu = () =>{
        setPlayMenuCurtain( prevState => !prevState)
        ctx.closeGoldenSparrowContactUsPage()
    }
    const {setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);
    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
    }
    const notifySuccess = () =>{
        toast.success('Email Copied!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const notifyFailed = () =>{
        toast.error('Failed to Copy Email.Click Again.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const handleCopyToClipboard = async() => {
        if(!refCopyEmailAddress.current) return;
        const text = refCopyEmailAddress.current.textContent;
        try{
            await navigator.clipboard.writeText(text);
            await notifySuccess()
        }catch (error){
            await notifyFailed()
        }
    }

    return(
        <GoldenSparrowContactUsPageModal>
            <GoldenSparrowPageTransition timeline={GoldenSparrowPageTransitionTl}/>
            <div className="contactus-scrolling-panel"
                 onMouseEnter={()=> handleCursorMouseEnter("small","","normal",BrandLogoURI)}
                 onMouseLeave={()=> handleCursorMouseLeave("small","","normal",BrandLogoURI)}
            >
                <div className="contact-back-btn-container" onClick={handleCloseGoldenSparrowContactUsMenu}>
                    CLOSE
                </div>
                <div className="left-footer-section">
                    <div className="line1">
                        <h1 className="text">GET IN</h1>
                    </div>
                    <div className="line2">
                        <img src={GSLOGO} alt="" className="logo"/>
                        <h1 className="text-2">TOUCH</h1>
                    </div>
                    <div className="contact-email-row">
                        {/*<MailIcon/>*/}
                        <div className="email-interaction" ref={refCopyEmailAddress} onClick={handleCopyToClipboard}
                             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",SendEmailIcon)}
                             onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                        >
                            studio.goldensparrow@gmail.com
                            <ToastContainer
                                position="bottom-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                bodyClassName={"toastBody"}
                            />
                        </div>
                    </div>
                </div>
                <div className="right-footer-section">
                    <div className="contact-grid">
                        <div className="contact-row">
                            <div className="grid-block">
                                <h1 className="head-line">CONTACT US</h1>
                                <h1 className="text-link">studio.goldensparrow@gmail.com</h1>
                                <h1 className="text-line">+91-9307688492</h1>
                            </div>
                            <div className="grid-block"
                                 onMouseEnter={()=> handleCursorMouseEnter("medium","","difference",LeftClickIcon)}
                                 onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                            >
                                <h1 className="head-line">FOLLOW US</h1>
                                <a className="text-link" href='https://twitter.com/gscreative556' target="_blank">Twitter</a>
                                <a className="text-link" href='https://www.facebook.com/profile.php?id=100081025428884' target="_blank">Facebook</a>
                                <a className="text-link" href='https://www.instagram.com/goldensparrowstudio.in/' target="_blank">Instagram</a>
                            </div>
                        </div>
                        <div className="contact-row">
                            <div className="grid-block"
                                 onMouseEnter={()=> handleCursorMouseEnter("medium","","difference",LeftClickIcon)}
                                 onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                            >
                                <h1 className="head-line">TRENDING ON</h1>
                                <a className="text-link" target={"_blank"}>Youtube</a>
                                <a className="text-link" href={"https://www.behance.net/sagarmitkari1"} target={"_blank"}>Behance</a>
                                <a className="text-link" href={"https://www.artstation.com/sagarmitkari9"} target={"_blank"}>ArtStation</a>
                                <a className="text-link" href={"https://dribbble.com/goldensparrowstudio"} target={"_blank"}>Dribble</a>
                                <a className="text-link" href={"https://in.pinterest.com/studiogoldensparrow"} target={"_blank"}>Pinterest</a>
                                <a className="text-link" href={"https://goldensparrow88.cgsociety.org/"} target={"_blank"}>CGSociety</a>
                                <a className="text-link" href={"https://3dtotal.com/goldensparrowstudio"} target={"_blank"}>3DTotal</a>
                            </div>
                            <div className="grid-block">
                                <h1 className="head-line">LOCATION</h1>
                                <h1 className="text-link">Plot No 10, Karve Nagar</h1>
                                <h1 className="text-link">Pune - 411052, India</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GoldenSparrowContactUsPageModal>
    )
}
export default GoldenSparrowContactUsPage;
