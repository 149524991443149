import React, {useContext, useEffect, useRef} from "react";
import "./TechnologyPad.scss";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";

//logo imports-------------------------------------------------------------------------------------------------------
import useOnScreen from "../../hooks/useOnScreen";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
import {HomePage_VideoData} from "../../Pages/HomePage/HomePage_VideoData";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
//logo imports-------------------------------------------------------------------------------------------------------
gsap.registerPlugin(ScrollTrigger, SplitText);


const TechnologyPad = () =>{

    const ref = useRef(null);
    const refBrainVideo = useRef(null);
    const refBrainTextWrapper = useRef(null);
    const refBrainText = useRef(null);
    const onScreen = useOnScreen(ref);
    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const MagicIconURI = GSHOMEDATA.magicIcon;
    const EyeIcon = GSHOMEDATA.eyeIcon;
    const {setSize, setText, setVideoSource, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);
    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }
    useEffect(() => {
        setTimeout(()=>{
            if(onScreen){
                playText()
                setTimeout(startVideo, 800)
            }else{
                setTimeout(stopVideo,1)
            }
        })
    }, [onScreen]);

    const playText = () =>{
        let flipUpText = refBrainText.current;
        let flipUpTextLine = new SplitText(flipUpText,{type: "chars, words"} );
        gsap.from(
            flipUpTextLine.chars,
            {
                duration: 1.5,
                scale:0,
                display: 'none',
                transformOrigin: '100% 50%',
                ease: "Back.easeOut",
                stagger: 0.025,
            }
        );
    }

    useEffect(() => {
        setTimeout(()=>{
            //-----------------------------------------------------------------------------------------------------------
            let direction = -1; // 1 = forward, -1 = backward scroll
            const roll1 = roll(".rollingText", {duration: 8},  true),
                roll2 = roll(".rollingText02", {duration: 8}, true),
                scroll = ScrollTrigger.create({
                    scroller: "#main-container",
                    horizontal: true,
                    onUpdate(self) {
                        console.log('onUpdate', self.direction)
                        if (self.direction !== direction) {
                            direction *= -1;
                            gsap.to([roll1,roll2], {timeScale: direction, overwrite: true});
                        }
                    }
                });
            // helper function that clones the targets, places them next to the original, then animates the xPercent in a loop to make it appear to roll across the screen in a seamless loop.
            function roll(targets, vars, reverse) {
                const tl = gsap.timeline({
                    repeat: -1,
                    onReverseComplete() {
                        this.totalTime(this.rawTime() + this.duration() * 10); // otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
                    }
                });
                vars = vars || {};
                vars.ease || (vars.ease = "none");
                gsap.utils.toArray(targets).forEach(el => {
                    let clone = el.cloneNode(true);
                    // el.parentNode.appendChild(clone);
                    gsap.set(clone, {position: "absolute", top: el.offsetTop, left: el.offsetLeft + (reverse ? -el.offsetHeight : el.offsetHeight)});
                    tl.to([el, clone], {yPercent: reverse ? 100 : -100, ...vars}, 0);
                });
                return tl;
            }
            //-----------------------------------------------------------------------------------------------------------
            //-----------------------------------------------------------------------------------------------------------
            // Split Text Trigger
            const quotes = document.querySelectorAll(".slide1-text");
            function setupSplits() {
                quotes.forEach(quote => {
                    // Reset if needed
                    if(quote.anim) {
                        quote.anim.progress(1).kill();
                        quote.split.revert();
                    }
                    quote.split = new SplitText(quote, {
                        type: "lines,words,chars",
                        linesClass: "split-line"
                    });
                    // Set up the anim
                    quote.anim = gsap.from(quote.split.chars, {
                        scrollTrigger: {
                            trigger: quote,
                            horizontal: true,
                            toggleActions: "restart pause resume reverse",
                            start: "left 100%",
                            scroller: "#main-container",
                            scrub: true
                        },
                        duration: 2,
                        ease: "circ.out",
                        x:   -500 ,//  gsap.utils.wrap([-300,300]),
                        // rotation:gsap.utils.wrap([-100, 100]),
                        opacity:0,
                        stagger:{each:1, from:"start"}
                    });
                });
            }
            ScrollTrigger.addEventListener("refresh", setupSplits);
            setupSplits();
        })
    }, []);

    const startVideo = async () => {
        const video = refBrainVideo.current //document.querySelector('#brain-video_background');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video =  refBrainVideo.current //document.querySelector('#brain-video_background');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }


    return(
        <div className="technology-pad"
             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",EyeIcon)}
             onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
        >
            <div className="front-vertical-pillar" id={"idea-box-frontend"}>
                <div className="rollingText">
                    <h1 className="section-title"><span className="special-char">T</span>echnical Expertise - Concept -Design - Branding - Digital Deployment . </h1>
                </div>
                <div className="rollingText02">
                    <h1 className="section-title"><span className="special-char">T</span>echnical Expertise - Concept - Design - Branding - Digital Deployment . </h1>
                </div>
            </div>
            <div className="tech-pad-box" >
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.threeDAnimationLogo} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">3D Animation</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">We bring life to your products & services through our cutting edge skills & art direction using some of the popular 3d animation softwares such as Blender, Maya, Zbrush, Hudini and bring life to product visulization with liquid and other forms of simulation using Realflow Embergen and many other tools.Overall we make your brand unique and help you to sell faster and more effectively.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.videoEditing} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">VFX & Editing</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">No digital product is finished until its goes through vfx and Editing workflow. With our special skillsets in this area we bring expertise in best tools and practices adopted by industry to bring you that final polished product only to enhance your product experience..</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.frontEnd} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">Website & App Development</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">That finished product deserves latest swift technologies to publish online. We bring expertise with  modern Javascript frameworks such as React Js, Next Js along with mobile App development platform experience with frameworks such as React Native, Flutter and  Native Android Studio others to give seamless online experience</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.jsAnim} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">Modern UI-UX Animation Libraries</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">That smooth buttery  and modern horizontal & vertical scroll experience you are having here is very attractive right? We bring modern user experience to your websites with our state of the art skills in libraries like GSAP, Locomotive scroll, Framer Motion and many others. And help you stand out your website one in million.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.threeDAnimationLogo} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">3D Animation</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">We bring life to your products & services through our cutting edge skills & art direction using some of the popular 3d animation softwares such as Blender, Maya, Zbrush, Hudini and bring life to product visulization with liquid and other forms of simulation using Realflow Embergen and many other tools.Overall we make your brand unique and help you to sell faster and more effectively.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.videoEditing} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">VFX & Editing</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">No digital product is finished until its goes through vfx and Editing workflow. With our special skillsets in this area we bring expertise in best tools and practices adopted by industry to bring you that final polished product only to enhance your product experience..</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.backEnd} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">Backend Development</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Every website needs data interaction. We develope secured backends for sdeamless  data flow and user interaction using  our exceltional skills in both cloud services (Amazon Cloud services, Google Firebase)  as well as backend technologies such as Mongo DB, PostgreSQL, MySql, GraphQL Rest API, JWT Auth Token, Stripe Payment system and many more.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.threeDAnimationLogo} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">3D Animation</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">We bring life to your products & services through our cutting edge skills & art direction using some of the popular 3d animation softwares such as Blender, Maya, Zbrush, Hudini and bring life to product visulization with liquid and other forms of simulation using Realflow Embergen and many other tools.Overall we make your brand unique and help you to sell faster and more effectively.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.videoEditing} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">VFX & Editing</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">No digital product is finished until its goes through vfx and Editing workflow. With our special skillsets in this area we bring expertise in best tools and practices adopted by industry to bring you that final polished product only to enhance your product experience..</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.threeDAnimationLogo} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">3D Animation</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">We bring life to your products & services through our cutting edge skills & art direction using some of the popular 3d animation softwares such as Blender, Maya, Zbrush, Hudini and bring life to product visulization with liquid and other forms of simulation using Realflow Embergen and many other tools.Overall we make your brand unique and help you to sell faster and more effectively.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.videoEditing} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">VFX & Editing</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">No digital product is finished until its goes through vfx and Editing workflow. With our special skillsets in this area we bring expertise in best tools and practices adopted by industry to bring you that final polished product only to enhance your product experience..</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.threeDAnimationLogo} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">3D Animation</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">We bring life to your products & services through our cutting edge skills & art direction using some of the popular 3d animation softwares such as Blender, Maya, Zbrush, Hudini and bring life to product visulization with liquid and other forms of simulation using Realflow Embergen and many other tools.Overall we make your brand unique and help you to sell faster and more effectively.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.videoEditing} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">VFX & Editing</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">No digital product is finished until its goes through vfx and Editing workflow. With our special skillsets in this area we bring expertise in best tools and practices adopted by industry to bring you that final polished product only to enhance your product experience..</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.threeDAnimationLogo} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">3D Animation</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">We bring life to your products & services through our cutting edge skills & art direction using some of the popular 3d animation softwares such as Blender, Maya, Zbrush, Hudini and bring life to product visulization with liquid and other forms of simulation using Realflow Embergen and many other tools.Overall we make your brand unique and help you to sell faster and more effectively.</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <div className="box-row">
                            <img src={GSHOMEDATA.videoEditing} alt="" className="tech-logo"/>
                        </div>
                    </div>
                    <h1 className="tech-name">VFX & Editing</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">No digital product is finished until its goes through vfx and Editing workflow. With our special skillsets in this area we bring expertise in best tools and practices adopted by industry to bring you that final polished product only to enhance your product experience..</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
                <div className="technology-pad-tile" data-scroll data-scroll-direction="vertical" data-scroll-speed="10" data-scroll-target="#idea-box-frontend">
                    <div className="tech-logo-box">
                        <img src="" alt="" className="tech-logo"/>
                    </div>
                    <h1 className="tech-name">Tech</h1>
                    <div className="tech-desc-box">
                        <h1 className="tech-desc-lines">Description</h1>
                    </div>
                </div>
            </div>
            <div className="rear-vertical-pillar" id={"idea-box-backend"}>
                <h1 className="section-title">Website & App Development - </h1>
                <h1 className="section-title">Backend</h1>
            </div>
            <div className="more-services-box">
                <div className="quote" >
                    <h1 className="slide1-text">A Design that is fully formed, fully understood.......</h1>
                </div>
                <div className="ending-message-wrapper" ref={refBrainTextWrapper} >
                    <h1 className="ending-message" ref={refBrainText}>that, sticks right in there somewhere</h1>
                </div>
                <div className="brain-clip-section"  ref={ref}>
                    <video id={"brain-video_background"} playsInline preload={"true"} muted loop className={"brain-video"} ref={refBrainVideo}>
                        <source src={HomePage_VideoData[11].brainVideo}/>
                    </video>
                </div>
            </div>
            <div className="rear-vertical-pillar" id={"last-pillar"}>
                <h1 className="section-title">PURE DESIGN - PURE IDENTITY</h1>
                <h1 className="section-title">.</h1>
            </div>
        </div>
    )
}
export default TechnologyPad;









