import React, {useRef, useEffect} from 'react'
import "./GoldenSparrowHorizontalPageTransition.scss";



const GoldenSparrowHorizontalPageTransition = ({timeline}) =>{


    const GoldenSparrowHorizontalCurtainTransition = useRef(null);
    const vh = (coef) => window.innerHeight * (coef/100);
    const vw = (coef) => window.innerWidth * (coef/100);
    useEffect(() => {
        // timeline.to(trans.current, {
        //     duration: 2,
        //     y: vh(-100),
        //     ease: 'Power4.easeInOut'
        // })
        timeline
            .to(".golden-sparrow-horizontal-box", {
                force3D: true,
                duration: 1,
                scaleY: 0,
                yPercent: -500,
                ease: "power1.inOut",
                stagger: { amount: 1 },
                borderRadius: vw(2)
                // transformOrigin: "bottom bottom"
            })
            .to(GoldenSparrowHorizontalCurtainTransition.current,{
                duration: 3,
                x: vw(100),
                ease: 'Power4.easeInOut'
            },"-=1")
    },[])

    return(
        <div className="golden-sparrow-horizontal-transition">
            <div className="golden-sparrow-horizontal-transition-effect" ref={GoldenSparrowHorizontalCurtainTransition}>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
                <div className="golden-sparrow-horizontal-box"></div>
            </div>
        </div>
    )
}

export default GoldenSparrowHorizontalPageTransition;
