import React, {useContext, useEffect, useRef, useState} from "react";
import "./SplashScreen.scss";
import BrandLogoVideo from "../../assets/videos/Golden_Sparrow_Logo.mp4";
//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../hooks/useOnScreen";
import SplashScrollingServices from "../../components/SpnashScrollingServices/SplashScrollingServices";
import GoldenSparrowAnimationReelShownContext from "../../Context/GoldenSparrowAnimationReelShownContext";
import GoldenSparrowAnimationReelContext from "../../Context/GoldenSparrowAnimationReelContext";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import {HomePage_VideoData} from "../../Pages/HomePage/HomePage_VideoData";
gsap.registerPlugin(SplitText);
//----------------------------------------------------------------------------------------------------------------------

const SplashScreen = () =>{

    const ref = useRef(null);
    const refSplashContainer = useRef(null);
    const onScreen = useOnScreen(ref);
    const [firstTimeLoading,setFirstTimeLoading] = useState(true);
    const ctx = useContext(GoldenSparrowAnimationReelShownContext);
    const ctx_SetURl = useContext(GoldenSparrowAnimationReelContext);
    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.gsBrandLogoArrowH;
    const MagicIconURI = GSHOMEDATA.magicIcon;
    const MediaIcon = GSHOMEDATA.watchMediaIcon;

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
        setFilterImplementation("grayScale(0%)")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode, imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    const handleOpenGoldenSparrowAnimationReel = (showReelURl) =>{
        ctx_SetURl.addURl(showReelURl)
        ctx.showGoldenSparrowAnimationReel();
    }

    useEffect(() => {
        setTimeout(()=>{
            if(firstTimeLoading){
                const splashContainer = refSplashContainer.current;
                gsap.to(splashContainer,{opacity: 0, duration: 0.05} )
                setTimeout(animateOpacityOFSplashScreen, 10000)
                setTimeout(handleWordDreamCloud,10000)
                setFirstTimeLoading(prevState => !prevState)
            }
        })
    }, []);

    useEffect(() => {
       setTimeout(()=>{
            if(onScreen){
                if(!firstTimeLoading){
                    setTimeout(startVideo,2000);
                    handleWordDreamCloud()
                }
            }else{
                setTimeout(stopVideo,25)
            }
       })
    }, [onScreen]);


    const handleWordDreamCloud = () =>{
        var mySplitText = new SplitText("#msSplitChars", {type:"words"}),
            tl =  gsap.timeline(),
            numWords = mySplitText.words.length;
        for(var i = 0; i < numWords; i++){
            tl.from(mySplitText.words[i],  {force3D:true,duration: 1, scale:Math.random() >0.5 ? 0 : 2, opacity:0}, Math.random());
        }
    }

    const animateOpacityOFSplashScreen = async () => {
        const splashContainer = refSplashContainer.current;
        gsap.to(splashContainer,{opacity: 1, duration: 2, ease: "Quint.easeOut"});
    }

    const startVideo = async () => {
        const video = document.querySelector('#video_background');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = document.querySelector('#video_background');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }


    return(
        <div className={"splashScreen-container"} ref={refSplashContainer}
             onMouseEnter={()=> handleCursorMouseEnter("medium","","difference",BrandLogoURI)}
             onMouseLeave={()=> handleCursorMouseLeave("medium","","difference",BrandLogoURI)}
        >
            <div className="branding-section">
                <div className="top-section">
                    <video id={"video_background"} playsInline  loop className={"rightVid"}
                           preload={"true"}>
                        <source src={BrandLogoVideo} type={"video/mp4"}/>
                    </video>
                </div>
                <div className="bottom-section">
                    <h1 className="tag-line">'Emblem of Creativity'</h1>
                    <h1 className={"first-trigger"}><span className={"special-char"}>G</span> <span className={"letter-gap"}>olden</span><span className={"special-char"}>S</span><span className={"letter-gap2"}>parrow</span>    <span className={"last-word"}>Studio</span></h1>
                    <p style={{color: "black"}} className={"shining-brand-name"}>Golden Sparrow Studio</p>
                </div>
            </div>
            <div className="messaging-section">
                <div className="top-section-messaging" ref={ref}
                     onMouseEnter={()=> handleCursorMouseEnter("medium","","screen",MagicIconURI)}
                     onMouseLeave={()=> handleCursorMouseLeave("medium","","difference",BrandLogoURI)}
                >
                    <h1 id="msSplitChars" className="messaging-para">
                        When i see a block - "i seek a breeze walk." When i seek an idea - "it always have been from my magical hat". When that hat was outworn - "i drew inspiration none other than from mother nature"!
                        Last night i've been thinking, what's the source of my next creation? If you too caught-up in rubble,and looking for miracle then worry not. You have been rescued just because that's what we create here with our imagination.
                    </h1>
                </div>
                <div className="bottom-section-messaging">
                    <SplashScrollingServices/>
                </div>
            </div>
            <div className="space-ending">
                <h1 className="demo-reel-header">Watch Our</h1>
                <div className="demo-reel-box-wrapper">
                    <div className="demo-reel-box">
                        <div className="play-button-box"
                             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",MediaIcon)}
                             onMouseLeave={()=> handleCursorMouseLeave("medium","","difference",BrandLogoURI)}
                             onClick={() => handleOpenGoldenSparrowAnimationReel(HomePage_VideoData[1].barrelAgedReel)}>
                            <svg width="24" height="27" viewBox="0 0 24 27">
                                <path d="M1.25 0.94263L23 13.5L1.25 26.0574L1.25 0.94263Z" stroke="white" fill="none"/>
                            </svg>
                        </div>
                        <img src={GSStudio_HomePageData[0].Fruity} alt="" className="demo-reel"/>
                    </div>
                    <div className="demo-reel-box">
                        <div className="play-button-box"
                             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",MediaIcon)}
                             onMouseLeave={()=> handleCursorMouseLeave("medium","","difference",BrandLogoURI)}
                             onClick={() => handleOpenGoldenSparrowAnimationReel(HomePage_VideoData[2].aandsReel)}>
                            <svg width="24" height="27" viewBox="0 0 24 27">
                                <path d="M1.25 0.94263L23 13.5L1.25 26.0574L1.25 0.94263Z" stroke="white" fill="none"/>
                            </svg>
                        </div>
                        <img src={GSStudio_HomePageData[0].crystalsIceburgImage2} alt="" className="demo-reel"/>
                    </div>
                </div>
                <h1 className="demo-reel-header">3D Product Visualization</h1>
                <h1 className="demo-reel-header">Animation Reels</h1>
            </div>
        </div>
    )
}
export default SplashScreen;
