import React, {useContext} from "react";
import "./style.scss";

import {GoldenSparrowStudioCursorContext} from "./GoldenSparrowStudioCursorManager";


const GoldenSparrowStudio_CustomCursor = () =>{

    const {size, text, imgSource, videoSource, blendMode, filterImplementation} = useContext(GoldenSparrowStudioCursorContext);
    const secondaryCursor = React.useRef(null);
    let WidthSlide = 0;
    const positionRef = React.useRef({
        mouseX: 0,
        mouseY: 0,
        destinationX: 0,
        destinationY: 0,
        distanceX: 0,
        distanceY: 0,
        key: -1
    });

    React.useEffect(()=>{
        if(secondaryCursor.current && WidthSlide < secondaryCursor.current.offsetWidth){
            document.addEventListener("mousemove",(event)=>{
                const {clientX,clientY} = event;
                const mouseX = clientX;
                const mouseY = clientY;
                positionRef.current.mouseX = mouseX - secondaryCursor.current.clientWidth / 2;
                positionRef.current.mouseY = mouseY - secondaryCursor.current.clientHeight / 2;
            });
        }
        return () => {};
    },[]);

    React.useEffect(()=>{
        const followMouse = () =>{
            positionRef.current.key = requestAnimationFrame(followMouse);
            const {
                mouseX,
                mouseY,
                destinationX,
                destinationY,
                distanceX,
                distanceY
            } = positionRef.current;

            if(!destinationX || ! destinationY){
                positionRef.current.destinationX = mouseX;
                positionRef.current.destinationY = mouseY;
            }else{
                positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
                positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
                if(
                    Math.abs(positionRef.current.distanceX) + (Math.abs(positionRef.current.distanceY))< 0.1
                ){
                    positionRef.current.destinationX = mouseX;
                    positionRef.current.destinationY = mouseY;
                }else {
                    positionRef.current.destinationX += distanceX;
                    positionRef.current.destinationY += distanceY;
                }
            }
            if(secondaryCursor && secondaryCursor.current)
                secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px,0)`;
        };
        followMouse();
    },[]);

    return(
        <div className={`GoldenSparrowStudio-cursor-wrapper default`} >
            <div className={`gs-secondary-cursor ${size}`} ref={secondaryCursor} style={{"--blendMode": blendMode, "--filter": filterImplementation}}>
                <h1 className={"cursorText"}>{text}</h1>
                <img src={imgSource} alt="" className="cursorImage"/>
                {
                    videoSource === "" ?
                        <div>
                        </div>
                        :
                        <div className={"videoWrapper"}>
                            <div className="fractalContainerBG">
                            </div>
                            <video autoPlay loop muted className={"videoFootage"}>
                                <source src={videoSource} type="video/mp4"/>
                            </video>
                        </div>
                }
            </div>
        </div>
    )
}

export default GoldenSparrowStudio_CustomCursor;


