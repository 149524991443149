import React,{useState,useEffect,useRef} from "react";

import "./FlipBook_HardPage.scss";


const FlipBook_HardPage = React.forwardRef((props,ref) =>{
    return(
        <div className="page page-cover" ref={ref} data-density="hard">
            <div className="page-content">
                <h2>{props.children}</h2>
            </div>
        </div>
    )
});

export default FlipBook_HardPage;
