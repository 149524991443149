import React, {useContext, useState} from "react";
import "./GoldenSparrowAboutPage.scss";
import GoldenSparrowAboutPageModal from "../GoldenSparrowAboutPageModal";
import GoldenSparrowAboutPageShownContext from "../../../../Context/GoldenSparrowAboutPageShownContext";
import {gsap} from "gsap";

import GoldenSparrowPageTransition
    from "../../../PageTransition/GoldenSparrowPageTransition/GoldenSparrowPageTransition";

import {GoldenSparrowStudioCursorContext} from "../../../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";

import {GSStudio_HomePageData} from "../../../../Pages/HomePage/HomePage_Data";

const GoldenSparrowAboutPage = () =>{

    const ctx = useContext(GoldenSparrowAboutPageShownContext);
    const [playMenuCurtain, setPlayMenuCurtain] = useState(false);
    const GoldenSparrowPageTransitionTl = gsap.timeline();
    const GSHOMEDATA = GSStudio_HomePageData[0];


    const handleCloseGoldenSparrowAboutMenu = () =>{
        setPlayMenuCurtain( prevState => !prevState)
        ctx.closeGoldenSparrowAboutPage()
    }
    const {setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);
    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
    }

    return(
            <GoldenSparrowAboutPageModal>
                <GoldenSparrowPageTransition timeline={GoldenSparrowPageTransitionTl}/>
                <div className="grid-line-container">
                    <div className="hrline"></div>
                    <div className="vrline1"></div>
                    <div className="vrline2"></div>
                    <div className="center-circle">
                    </div>
                    <img src={GSHOMEDATA.gearsAndPebblesImage} alt="" className="about-image1"/>
                    <img src={GSHOMEDATA.gold} alt="" className="about-image2"/>
                    <img src={GSHOMEDATA.crystals} alt="" className="about-image3"/>
                    <img src={GSHOMEDATA.crystalsIceburg} alt="" className="about-image4"/>
                </div>
                <div className="golden-sparrow-about-page"
                    onMouseEnter={()=> handleCursorMouseEnter("small","","normal","")}
                     onMouseLeave={()=> handleCursorMouseLeave("small","","normal","")}
                >
                    <div className="brand-logo-header">
                        <img src={GSHOMEDATA.GSBrandLogo} alt="" className="brand-logo-about"/>
                        <h1 className="golden-sparrow-header">Golden Sparrow Studio</h1>
                    </div>
                    <div className="back-btn-container" onClick={handleCloseGoldenSparrowAboutMenu}>
                    </div>
                    <div className="about-us-body">
                        <h1 className="golden-sparrow-header">About US</h1>
                        <h1 className="who-we-are">
                            We are a Design & Development studio who focuses on High Quality  digital Content that helps brand enhance their product value and generate high revenue.
                        </h1>
                        <div className="our-mission-box">
                            <h1 className="our-mission-header">Our Vision</h1>
                            <h1 className="our-mission">Our vision is to transform digital world in to an immersive experience that will create impact on the way we consume digital content today.Thats why we constantly strive to explore and enhance our capabilities using technologies such as 3D Animation, VFX, modern UI-UX to give best possible results to our clients.</h1>
                        </div>
                        <div className="social-media-bracket">
                            <h1 className="social-media-link">FaceBook</h1>
                            <h1 className="social-media-link">Twitter</h1>
                            <h1 className="social-media-link">Instagram</h1>
                        </div>
                    </div>
                </div>
            </GoldenSparrowAboutPageModal>
    )
}

export default GoldenSparrowAboutPage;
