

export const GSStudio_HomePageData = [
    {
        brandlogo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2FGSBrandLogo.png?alt=media&token=4e7012d0-1671-4c39-8e1a-bd9bc60e78fc",
        magicIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2F1865-shooting-stars-lineal.gif?alt=media&token=e16a812c-f98a-4350-ba5d-84bd6b7e20ec",
        eyeIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Feye.gif?alt=media&token=aac0fce3-5f7c-429b-bf18-afe80f7e8f2b",
        boatIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fboat.gif?alt=media&token=9cbe978e-0f0e-4fe4-b305-01e6e26ef2e7",
        scrollHorizontalIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fscroll-horizontal.gif?alt=media&token=8b663d96-e30c-47c3-9dad-532342af29f1",
        vScrollIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fscroll-v.gif?alt=media&token=3bd495fd-a706-4161-b455-f7fde2d20182",
        swipe: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fswipe.gif?alt=media&token=b3e18fc3-d9c9-40aa-bb30-7fa1f2f8598d",
        leftClick: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fmouseclick.gif?alt=media&token=b458337a-582f-453f-bc2b-dd51beb7c1fe",
        sendEmail: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fsend-email.gif?alt=media&token=1309c0a2-4f31-4f73-bb11-6bf9c96c633a",
        watchMediaIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2F499-clipboard-film-clap-outline.gif?alt=media&token=affd834e-d3c7-4c65-88d6-f88612bddd6c",
        codeIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-a8f7c.appspot.com/o/Images%2Fcode.gif?alt=media&token=ec611f60-b3bb-4145-92ef-9c9aa1174253",
        gearsAndPebblesImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FGearsAndPebbles.png?alt=media&token=c2d09256-0e69-4e68-b6dc-0339fd92fb4e",
        gold: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FGold.png?alt=media&token=a1181592-58c3-4e0b-9f9a-d67a93170981",
        crystals: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FCrystals.png?alt=media&token=f5cf374f-be27-4be6-b125-712e9b8d3435",
        crystalsIceburg: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FCrystal_Iceburg.png?alt=media&token=165753d1-c889-49a2-a5bd-abb2cd0b3e1b",
        GSBrandLogo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FGSBrandLogo.png?alt=media&token=0fa4b3d0-52a3-44d5-9638-878e045f25ca",
        mermaidImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FMermaid.png?alt=media&token=31a01f68-d067-49cd-b1a9-a0db382aa49d",
        shake: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2Fshake.png?alt=media&token=795afb23-99f8-471e-8f26-604ecfa6010a",
        barrelBigSplash: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FBarrelAgedBigSplash.png?alt=media&token=68029fa3-78f4-4209-a4f6-88df57a3d253",
        crystalsIceburgImage2: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FCrystal_Iceburg_Master2.png?alt=media&token=e46aceb6-a021-4f0b-a22c-4cee88e28007",
        Fruity: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FFruity.png?alt=media&token=4f1bff91-030e-4c73-a58b-5c1e50bacb5c",
        PersonalPhoto: "https://firebasestorage.googleapis.com/v0/b/goldensparrow-2a1bc.appspot.com/o/images%2FCRopped.png?alt=media&token=79685746-e834-4a7c-a3dc-4338fd981c40",
        cartoon: "https://firebasestorage.googleapis.com/v0/b/goldensparrow-2a1bc.appspot.com/o/images%2Fcartoon.gif?alt=media&token=2383490c-d64e-41b9-982d-1f78c7e269a4",
        barrelAgedBlueBerryTransparent: "https://firebasestorage.googleapis.com/v0/b/goldensparrow-2a1bc.appspot.com/o/images%2FBarrelAgedBlueBerry_Transparent2.png?alt=media&token=083dcfac-0a2a-4b3e-bdef-ba148cacf55e",
        aAndSWatchProjectImage: "https://firebasestorage.googleapis.com/v0/b/goldensparrow-2a1bc.appspot.com/o/images%2FArnoldAndSon.png?alt=media&token=5efe5848-2f36-4f5d-98c0-efdad17e9156",
        kiboImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-bf173.appspot.com/o/images%2FKibo.png?alt=media&token=2e4134e9-b97b-489e-8b8a-a63361b38a23",
        bugattiImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-bf173.appspot.com/o/images%2FbugattiImg.png?alt=media&token=c960c1ac-482b-4c14-9abc-148998cf6aed",
        alaFutCanImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FALAFU_RousseRustre_3D.png?alt=media&token=2bfeccf6-0597-45eb-92fe-7801519edd1a",
        threeDAnimationLogo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2F3DAnimation.png?alt=media&token=58d53806-bf54-4c60-8c4d-c490f337158b",
        videoEditing: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FVideoEditing.png?alt=media&token=a4f41bd6-3cd0-42a8-b5cd-38ce563bb7a2",
        frontEnd: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FFrontEnd.png?alt=media&token=4e63989e-de89-489f-b7d7-60af769bd1f8",
        jsAnim: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FjsAnimationLibraries.png?alt=media&token=fced9426-ff75-4a5f-9762-8c549808494e",
        backEnd: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2Fbackend.png?alt=media&token=2147ae74-52ee-45c5-9a4f-564d1c680412",
        blueBerrySplash: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FBlueBerrySplash.png?alt=media&token=ff00d96a-4dd9-415e-9292-6e3df8c080f2",
        barrelAgedWireframe: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FBarrelAged_BlueBerry_WireFrameShot.png?alt=media&token=ed2e51bd-28e6-4baf-9f27-2213b483ca9c",
        appleSplash: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FAppleSplash.png?alt=media&token=272e98d5-d51d-4815-ac16-6b056271c4ad",
        appleSplashWireFrame: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FAppleSplash_Wireframe.png?alt=media&token=063069bd-421a-4186-960c-770444876ac7",
        toonShader: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FToonShader.png?alt=media&token=b8c42538-4537-4ace-aed5-858d3da64c22",
        aandsWatch: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FCrystal_Iceburg9.png?alt=media&token=b1ec4584-7805-4d48-a690-6f38c450b328",
        aandsWatchWireframe: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FCrystal_Iceburg_AO2.png?alt=media&token=080ebad4-3f0a-45d7-a107-0b4a476dfaf1",
        aandsExploded: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FAAndSExplodedShot.png?alt=media&token=260bb24d-0c52-4572-9b48-5d37b132a04b",
        aandsExploded_WireFrame: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2FAAndSExplodedShot_Wirefrrame.png?alt=media&token=6df8638e-e340-442f-99ea-a347b6a8e3ce",
        swipeLeftIcon: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/images%2Fanimation_500_l4dswf06.gif?alt=media&token=97595f4d-f2cb-4a01-aa02-cee7a1417baa",
        brands: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2FBrands.png?alt=media&token=db6c4266-7dd8-4478-8b24-367e4ad8068f",
        socketIOLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2F1_tOitxCwTNcS3ESstLylmtg.png?alt=media&token=c0961fdb-6c26-4e0d-99c7-cc09c3bebe19",
        angularLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2FAngular.png?alt=media&token=33f40617-5ab8-431f-bc2d-432d06b95616",
        blenderLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2FBlender.png?alt=media&token=ad9b5eee-a5da-4c1e-a065-8b112e802e12",
        psLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2FPhotoshop.png?alt=media&token=18a69808-b971-4e99-a0b2-bb9ec2814962",
        reactLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2FReactJS_Logo.png?alt=media&token=c69d7e9d-23c0-4f4c-9275-81b8409bf1f2",
        unrealLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2FUnreal_Engine_Logo.png?alt=media&token=f9d4b532-3fcb-4640-bd30-9c74b4133969",
        afterEffectsLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fadobe_after_effects_macos_bigsur_icon_190464.png?alt=media&token=15c76e39-8fcc-448a-bcdd-d09da5bfcb6e",
        awsLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Faws.png?alt=media&token=a1ba5bee-d608-4f31-bda8-a84633a9a41c",
        figmaLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Ffigma.png?alt=media&token=2e4bfb97-ec53-4f7c-885e-006442c29b94",
        firebaseLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Ffirebase.png?alt=media&token=943a7aa8-bba2-4435-b346-3fa3a4aabe07",
        houdiniLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fhoudini.png?alt=media&token=9468fd2c-3183-4e05-89c6-b80caca90fd1",
        realFlowLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Flogo-RealFlow.png?alt=media&token=ca54b70f-aefc-4ed1-9d1b-19b71370b2a1",
        mayaLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fmaya.jpg?alt=media&token=73cf6b4c-15ba-47b9-b211-d5fde5da7efa",
        mongoLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fmongo.png?alt=media&token=98f453cb-c14c-4f9d-9e67-e850ce8fe8dc",
        mySQLLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fmysql.png?alt=media&token=135f60b5-269c-4ccb-8862-4fffa1b032a6",
        nextLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fnext-js_Logo.png?alt=media&token=e190ded9-7416-4666-91fc-b87269079176",
        postGreLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fpostgre.png?alt=media&token=664eedff-38ab-4fd0-b93e-6843c42ec26c",
        pwaLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fpwa_logo.png?alt=media&token=1b9b015b-66c7-4b75-841f-7655440989cd",
        xdLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/logos%2Fxd.png?alt=media&token=72903a87-51d8-4ab5-98ed-6335fdb4cfd1",
        snap2: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2FASnap2-min.png?alt=media&token=4aacaad2-9786-4c14-8519-757398028d82",
        snap5: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2FAsnap55-min.png?alt=media&token=892a87f4-de38-4bf1-8696-082853e2e421",
        last: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2Flastsnap-min.png?alt=media&token=a8b76762-ca80-435a-af5a-11a8891aa10a",
        gsBrandLogoArrowV: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2FGSBrandLogo_Vertical_Arrow.png?alt=media&token=98de8235-41a8-4872-b490-fcd6a44573bd",
        gsBrandLogoArrowH: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2FGSBrandLogo_Horizontal_Arrow.png?alt=media&token=d0dff659-45ba-4b3c-a0be-f366b607c672",
        clickHere: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/images%2F52603-click-here.gif?alt=media&token=a536381b-9c8e-4ed1-bd74-118267cef16e"
    }
]
