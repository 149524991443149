import React, {useState, useEffect, useRef} from "react";
import "./GlobalCircularLoadingScreen.scss";
import {gsap} from "gsap";



const CircularLoadingSplashToHome = () =>{

    const [redirectTimeout, setRedirectTimeout] = useState(false);
    const refPercent = useRef(null);
    const refSVGCircleProgress = useRef(null);
    const refSVGCircleProgressPath = useRef(null);

    useEffect(() => {
        setTimeout(()=>{
            var svgCircleProgress1 =  refSVGCircleProgress.current //document.getElementById("svgCircleProgress1");
            var svgCircleProgress1Path1 = refSVGCircleProgressPath.current//document.getElementById("svgCircleProgressPath1");
            var tween, percentElement = false;
            var tl =gsap.timeline({duration: 1, ease: 'Power4.easeOut'})
            gsap.set(svgCircleProgress1Path1, {drawSVG:0});
            function start(){
                tween = tl.fromTo(svgCircleProgress1Path1,  {
                    drawSVG:"0"
                }, {
                    drawSVG:'0 100%',
                    onUpdate:setPercentage,
                    onComplete: redirectToHomeRoute,
                    duration: 8
                });
            }
            function setPercentage(){
                if (!percentElement){
                    percentElement = refPercent.current;
                }
                var v = Math.round((tl.progress() * 100));
                //v /= 10; // for use when you want 0.1 % use 1000 above if you do
                percentElement.innerHTML = v + " %";
                percentElement.style.opacity = tl.progress();
            }
            function redirectToHomeRoute(){
                // refirect to Home route
                setRedirectTimeout(true)
            }
            start();
        })
    }, []);

    return(
        <>
            {
                !redirectTimeout ? (
                    <div className="global-circular-loading-screen-container-splash-to-home">
                        <svg id="svgCircleProgress1" className="svgloader" height="400" width="400" viewBox={"0 0 100 100"} ref={refSVGCircleProgress}>
                            <circle id="svgCircleProgressPath1" cx="50" cy="50" r="40" stroke="#3a3530" strokeWidth="1" fill="transparent" transform="rotate(-90, 50, 50)" ref={refSVGCircleProgressPath}/>
                        </svg>
                        <div id="global-percent" ref={refPercent}>0%</div>
                        <h1 className="global-progress-ring-title">GOLDEN SPARROW STUDIO</h1>
                        <h1 className="global-progress-ring-title">Loading...</h1>
                    </div>
                ) : <div/>
            }
        </>
    )
}

export default CircularLoadingSplashToHome;
