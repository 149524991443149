import React,{Fragment, useState} from "react";
import ReactDOM from "react-dom";
import  "./GoldenSparrowShowReelModal.scss";


const ShowReelBackDrop = props =>{
    return <div className={"showReelBackdrop"}/>
}

const ShowreelModalOverlay = props =>{
    return(
        <div className={"showreelModal"}>
            <div className={"showreelContent"}>{props.children}</div>
        </div>
    )
}

const portalElements_Showreel = document.getElementById('overlays-for-showreel-gs');

const GoldenSparrowShowreelModal = (props) =>{
    return(
        <Fragment>
            {ReactDOM.createPortal(<ShowReelBackDrop/>,portalElements_Showreel)}
            {ReactDOM.createPortal(<ShowreelModalOverlay>{props.children}</ShowreelModalOverlay>,portalElements_Showreel)}
        </Fragment>
    )
}

export default GoldenSparrowShowreelModal;
