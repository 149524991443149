import React, {useContext, useEffect, useRef} from "react";
import {gsap} from "gsap";
import "./SplashScrollingServices.scss"
import {GoldenSparrowStudioCursorContext} from "../../Widgets/Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";



const SplashScrollingServices = () =>{

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;

    const splashTagReelRowRefs = useRef([]);
    splashTagReelRowRefs.current = [];

    const addToSplashTagReelRowRefs = el => {
        if (el && !splashTagReelRowRefs.current.includes(el)) {
            splashTagReelRowRefs.current.push(el);
        }
    };

    const { setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode, imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
        setFilterImplementation("grayScale(0%)")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode, imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    useEffect(() => {
        setTimeout(()=>{
            const rows =   splashTagReelRowRefs.current //document.querySelectorAll(".splash-tagreel-row");
            rows.forEach(function (e, i) {
                let row_width = e.getBoundingClientRect().width;
                let row_item_width = e.children[0].getBoundingClientRect().width;
                let initial_offset = ((2 * row_item_width) / row_width) * 100 * -1;
                let x_translation = initial_offset * -1;
                // console.log(x_translation);
                gsap.set(e, {
                    xPercent: `${initial_offset}`
                });
                let duration = 5 * (i + 1);
                var tl = gsap.timeline();
                tl.to(e, {
                    ease: "none",
                    duration: duration,
                    xPercent: 0,
                    repeat: -1
                });
            });
        })
    }, []);

    return(
        <div className="splash-scrolling-services"
             onMouseEnter={()=> handleCursorMouseEnter("medium","Services","normal",BrandLogoURI)}
             onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
        >
            <section className="splash-tagreel">
                <div className="splash-tagreel-content">
                    <div className="splash-tagreel-items" role="marquee">
                        <div className="splash-tagreel-row" ref={addToSplashTagReelRowRefs}>
                            <div className="splash-tagreel-item"><span>Webdesign</span></div>
                            <div className="splash-tagreel-item -stroke"><span>Development</span></div>
                            <div className="splash-tagreel-item"><span>Webdesign</span></div>
                            <div className="splash-tagreel-item -stroke"><span>Development</span></div>
                            <div className="splash-tagreel-item"><span>Webdesign</span></div>
                        </div>
                        <div className="splash-tagreel-row" ref={addToSplashTagReelRowRefs}>
                            <div className="splash-tagreel-item -stroke"><span>APP </span></div>
                            <div className="splash-tagreel-item"><span>Development</span></div>
                            <div className="splash-tagreel-item -stroke"><span>App</span></div>
                            <div className="splash-tagreel-item"><span>Development</span></div>
                            <div className="splash-tagreel-item -stroke"><span>App</span></div>
                        </div>
                        <div className="splash-tagreel-row" ref={addToSplashTagReelRowRefs}>
                            <div className="splash-tagreel-item -stroke"><span>Cloud</span></div>
                            <div className="splash-tagreel-item"><span>Services</span></div>
                            <div className="splash-tagreel-item -stroke"><span>Cloud</span></div>
                            <div className="splash-tagreel-item"><span>Services</span></div>
                            <div className="splash-tagreel-item -stroke"><span>Cloud</span></div>
                        </div>
                        <div className="splash-tagreel-row" ref={addToSplashTagReelRowRefs}>
                            <div className="splash-tagreel-item"><span>3D Animation</span></div>
                            <div className="splash-tagreel-item -stroke"><span>VFX</span></div>
                            <div className="splash-tagreel-item"><span>3D Animation</span></div>
                            <div className="splash-tagreel-item -stroke"><span>VFX</span></div>
                            <div className="splash-tagreel-item"><span>3D Animation</span></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SplashScrollingServices;
