import React from "react";


const GoldenSparrowAnimationReelContext = React.createContext({
    goldenSparrowAnimationReelURL: "",
    addURl: (url) =>{},
    removeURl: (id) =>{}
})

export default GoldenSparrowAnimationReelContext;
