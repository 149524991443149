import React,{useState} from "react";
import SplashPreEnterScreen from "../SplashScreen/PreScreenEnter/SplashPreEnterScreen";
import CircularLoadingSplashToHome from "../SplashScreen/CircularLoadingSplashToHome/CircularLoadingSplashToHome";


const SplashToHomeScreenPage = () =>{

    const [switchToHomePage,setSwitchToHomePage] = useState(false)

    const handleSplashToHomeScreenCallback = (callBackVal) =>{
        if(callBackVal){
            setSwitchToHomePage(prevState => !prevState);
        }
    }
    return(
        <>
            {
                !switchToHomePage ? <SplashPreEnterScreen onSplashLeaveCallback={handleSplashToHomeScreenCallback} /> : <CircularLoadingSplashToHome />
            }
        </>
    )
}

export default SplashToHomeScreenPage;
