import React  from "react";
import "./AllProjectsDisplaySection.scss";

import Project_ArnoldAndSon from "./AllProjects/Project_ArnoldAndSon/Project_ArnoldAndSon";
import Project_BarrelAged from "./AllProjects/Project_BarrelAged/Project_BarrelAged";
import Project_SFA from "./AllProjects/Project_SFA/Project_SFA";
import Project_Bugatti from "./AllProjects/Project_Bugatti/Project_Bugatti";
import Project_AlaFut from "./AllProjects/Project_AlaFut/Project_AlaFut";



const AllProjectsDisplaySection = () =>{


    return(
        <div className="all-projects-display-section">
            <div className="work-area">
                <Project_BarrelAged/>
                <span className="line-2"/>
                <Project_ArnoldAndSon/>
                <span className="line-2"/>
                <Project_SFA/>
                <span className="line-2"/>
                <Project_Bugatti/>
                <span className="line-2"/>
                <Project_AlaFut/>
            </div>
        </div>
    )
}

export default AllProjectsDisplaySection;
