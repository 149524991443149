import React, {useEffect, useState, useRef, useContext} from "react";
import "./BrandHomeScreenWidget.scss";


import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../../hooks/useOnScreen";
import {BarrelAgedCursorContext} from "../../Cursors/BarrelAged_CustomCursor/BarrelAgedCursorManager";
import BA_HomePage_VideoData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_VideoData";

gsap.registerPlugin(ScrollTrigger, SplitText);



const BrandHomeScreenWidget = () =>{

    const refSplashVideoBox = useRef(null);
    const refSplashBarrelAgedVideo = useRef(null);
    const refMarqueeText1 = useRef(null);
    const refMarqueeText2 = useRef(null);

    const onScreenSplashVideoSection = useOnScreen(refSplashVideoBox);

    const { setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(BarrelAgedCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setBlendMode(BlendMode)
        setVideoSource(BA_HomePage_VideoData.AAndSHomePageVideoData[2].BarrelAgedVid)
        setFilterImplementation("grayScale(0%)")
        // setImgSource("https://firebasestorage.googleapis.com/v0/b/shadowfightarena-99fe0.appspot.com/o/ezgif.com-gif-maker.gif?alt=media&token=d503b7ac-2f06-4d37-a24b-711e4b596a5a")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setVideoSource("")
        setBlendMode(BlendMode)
        // setImgSource("")
    }

    useEffect(() => {
        setTimeout(()=>{
            if(onScreenSplashVideoSection){
                setTimeout(startVideo,2)
            }else{
                setTimeout(stopVideo,1)
            }
        })
    }, [onScreenSplashVideoSection]);


    const startVideo = async () => {
        const video =  refSplashBarrelAgedVideo.current; // refSplashBarrelAgedVideo.current;
        try {
            await video.load();
            const VideoTimeLine = gsap.timeline({paused: true});
            VideoTimeLine.from(refSplashBarrelAgedVideo.current,{
                opacity: 0,
                duration: 2,
                ease: 'Power4.easeInOut',
            },"startVideo")
                .to(refSplashBarrelAgedVideo.current,{
                    opacity: 1,
                    duration: 2,
                    ease: 'Power4.easeInOut',
                },"stopVideo")
            VideoTimeLine.play()
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
        }
    }
    const stopVideo = async () => {
        const video =  refSplashBarrelAgedVideo.current; // refSplashBarrelAgedVideo.current;
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }

    useEffect(() => {
        setTimeout(()=>{
            //-----------------------------------------------------------------------------------------------------------
            let direction = -1; // 1 = forward, -1 = backward scroll
            const roll1 = roll(refMarqueeText1.current, {duration: 8},  true),
                roll2 = roll(refMarqueeText2.current, {duration: 8}, true),
                scroll = ScrollTrigger.create({
                    scroller: '#barrel-aged-project-container' ,
                    horizontal: true,
                    onUpdate(self) {
                        if (self.direction !== direction) {
                            direction *= -1;
                            gsap.to([roll1,roll2], {timeScale: direction, overwrite: true});
                        }
                    }
                });
            // helper function that clones the targets, places them next to the original, then animates the xPercent in a loop to make it appear to roll across the screen in a seamless loop.
            function roll(targets, vars, reverse) {
                const tl = gsap.timeline({
                    repeat: -1,
                    onReverseComplete() {
                        this.totalTime(this.rawTime() + this.duration() * 10); // otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
                    }
                });
                vars = vars || {};
                vars.ease || (vars.ease = "none");
                gsap.utils.toArray(targets).forEach(el => {
                    let clone = el.cloneNode(true);
                    // el.parentNode.appendChild(clone);
                    gsap.set(clone, {position: "absolute", top: el.offsetTop, left: el.offsetLeft + (reverse ? -el.offsetHeight : el.offsetHeight)});
                    tl.to([el, clone], {yPercent: reverse ? 100 : -100, ...vars}, 0);
                });
                return tl;
            }
            //-----------------------------------------------------------------------------------------------------------
        })
    }, []);


    return(
        <>
            <div className="barrel-aged-brand-home-screen-widget" >
                <div className="barrel-aged-vertical-pillar" >
                    <div className="vertical-sliding-text-section marque-text" ref={refMarqueeText1}>
                        <h1 className="vertical-sliding-text-barrel-aged"><span className="special-font">B</span>ARREL<span className="special-font">A</span>GED</h1>
                    </div>
                    <div className="vertical-sliding-text-section marque-text2" ref={refMarqueeText2}>
                        <h1 className="vertical-sliding-text-barrel-aged"><span className="special-font">B</span>ARREL<span className="special-font">A</span>GED</h1>
                    </div>
                </div>
                <div className="splash-video-container-box" ref={refSplashVideoBox}
                     onMouseEnter={() => handleCursorMouseEnter("large", "Barrel Aged", "normal")}
                     onMouseLeave={() => handleCursorMouseLeave("small", "Barrel Aged", "difference")}
                >
                    <div className="barrel-image-inner-box">
                        <video id={"barrel-aged-splash-video-home"} playsInline preload={"true"} loop muted className={"intro-all-flavours-video"}
                               poster="" ref={refSplashBarrelAgedVideo}>
                            <source src={BA_HomePage_VideoData.AAndSHomePageVideoData[2].BarrelAgedVid} type={"video/mp4"}/>
                        </video>
                        {/*<img src={BarrelAgedIntroImage} alt="" className="barrel-intro-image"/>*/}
                    </div>
                    <div className="branding-section">
                        <h1 className="opening-statement">Lets make your Day</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandHomeScreenWidget;
