import React, {useEffect, useRef, useState} from "react";
import "./SplashPreEnterScreen.scss";
import {gsap} from "gsap";
import useOnScreen from "../../../hooks/useOnScreen";
import {HomePage_VideoData} from "../../HomePage/HomePage_VideoData";
import {GSStudio_HomePageData} from "../../HomePage/HomePage_Data";

const SplashPreEnterScreen = ({onSplashLeaveCallback}) =>{

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    const refLeftLogoTitle = useRef(null);
    const refRightLogoTitle = useRef(null);
    const refBrandLogo = useRef(null);
    const refActionButtonSection = useRef(null);
    const refHeadPhoneIcon = useRef(null);
    const [leaveSplashPreEnterScreen, setLeaveSplashPreEnterScreen] = useState(false);
    const [buttonEnabled,setButtonEnabled] = useState(false);

    useEffect(()=>{
        if(leaveSplashPreEnterScreen){
            onSplashLeaveCallback(leaveSplashPreEnterScreen)
        }
    },[leaveSplashPreEnterScreen])

    useEffect(() => {
        setTimeout(()=>{
            gsap.set(".before, .after", {opacity: 0, scale: 0.5});
            gsap.to(".before, .after", {
                scale: 1.2,
                duration: 1.5,
                stagger: {
                    each: 0.5,
                    repeat: -1
                }
            });
            gsap.to(".before, .after", {
                opacity: 1,
                duration: 0.75,
                stagger: {
                    each: 0.5,
                    repeat: -1,
                    yoyo: true
                }
            });
            //----------------------------------------------------------------------------------------
            let leftLogoTitle = refLeftLogoTitle.current;
            gsap.fromTo(leftLogoTitle,
                {
                    x: 200,
                    autoAlpha:0
                },{
                    x: 0,
                    autoAlpha: 1,
                    duration: 2,
                    ease: "Quint.easeOut",
                    delay: 1
                }
            )
            let rightLogoTitle = refRightLogoTitle.current;
            gsap.fromTo(rightLogoTitle,
                {
                    x: -200,
                    autoAlpha:0
                },{
                    x: 0,
                    autoAlpha: 1,
                    duration: 2,
                    ease: "Quint.easeOut",
                    delay: 1
                }
            )
            //----------------------------------------------------------------------------------------
        })
    }, []);

    useEffect(() => {
        setTimeout(()=>{
            if(onScreen){
                setTimeout(startVideo,1000)
            }else{
                setTimeout(stopVideo,5)
            }
        })
    }, [onScreen]);

    useEffect(() => {
        setTimeout(enableButton,2000)
    }, []);

    const enableButton = () =>{
        setButtonEnabled(true)
    }

    const startVideo = async () => {
        const video = document.querySelector('#video_background_start_exp');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = document.querySelector('#video_background_start_exp');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const handle_zoomOutOfPreSplashScreen = () =>{
        if(buttonEnabled){
            let leftLogoTitle = refLeftLogoTitle.current;
            gsap.fromTo(leftLogoTitle,
                {
                    x: 0,
                    scale: 1,
                    autoAlpha:1
                },{
                    x: -2000,
                    scale: 8,
                    autoAlpha: 0,
                    duration: 1.2,
                    ease: "Quint.easeOut",
                }
            ) ;
            let brandLogo= refBrandLogo.current;
            gsap.fromTo(brandLogo,
                {
                    scale: 1,
                    autoAlpha:1
                },{
                    scale: 13,
                    autoAlpha: 0,
                    duration: 1.2,
                    ease: "Quint.easeOut",
                }
            )
            let rightLogoTitle = refRightLogoTitle.current;
            gsap.fromTo(rightLogoTitle,
                {
                    x: 0,
                    scale: 1,
                    autoAlpha:1
                },{
                    x: 2000,
                    scale: 8,
                    autoAlpha: 0,
                    duration: 1.2,
                    ease: "Quint.easeOut",
                }
            )
            let actBtn = refActionButtonSection.current;
            gsap.fromTo(actBtn,
                {
                    autoAlpha:1
                },{
                    autoAlpha: 0,
                    duration: 1.2,
                    ease: "Quint.easeOut",
                }
            )
            let actHDPhone = refHeadPhoneIcon.current;
            gsap.fromTo(actHDPhone,
                {
                    autoAlpha:1
                },{
                    autoAlpha: 0,
                    duration: 1.2,
                    ease: "Quint.easeOut",
                }
            )
            setTimeout(stopVideo,50)
            setTimeout(handleLeaveSplashScreen,2500)
        }
    }

    const handleLeaveSplashScreen = async () =>{
        await setLeaveSplashPreEnterScreen(true)
    }

    return(
        <div className="splash-pre-enter-screen" ref={ref}>
            <video id={"video_background_start_exp"} playsInline preload={"true"} loop className={"start-exp-video"} poster="">
                <source src={HomePage_VideoData[3].greenNebula} type={"video/mp4"}/>
            </video>
            <div className="enter-website-container">
                <div className="brand-logo-wrapper">
                    <h1 className="pre-logo-brand-name" ref={refLeftLogoTitle}>Golden</h1>
                    <img src={GSStudio_HomePageData[0].GSBrandLogo} alt="" className="brand-logo" ref={refBrandLogo}/>
                    <h1 className="post-logo-brand-name" ref={refRightLogoTitle}>Sparrow</h1>
                </div>
                <div className="pulse" ref={refActionButtonSection} onClick={handle_zoomOutOfPreSplashScreen}>
                    <i className="fa fa-phone" aria-hidden="true">
                        <span className="before">
                        </span>
                        <span className="after">
                        </span>
                    </i>
                    <h1 className="text">START EXPERIENCE</h1>
                    <h1 className="text-msg">HEADPHONES RECOMMENDED</h1>
                </div>
                <svg className="headphone" width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                     style={{opacity: 1, visibility: 'inherit'}} ref={refHeadPhoneIcon}>
                    <path
                        d="M15 0C6.72896 0 0 7.34087 0 16.3636V23.1818C0 23.5587 0.304964 23.8636 0.681839 23.8636C1.05871 23.8636 1.36368 23.5587 1.36368 23.1818V16.3636C1.36368 8.09257 7.48073 1.36361 15 1.36361C22.5193 1.36361 28.6363 8.09257 28.6363 16.3636V23.1818C28.6363 23.5587 28.9413 23.8636 29.3182 23.8636C29.695 23.8636 30 23.5587 30 23.1818V16.3636C29.9999 7.34087 23.271 0 15 0Z"
                        fill="#cea239">
                    </path>
                    <path
                        d="M7.49997 16.3635H5.45452C3.95041 16.3635 2.72723 17.5866 2.72723 19.0908V27.2726C2.72723 28.7767 3.95034 29.9999 5.45452 29.9999H7.49997C7.87684 29.9999 8.18181 29.6949 8.18181 29.3181V17.0454C8.18181 16.6685 7.87684 16.3635 7.49997 16.3635ZM6.81819 28.6362H5.45452C4.70281 28.6362 4.0909 28.0243 4.0909 27.2726V19.0908C4.0909 18.3391 4.70281 17.7272 5.45452 17.7272H6.81813V28.6362H6.81819Z"
                        fill="#cea239">
                    </path>
                    <path
                        d="M24.5454 16.3635H22.5C22.1231 16.3635 21.8181 16.6685 21.8181 17.0454V29.3181C21.8181 29.6949 22.1231 29.9999 22.5 29.9999H24.5454C26.0495 29.9999 27.2727 28.7768 27.2727 27.2726V19.0908C27.2727 17.5866 26.0495 16.3635 24.5454 16.3635ZM25.909 27.2726C25.909 28.0243 25.2971 28.6362 24.5454 28.6362H23.1818V17.7271H24.5454C25.2971 17.7271 25.909 18.339 25.909 19.0908V27.2726Z"
                        fill="#cea239">
                    </path>
                </svg>
            </div>
            <div className="message-footer">
            </div>
        </div>
    )
}

export default SplashPreEnterScreen;
