import React,{Fragment} from "react";
import ReactDOM from "react-dom";
import styles from  "./GoldenSparrowContactUsPageModal.scss";



const ContactUsBackDrop = props =>{
    return <div className={styles.contactUsBackdrop}/>
}

const ContactUsModalOverlay = props => {
    return(
        <div className={styles.contactUsModal}>
            <div className={styles.contactUsContent}>{props.children}</div>
        </div>
    )
}

const portalElements_ContactUs = document.getElementById('overlays-for-contact-us-page-gs');

const GoldenSparrowContactUsPageModal = (props) =>{
    return(
        <Fragment>
            {ReactDOM.createPortal(<ContactUsBackDrop/>,portalElements_ContactUs)}
            {ReactDOM.createPortal(<ContactUsModalOverlay>{props.children}</ContactUsModalOverlay>,portalElements_ContactUs)}
        </Fragment>
    )
}
export default GoldenSparrowContactUsPageModal;
