import React, {useContext, useEffect, useState,useRef} from "react";
import "./GoldenSparrowShowReelPage.scss";
import GoldenSparrowShowreelModal from "../GoldenSparrowShowreelModal";
import GoldenSparrowAnimationReelContext from "../../../../Context/GoldenSparrowAnimationReelContext";
import GoldenSparrowAnimationReelShownContext from "../../../../Context/GoldenSparrowAnimationReelShownContext";




const GoldenSparrowShowReelPage = () =>{


    const ctx = useContext(GoldenSparrowAnimationReelContext);
    const ctx_ShowReelModal = useContext(GoldenSparrowAnimationReelShownContext);
    let animationReelURL = ctx.goldenSparrowAnimationReelURL;
    const refShowReelVideo = useRef(null);

    const [animURl,setAnimURl] = useState(animationReelURL);


    // useEffect(()=>{
    //     setTimeout(()=>{
    //         if(animationReelURL !== ""){
    //             setAnimURl(animationReelURL)
    //         }
    //     })
    // },[])

    useEffect(() => {
        setTimeout(()=>{
            if(animURl !== ""){
                setTimeout(startVideo,3000)
            }
        })
    }, [animURl]);


    const handleCloseDialogue = () =>{
        ctx.goldenSparrowAnimationReelURL = "";
        setTimeout(stopVideo,1)
        ctx_ShowReelModal.closeGoldenSparrowAnimationReel()
    }



    const startVideo = async () => {
        const video = refShowReelVideo.current //document.querySelector('#showreel-video_background');
        console.log(animURl,"Real URl")
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = refShowReelVideo.current //document.querySelector('#showreel-video_background');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }

    const handleOnFinishedVideo = async () =>{
        await stopVideo();
        ctx.goldenSparrowAnimationReelURL = "";
        setTimeout(stopVideo,1)
        ctx_ShowReelModal.closeGoldenSparrowAnimationReel()
    }


    return(
        <GoldenSparrowShowreelModal>
            <div className="golden-sparrow-showreel-page">
                    <div className="golden-sparrow-showreel-dialogue">
                        <video id={"showreel-video_background"} playsInline preload={"true"} loop className={"showreel-video_background"}  ref={refShowReelVideo} onEnded={handleOnFinishedVideo}>
                            <source src={animURl}/>
                        </video>
                        <div className="button-close-video-dialogue" onClick={handleCloseDialogue}>x</div>
                    </div>

            </div>
        </GoldenSparrowShowreelModal>
    )
}
export default GoldenSparrowShowReelPage;
