import React, {useContext, useEffect, useRef, useState} from "react";
import "./PersonalIntroWidget.scss";

//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
//----------------------------------------------------------------------------------------------------------------------


const PersonalIntroWidget = () =>{

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const BoatIcon = GSHOMEDATA.boatIcon;

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    return(
        <div className="personal-intro-widget"
             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",BoatIcon)}
             onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
        >
            {/*<div className="dotPanel"></div>*/}
            <div className="photo-section">
                <img src={GSStudio_HomePageData[0].PersonalPhoto} alt="" className="personal-photo"/>
                <h1 className="signature">Sagar Mitkari</h1>
            </div>
            <div className="about-me-section">
                <div className={"about-me-wrapper"}>
                    {/*<h1 className={"about-me-statement"}><span>Creator</span> <span className={"special-font"}>of</span> <span>Futuristic Designs </span></h1>*/}
                    <div className="shadows">
                        <span>C</span><span>r</span><span>e</span><span>a</span><span>t</span><span>o</span><span>r</span><span className={"special-font"}> of </span><span>Futuris</span>
                    </div>
                    <h1 className={"about-me-statement"}><span>And Thinker</span> <span className={"special-font"}>of </span><span>Illusive</span> <span>Ideas</span></h1>
                    <p>with Locomotive Scroll</p>
                </div>
                <div className="about-me-details">
                    <h1 className="about-me-statement">
                        I’m a 10X Fullstack Developer, graphic designer, and an innovative 3D digital artist with over 10 years’ of professional experience & a degree in Industrial Engineering + diploma in 3D animation. A Perfect combination of left and right side of brain.
                    </h1>
                    <h1 className="about-me-para">
                        I’m Inquisitive, passionate and always strive for innovation. Mixing art and creativity with technology is like fireball in my hand. Currently, I work as a freelance 3d artist , software developer, and director of Golden Sparrow Studio. Ever since I was a kid, art has fascinated me. To this day, i use art and technology to create a fine blend between illusion and reality that makes brands stand out in crowd with a crown.
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default PersonalIntroWidget;
