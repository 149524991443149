import {useReducer,useContext} from "react";
import GoldenSparrowAnimationReelContext from "../../../Context/GoldenSparrowAnimationReelContext";



// const ctx = useContext(GoldenSparrowAnimationReelShownContext);

const defaultShowReelState = {
    goldenSparrowAnimationReelURL: ""
}

const ShowReelReducer = (state,action) => {
    if(action.type === "ADD_SHOWREEL_URL"){
        const url = action.item;
        return{
            goldenSparrowAnimationReelURL: url
        }
    }else if(action.type === "REMOVE_SHOWREEL_URL"){
        const url = action.item;
        return{
            goldenSparrowAnimationReelURL: ""
        }
    }
    return defaultShowReelState;
}

const ShowReelProvider = props => {

    const [showReelState, dispatchShowReelURlChangeAction] = useReducer(ShowReelReducer, defaultShowReelState);

    const addnewShowReelURLAction = item => {
        dispatchShowReelURlChangeAction({type: 'ADD_SHOWREEL_URL',item})
    }
    const removeShowReelURlAction = item => {
        dispatchShowReelURlChangeAction({type: 'REMOVE_SHOWREEL_URL',item})
    }

    const showReelContext = {
        goldenSparrowAnimationReelURL: showReelState.goldenSparrowAnimationReelURL,
        addURl: addnewShowReelURLAction,
        removeURl: removeShowReelURlAction
    }

    return <GoldenSparrowAnimationReelContext.Provider value={showReelContext}>
                {props.children}
           </GoldenSparrowAnimationReelContext.Provider>


}
export default ShowReelProvider;
