import React, {useContext} from "react";
import "./OurExpertise.scss";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";



const OurExpertise = () =>{


    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const CodeIcon = GSHOMEDATA.codeIcon;

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode} = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    return(
        <div className="our-expertise">
            <div className="vertical-column">
                <h1 className="section-title">OUR EXPERTISE</h1>
                <h1 className="section-title">.</h1>
            </div>
            <div className="expertise-grid"
                 onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",CodeIcon)}
                 onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
            >
                <div className="expertise-column">
                    <div className="spacer"/>
                    <div className="full-column">
                        <h1 className="column-text">Web Design & Development</h1>
                        <div className={"tech-desc"}>
                            <span className="column-desc"><span className="gap"></span>   For Webdesign & prototyping we have expertise in array of tools from Photoshop
                                <img src={GSHOMEDATA.psLogo} alt="" className="tool-logo"/>Figma<img src={GSHOMEDATA.figmaLogo} alt="" className="tool-logo"/>Adobe XD
                                <img src={GSHOMEDATA.xdLogo} alt="" className="tool-logo"/> and many others.
                            </span>
                            <span className="column-desc"><span className="gap"></span> We have special expertise in modern web development javascript frameworks such as React Js
                                <img src={GSHOMEDATA.reactLogo} alt="" className="tool-logo"/>,Next JS (Serverside Rendering)<img src={GSHOMEDATA.nextLogo} alt="" className="tool-logo"/>Angular,
                                <img src={GSHOMEDATA.angularLogo} alt="" className="tool-logo"/>,Javascript, typescript etc.
                            </span>
                            <span className="column-desc"><span className="gap"></span> Data management & security it always crucial for any business. Therefore we provide seamless backend Developement services through latest backend technologies.
                                Be it traditional databases like Mongo DB<img src={GSHOMEDATA.mongoLogo} alt="" className="tool-logo"/>,MySQL<img src={GSHOMEDATA.mySQLLogo} alt="" className="tool-logo"/>Postgre-SQL,
                                <img src={GSHOMEDATA.postGreLogo} alt="" className="tool-logo"/> or cloud backend services such as AWS <img src={GSHOMEDATA.awsLogo} alt="" className="tool-logo"/> or Firebase <img src={GSHOMEDATA.firebaseLogo} alt="" className="tool-logo"/>we cover your back with most efficient & secured backend technologies available in market.
                            </span>
                            <span className="column-desc"><span className="gap"></span>If you want to make your webapps run offline or with live realtime updates we can provide support for this using PWA - Progressive Web Apps
                                <img src={GSHOMEDATA.pwaLogo} alt="" className="tool-logo"/> and socket IO<img src={GSHOMEDATA.socketIOLogo} alt="" className="tool-logo"/>for realtime communication.Not to mention specifically but we also provide CI-CD support for continuous delivery and deployment.
                                Overall you can rely on us for best possible services and performance with cutting edge technologies.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="expertise-column">
                    <div className="half-small-column">
                        <img src={GSHOMEDATA.snap2} alt="" className="grid-image"/>
                    </div>
                    <div className="half-big-column">
                        <img src={GSHOMEDATA.snap5} alt="" className="grid-image"/>
                    </div>
                    <div className="spacer"/>
                </div>
                <div className="expertise-column">
                    <div className="spacer"/>
                    <div className="half-big-column">
                        <h1 className="column-text">App Development</h1>
                        <div className={"tech-desc"}>
                            <span className="column-desc"><span className="gap"></span> We also provide fullstack app development on both Android + iOS Platform using XCode or Android Studio, React Native for Native platform as well as Google Flutter for Hybrid App development.
                            </span>
                            <span className="column-desc"><span className="gap"></span> All the goodies that come with backend developement frameworks can be implemented here with App to provide strong backend.
                            </span>
                        </div>
                    </div>
                    <div className="half-small-column">
                        <img src={GSHOMEDATA.last} alt="" className="grid-image"/>
                    </div>
                </div>
                <div className="expertise-column">
                    <div className="half-small-column">
                        <img src={GSStudio_HomePageData[0].crystals} alt="" className="grid-image"/>
                    </div>
                    <div className="half-big-column">
                        <img src={GSStudio_HomePageData[0].crystalsIceburg} alt="" className="grid-image"/>
                    </div>
                    <div className="spacer"/>
                </div>
                <div className="expertise-column">
                    <div className="spacer"/>
                    <div className="full-column">
                        <h1 className="column-text">3D Animation & VFX</h1>
                        <div className={"tech-desc"}>
                            <span className="column-desc"><span className="gap"></span>Want to create immersive experience for your product? Want to enhance product value? We achieve this using  3D animation which is our core specialty. We have command over more than 30 3D technologies that can take your product visualization to next level and help stand your brand with a crown in crowd.
                                Dont believe us? Watch our 3D projects that we just finished for few clients and you will know what can be possible in digital world.
                            </span>
                            <span className="column-desc"><span className="gap"></span> Weather it is 3D Product Visualization or 3D Animation we bring our expertise , skills and experience with industry standard 3D Animation technologies such as Blender
                                <img src={GSHOMEDATA.blenderLogo} alt="" className="tool-logo"/>,Maya<img src={GSHOMEDATA.mayaLogo} alt="" className="tool-logo"/>Houdini,
                                <img src={GSHOMEDATA.houdiniLogo} alt="" className="tool-logo"/> Unreal Engine <img src={GSHOMEDATA.unrealLogo} alt="" className="tool-logo"/>,Realflow , Substance Painter, Character Creator, Embergen, Eon-Vue and many more...
                            </span>
                            <span className="column-desc"><span className="gap"></span> We can provide your product bespoke identity through 3D product visualization & 3D Animation Advertising (like one you see in our latest projects).
                            </span>
                            <span className="column-desc"><span className="gap"></span>To give those finishing touches and effects we also provide VFX services using  our skills in various softwares such as Adobe AfterEffects, Davinci Resolve, HitFilm Pro, Nukes and many more...
                                Give your product bespoke identity today.
                                We provide special packages for large 3D projects. Drop us a line for more info.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurExpertise;

