import React,{useRef,useEffect} from "react";

import "./IntroRotatingVideoTransition.scss";


//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import GSBrandLogoCircularTransition from "../../assets/videos/IntroSplash_GSLogo.mp4";
//----------------------------------------------------------------------------------------------------------------------


const IntroRotatingVideoTransition  = () =>{


    const ref = useRef(null);

    useEffect(() => {
        setTimeout(()=>{
            gsap.set(ref.current,{transformOrigin: "center", scale: 0, opacity: 0})
            gsap.set(ref.current,{transformOrigin: "center", scale: 1, opacity: 0.95})
            // if(onScreen){
                setTimeout(startVideo,1500)
                const tl = gsap.timeline({
                    // repeat: -1,
                    // yoyo: true,
                    // repeatDelay: 0.5,
                    defaults: { ease: "power3.inOut", duration: 0.8 }
                });
                // ** Important Here is how you can rotate intro scene **
                // tl.to(ref.current, { duration: 3, rotation: 720, scale: 1, width: "100vw",height: "100vh", opacity: 1 });
                tl.to(ref.current, { duration: 3, opacity: 1 });
                tl.to(ref.current, { duration: 2,delay:5, x: '-16.5vw',width: "67vw", height: "45vh", y:'-27vh', opacity: 1 });
                tl.add(proceedToStopVideo)
                tl.to(ref.current, { duration: 1 , delay: 0.5, autoAlpha: 0 });

            proceedToStopVideo()
            // }else{
            //     setTimeout(stopVideo,50)
            // }
        })
    }, []);


    const proceedToStopVideo = () =>{
        setTimeout(stopVideo,5);
    }

    const startVideo = async () => {
        const video = document.querySelector('#rotating_vid');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = document.querySelector('#rotating_vid');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }


    return(
        <div className="intro-video-rotating-transition" ref={ref}>
            <video id={"rotating_vid"} className="StartingSplashVideo" loop playsInline  preload={"true"}  muted >
                <source src={GSBrandLogoCircularTransition} type="video/mp4"/>
            </video>
        </div>
    )
}
export default IntroRotatingVideoTransition;
