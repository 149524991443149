import React,{useRef,useEffect} from "react";
import "./FontFollowCursor.scss";

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../hooks/useOnScreen";
gsap.registerPlugin(ScrollTrigger, SplitText);




const FontFollowCursor = () =>{

    const ref = useRef(null);
    const refFirstTitle = useRef(null);
    const onScreen = useOnScreen(ref);


    useEffect(()=>{
        setTimeout(()=>{

            if(onScreen){
                let firstTitle = refFirstTitle.current;
                gsap.set(firstTitle,{autoAlpha: 1})
                let splitFirstTitle = new SplitText(firstTitle, {type: "chars, words"});
                gsap.fromTo(splitFirstTitle.chars,
                    {
                        rotateX:30,
                        scale:0,
                        y:50,
                        autoAlpha:0
                    },{
                        rotateX:0,
                        autoAlpha:1,
                        scale:1,
                        y:0,
                        duration: 2,
                        stagger: 0.1,
                        ease: "Elastic.easeOut"
                    }
                );
                gsap.to('.text2',{
                    x:'20%',rotate:10,duration:0.85,scrollTrigger:{
                        trigger:'.text2',
                        scrub:true,
                        start:'left 40%',
                        scroller: "#main-container",
                        horizontal: true
                    }
                })
                gsap.to('.text1',{
                    x:'-20%',rotate:-10,duration:0.85,scrollTrigger:{
                        trigger:'.text1',
                        scrub:true,
                        start:'left 30%',
                        scroller: "#main-container",
                        horizontal: true
                    }
                })
                gsap.to('.text3',{
                    x:'-20%',rotate:-10,duration:0.85,scrollTrigger:{
                        trigger:'.text3',
                        scrub:true,
                        start:'left 40%',
                        scroller: "#main-container",
                        horizontal: true
                    }
                })
            }else{
                let firstTitle = refFirstTitle.current;
                gsap.set(firstTitle,{autoAlpha:0})
            }

        })
    },[onScreen])





    return(
        <div className="font-follow-container" ref={ref}>
            <div id="text" ref={refFirstTitle}>Like What you see?</div>
            <div id="text" className="text1">Lets Explore</div>
            <div id="text" className="text2">We have mastered</div>
            <div id="text" className="text3">To Provide mastery</div>
        </div>
    )

}
export default FontFollowCursor;
