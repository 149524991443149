import React, {useContext} from "react";
import "./BrandValuesWidget.scss";


//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
//----------------------------------------------------------------------------------------------------------------------


const BrandValuesWidget = () =>{

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const EyeIcon = GSHOMEDATA.eyeIcon;

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    return(
        <div className="brand-values-widget"
                onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",EyeIcon)}
                onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
        >
            <div className="first-value-proposition" id={"brand-values-widget"} data-scroll data-scroll-direction="horizontal" data-scroll-speed="0.5" data-scroll-target="#brand-values-widget">
                <div className="inner-widget">
                    <div className="index-block">
                        <div className="index-indicator">
                            <h1 className="index-no">1</h1>
                        </div>
                    </div>
                    <div className="value-content-block">
                        <h1 className="value-concept">Imagination is source of Creation</h1>
                        <h1 className="value-statement">Smallest seed of imagination can grow and become the finest Creation on this planet.</h1>
                        <h1 className="value-direction">Unique, creative ideas can define new dimensions of growth for your business.</h1>
                    </div>
                </div>
            </div>
            <div className="second-value-proposition" data-scroll data-scroll-direction="horizontal" data-scroll-speed="3.5" data-scroll-target="#brand-values-widget">
                <div className="inner-widget">
                    <div className="index-block">
                        <div className="index-indicator">
                            <h1 className="index-no">2</h1>
                        </div>
                    </div>
                    <div className="value-content-block">
                        <h1 className="value-concept">Pure Design Idea</h1>
                        <h1 className="value-statement">Once a pure design idea has taken hold of your brain,its impossible to irradiate it.</h1>
                        <h1 className="value-direction">It can grow your business, it can engage audience.</h1>
                    </div>
                </div>
            </div>
            <div className="third-value-proposition" data-scroll data-scroll-direction="horizontal" data-scroll-speed="5.8" data-scroll-target="#brand-values-widget">
                <div className="inner-widget" >
                    <div className="index-block">
                        <div className="index-indicator">
                            <h1 className="index-no">3</h1>
                        </div>
                    </div>
                    <div className="value-content-block">
                        <h1 className="value-concept">Illusion through Imagination</h1>
                        <h1 className="value-statement">I good design makes your brain function quickly therefore time seems to feel more slow.</h1>
                        <h1 className="value-direction">5 seconds gaze on a good design illude mind to feel like spent 5 hours in real world.</h1>
                    </div>
                </div>
            </div>

            <div className="bottom-text-container">
                <h1 className="brand-identity-tag">illusion through imagination</h1>
            </div>
        </div>
    )

}
export default BrandValuesWidget;
