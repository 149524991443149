import React, {useContext} from "react";
import "./OurServices.scss";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";

const OurServices = () =>{

    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const MagicIconURI = GSHOMEDATA.magicIcon;
    const EyeIcon = GSHOMEDATA.eyeIcon;

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode, setFilterImplementation } = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
        setFilterImplementation("grayScale(0%)")
        // setImgSource("https://firebasestorage.googleapis.com/v0/b/shadowfightarena-99fe0.appspot.com/o/ezgif.com-gif-maker.gif?alt=media&token=d503b7ac-2f06-4d37-a24b-711e4b596a5a")
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
        // setImgSource("")
    }

    return(
        <div className="our-services"
             onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",MagicIconURI)}
             onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
        >
            <div className="our-services-header">

            </div>
            <div className="our-services-content">
                <div className="content-topic-section">
                    <div className="spacer"/>
                    <h1 className="topic">SERVICES</h1>
                    <h1 className="topic">OUR</h1>
                </div>
                <div className="content-grid-section">
                    <div className="content-grid-row">
                        <div className="content-block">
                            <h1 className="content-header">WEB DESIGN</h1>
                            <h1 className="content-line">Web Design</h1>
                            <h1 className="content-line">APP Design</h1>
                            <h1 className="content-line">Modern UI-UX</h1>
                            <h1 className="content-line">Concept Design</h1>
                            <h1 className="content-line">Graphic Design</h1>
                            <h1 className="content-line">Branding</h1>
                        </div>
                        <div className="content-block">
                            <h1 className="content-header">WEBSITE DEVELOPMENT</h1>
                            <h1 className="content-line">Website Frontend Development</h1>
                            <h1 className="content-line">Website Backend Development</h1>
                            <h1 className="content-line">Technology/Platform Migration</h1>
                            <h1 className="content-line">Progressive Web Apps</h1>
                            <h1 className="content-line">Realtime Apps using Socket IO</h1>
                        </div>
                        <div className="content-block">
                            <h1 className="content-header">PRODUCT ENHANCEMENT</h1>
                            <h1 className="content-line">2D Illustration/Product Design</h1>
                            <h1 className="content-line">3D Product Visualization/ Stills & Animation</h1>
                            <h1 className="content-line">3D Animated Commercials & Cinematic</h1>
                            <h1 className="content-line">VFX For Commercials & Cinematic</h1>
                        </div>
                    </div>
                    <div className="content-grid-row">
                        <div className="content-block-two">
                            <h1 className="content-header">APP DEVELOPMENT</h1>
                            <h1 className="content-line">Native Apps (iOS & Android)</h1>
                            <h1 className="content-line">Hybrid Apps (iOS & Android)</h1>
                            <h1 className="content-line">Backend Integration for Apps</h1>
                        </div>
                        <div className="content-block-two">
                            <h1 className="content-header">3D Animation</h1>
                            <h1 className="content-line">3D Animation for Product Visualization</h1>
                            <h1 className="content-line">3D Animation for Commercials</h1>
                            <h1 className="content-line">VFX for Commercials</h1>
                            <h1 className="content-line">3D Animation for Cinematic & Game DDevelopment</h1>
                        </div>
                        <div className="content-block-two">
                            <h1 className="content-header">Cloud Services</h1>
                            <h1 className="content-line">AWS Cloud Services</h1>
                            <h1 className="content-line">Google Firebase Cloud Services</h1>
                            <h1 className="content-line">Cloud Integration with Frontend</h1>
                            <h1 className="content-line">Migrating from traditional backend to Cloud</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-services-footer">
                <h1 className="services-footer-message">
                    Discover all that power of technology under one roof - only to enhance your products digital experience. Right from 2D Illustration, graphic design or Animated 3D Product Visualization, VFX or be it with latest web/App development, technologies & frameworks such as React JS, React Native, Flutter, Android Studio to Create fast native like experience on Web / App platforms we bring you that fast and bespoke digital experience with our expertise in latest technologies available on this planet at this moment.
                </h1>
            </div>
        </div>
    )
}

export default OurServices;
