import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import GoldenSparrowStudioCursorManager
    from "../../Widgets/Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import GoldenSparrowStudio_CustomCursor from "../../Widgets/Cursors/GoldenSparrowStudio_CustomCursor";
import HomePage from "../HomePage/HomePage";



const GoldenSparrowStudioHomePageCursorManager = () =>{
    return(
        <GoldenSparrowStudioCursorManager>
            <GoldenSparrowStudio_CustomCursor/>
            <HomePage/>
        </GoldenSparrowStudioCursorManager>
    )
}

export default GoldenSparrowStudioHomePageCursorManager;
