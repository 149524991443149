import React, {useContext, useState} from "react";
import {send} from "emailjs-com";
import "./LetsConnect.scss";
import FollowUs from "../FollowUSWidget/FollowUs";
import {GoldenSparrowStudioCursorContext} from "../Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";



const LetsConnect  = () =>{


    const {setSize, setText, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);
    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleFormSubmit = (event) =>{
        event.preventDefault();
        send(
            'service_cm2kxue',
            'template_nmho3bs',
            toSend,
            'v-IyXbHutSyJFBsWZ'
        ).then((response) => {
        })
        .catch((err) => {
        });
    }
    const [howDidYouFindUs, setHowDidYouFindUs] = useState("");
    const [projectBudget, setProjectBudget] = useState("");
    const [timeframe, setTimeframe] = useState("");
    const [projectCategory, setProjectCategory] = useState("");
    const [aboutProject, setAboutProject] = useState("");
    const [toSend, setToSend] = useState({
        client_email: '',
        to_name: 'Golden Sparrow Studio',
        client_name: '',
        company_name: '',
        project_name: '',
        found_about_you: '',
        project_category: '',
        about_the_project: '',
        project_budget: '',
        time_frame: '',
    });

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    const handleChangeHowDidYouFindUs = (e) =>{
        setToSend({ ...toSend, [e.target.name]: e.target.value });
        setHowDidYouFindUs(e.target.value)
    }
    const handleChangeProjectBudget = (e) =>{
        setToSend({ ...toSend, [e.target.name]: e.target.value });
        setProjectBudget(e.target.value);
    }
    const handleChangeTimeframe = (e) =>{
        setToSend({ ...toSend, [e.target.name]: e.target.value });
        setTimeframe(e.target.value);
    }
    const handleChangeProjectCategory = (e) =>{
        setToSend({ ...toSend, [e.target.name]: e.target.value });
        setProjectCategory(e.target.value)
    }
    const handleChangeAboutProject = (e) =>{
        setToSend({ ...toSend, [e.target.name]: e.target.value });
        setAboutProject(e.target.value);
    }

    const [proposalHighlight1, setProposalHighlight1] = useState("");
    const [proposalHighlight2, setProposalHighlight2] = useState("");
    const [proposalHighlight3, setProposalHighlight3] = useState("");
    const [proposalHighlight4, setProposalHighlight4] = useState("");
    const [proposalHighlight5, setProposalHighlight5] = useState("");
    const [ballparkFigure, setBallParkFigure] = useState("");

    const handleSelection_ProjectType = (event) =>{
        setToSend({ ...toSend, [event.target.name]: event.target.value });
        setProjectCategory(event.target.value);
        if(event.target.value === "web-design"){
            setProposalHighlight1("Uncompromised high-quality content");
            setProposalHighlight2("Finished projects with all files & artifacts");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$5-10K")
        }else if(event.target.value === "graphic-design"){
            setProposalHighlight1("from 2-10 graphic design elements depending upon details & complexity");
            setProposalHighlight2("Finished projects with all files & artifacts");
            setProposalHighlight3("For large scale projects we can work together to finalize better deal");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$2-10K");
        }else if(event.target.value === "ui-ux-prototype"){
            setProposalHighlight1("all platform & devices compatible UI-UX Prototype");
            setProposalHighlight2("High quality modern UI-UX that will enhance digital experience");
            setProposalHighlight3("Animated Prototype in Figma or any other tool of your choice");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$8-15K");
        }else if(event.target.value === "classic-web-frontend-backend"){
            setProposalHighlight1("Regular classic website design,development & deployment");
            setProposalHighlight2("Frontend developed in modern Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Backend development in mysql/PostgreSQL/Mongodb with secured authentication");
            setProposalHighlight4("optional Analytics support - with additional charges");
            setProposalHighlight5("Complete project artifacts + 3 months of free website + backend maintenance");
            setBallParkFigure("$15-20K")
        }else if(event.target.value === "classic-web-frontend"){
            setProposalHighlight1("Regular classic website design,development & deployment");
            setProposalHighlight2("Frontend developed in modern Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Complete project artifacts + 3 months of free website + backend maintenance");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$5-10K")
        }else if(event.target.value === "premium-modern-website-frontend"){
            setProposalHighlight1("Premium UI-UX that creates bespoke brand identity");
            setProposalHighlight2("modern frontend designed & developed in latest Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Modern sleek UI Animations & user interactions that engage audience & boost sales");
            setProposalHighlight4("Personalized attention given to UI-UX to make sure your digital product stands with a crown in crowd");
            setProposalHighlight5("6 months of free website maintenance + complete project artifacts");
            setBallParkFigure("$25-40K")
        }else if(event.target.value === "premium-modern-website-frontend-backend"){
            setProposalHighlight1("Premium UI-UX that creates bespoke brand identity");
            setProposalHighlight2("modern frontend designed & developed in latest Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Scalable backends using Cloud services such as AWS,Firebase or traditional database");
            setProposalHighlight4("Personalized attention given to UI-UX to make sure your digital product stands with a crown in crowd");
            setProposalHighlight5("Analytics integration + 6 months of free website & backend maintenance + complete project artifacts");
            setBallParkFigure("$35-50K")
        }else if(event.target.value === "premium-modern-website-frontend-3d"){
            setProposalHighlight1("Premium 3d Product Visualization Animation/stills that can steal the show");
            setProposalHighlight2("modern frontend designed & developed in latest Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Premium UI-UX that creates bespoke brand identity");
            setProposalHighlight4("Covers up to 3 products,six 3D scenes & up to 60 seconds of 3d Product Animation");
            setProposalHighlight5("*** want to consider more products for 3D animation? We can provide best deal for bigger scale projects***");
            setBallParkFigure("$40-60K");
        }else if(event.target.value === "3d-product-animation"){
            setProposalHighlight1("State of the art 3d Product Visualization Animation/stills that enhances product value");
            setProposalHighlight2("Personal attention to each brand to understand its needs");
            setProposalHighlight3("Covers right from storyboarding,concept art,Animation,VFX to final rendering & video editing");
            setProposalHighlight4("Covers up to 1 product, nine 3D scenes & up to 60 seconds of 3d Product Animation");
            setProposalHighlight5("*** want to consider more products for 3D animation? Lets have a word for bigger scale projects***");
            setBallParkFigure("$25-50K");
        }else if(event.target.value === "3d-commercial-animation"){
            setProposalHighlight1("Want to work with us for creating your next 3D commercials? Lets have a word to know your requirement");
            setProposalHighlight2("");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$70K+");
        }else if(event.target.value === "vfx"){
            setProposalHighlight1("Want to work with us for creating your next VFX commercials? Lets have a word to know your requirement");
            setProposalHighlight2("");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$50K+");
        }else if(event.target.value === "premium-ecommerce-frontend"){
            setProposalHighlight1("Premium UI-UX + smart & ultra-fast ecommerce user experience");
            setProposalHighlight2("modern frontend designed & developed in latest Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Premium user experience right from product display to checkout/cart and delivery screens.");
            setProposalHighlight4("Personalized attention given to UI-UX to make sure your digital product stands with a crown in crowd");
            setProposalHighlight5("6 months of free website maintenance + complete project artifacts");
            setBallParkFigure("$50-70K")
        }
        else if(event.target.value === "premium-ecommerce-frontend-3d-product-animation"){
            setProposalHighlight1("Premium UI-UX + smart & ultra-fast ecommerce user experience");
            setProposalHighlight2("modern frontend designed & developed in latest Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Premium user experience right from product display to checkout/cart and delivery screens.");
            setProposalHighlight4("Covers up to 5 products,nine 3D scenes & up to 60 seconds of 3d Product Animation");
            setProposalHighlight5("*** want to consider more products for 3D animation? Lets have a word for bigger scale projects***");
            setBallParkFigure("$80-110K")
        }else if(event.target.value === "premium-ecommerce-frontend-backend"){
            setProposalHighlight1("Premium UI-UX + smart & ultra-fast ecommerce user experience");
            setProposalHighlight2("modern frontend designed & developed in latest Javascript frameworks(React-JS,Angular or Next-JS");
            setProposalHighlight3("Scalable backend development right from product display to checkout/cart and delivery screens.");
            setProposalHighlight4("Personalized attention given to UI-UX to make sure your digital product stands with a crown in crowd");
            setProposalHighlight5("6 months of free website maintenance + complete project artifacts");
            setBallParkFigure("$50-90K")
        }else if(event.target.value === "app-frontend"){
            setProposalHighlight1("Premium UI-UX for your APP that creates bespoke brand identity");
            setProposalHighlight2("modern frontend designed in latest Native / Hybrid frameworks(React native,Flutter, iOS, Android Studio");
            setProposalHighlight3("Modern sleek UI Animations & user interactions that engage audience & boost sales");
            setProposalHighlight4("Personalized attention given to UI-UX to make sure your App stands with a crown in crowd");
            setProposalHighlight5("3 months of free App maintenance + complete project artifacts");
            setBallParkFigure("$15-40K")
        }else if(event.target.value === "app-frontend-backend"){
            setProposalHighlight1("Premium UI-UX for your APP that creates bespoke brand identity");
            setProposalHighlight2("modern frontend designed in latest Native / Hybrid frameworks(React native,Flutter, iOS, Android Studio");
            setProposalHighlight3("Scalable backend developed in Cloud/traditional databases/services");
            setProposalHighlight4("Personalized attention given to UI-UX to make sure your App stands with a crown in crowd");
            setProposalHighlight5("3 months of free App maintenance + complete project artifacts");
            setBallParkFigure("$20-60K")
        }else if(event.target.value === "cloud-services"){
            setProposalHighlight1("Want to work with us for Developing cloud services? Lets have a word.");
            setProposalHighlight2("");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$45K+");
        }else if(event.target.value === "cloud-services") {
            setProposalHighlight1("Want to work with us for Maintenance of existing projects? Lets have a word.");
            setProposalHighlight2("");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$10-40K+");
        }
        else if(event.target.value === "custom-requirement"){
            setProposalHighlight1("Got a custom requirement?Lets have a word.");
            setProposalHighlight2("Min budget $(50K+)");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("$50K+");
        }else{
            setProposalHighlight1("");
            setProposalHighlight2("");
            setProposalHighlight3("");
            setProposalHighlight4("");
            setProposalHighlight5("");
            setBallParkFigure("");
        }
    }

    return(
        <div className="lets-connect"
             onMouseEnter={()=> handleCursorMouseEnter("small","","difference","")}
             onMouseLeave={()=> handleCursorMouseLeave("small","","difference","")}
        >
            <div className="lets-start-project-section">
                <form className="project-details-form-section" onSubmit={handleFormSubmit}>
                    <h1 className="form-title">Let's build your next project together</h1>
                    <div className="form-info-section">
                        <div className="form-row">
                            <div className="input-box">
                                <label htmlFor="clientname" className="item-name">Your name*</label>
                                <input id={"clientname"} name={"client_name"} placeholder={"Your name..."}  onChange={handleChange}  type="text" className="form-input"/>
                            </div>
                            <div className="input-box">
                                <label htmlFor="company" className="item-name">Your company*</label>
                                <input id={"company"} name={"company_name"} placeholder={"Your company..."} onChange={handleChange}  type="text" className="form-input"/>
                            </div>
                            <div className="input-box">
                                <label htmlFor="client_email" className="item-name">Your E-Mail*</label>
                                <input id={"client_email"} name={"client_email"} placeholder={"Your email..."}  onChange={handleChange}  type="email" className="form-input"/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-box">
                                <label htmlFor="how-did-you-find-us-select" className="item-name">How did you find out about us?*</label>
                                <select  name="found_about_you" id="how-did-you-find-us-select" value={howDidYouFindUs} onChange={handleChangeHowDidYouFindUs}>
                                    <option value="">How did you find out about us?</option>
                                    <option value="google">Google</option>
                                    <option value="social-media">Social Media(Behance, Twitter, Facebook)</option>
                                    <option value="article">I read an article about you</option>
                                    <option value="recommendation">I got recommendation</option>
                                    <option value="something-else">Something else..</option>
                                </select>
                            </div>
                            <div className="input-box">
                                <label htmlFor="budget-for-project-select" className="item-name">Budget for the project?*</label>
                                <select name="project_budget" id="budget-for-project-select" value={projectBudget} onChange={handleChangeProjectBudget}>
                                    <option value="">Budget for the project?*</option>
                                    <option value="$5-10K small project">$5-10K USD(small Projects only)</option>
                                    <option value="$10-20K">$10-20K USD</option>
                                    <option value="$20-30K">$20-30K USD</option>
                                    <option value="$35-50K">$35-50K USD(premium content)</option>
                                    <option value="$50K+ UltraPremium">$50K+(Ultra Premium Content)</option>
                                </select>
                            </div>
                            <div className="input-box">
                                <label htmlFor="time-frame-select" className="item-name">Time frame of the project?*</label>
                                <select name="time_frame" id="time-frame-select" value={timeframe} onChange={handleChangeTimeframe}>
                                    <option value="">Time frame of the project?</option>
                                    <option value="15 days">I need it within 15 days(small Projects only)</option>
                                    <option value="one month">I need it within a month</option>
                                    <option value="2 months">I need it within 2 months</option>
                                    <option value="3 months">I need it within 3 months</option>
                                    <option value="4+ months">4+ months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="ballpark-estimate-section">
                        <div className="left-controls-section">
                            <div className="input-box">
                                <label htmlFor="project-category" className="item-name">What kind of Project you want to build?*</label>
                                <select name="project_category" id="project-category"
                                        onChange={handleSelection_ProjectType} value={projectCategory}
                                >
                                    <option value="">What kind of Project you want to build?</option>
                                    <option value="web-design">Web/Concept Design</option>
                                    <option value="graphic-design">Graphic Design</option>
                                    <option value="ui-ux-prototype">Modern UI-UX Prototype</option>
                                    <option value="classic-web-frontend">Classic(regular) Website (Only Frontend)</option>
                                    <option value="classic-web-frontend-backend">Classic(regular) Website (Frontend + Backend)</option>
                                    <option value="premium-modern-website-frontend">Premium Modern Website (Only Frontend)</option>
                                    <option value="premium-modern-website-frontend-backend">Premium Modern Website (Frontend + Backend)</option>
                                    <option value="premium-modern-website-frontend-3d">Premium Modern Website (only Frontend) + 3D Product Stills/Animation</option>
                                    <option value="3d-product-animation">3D Product Animation (enhance product experience)</option>
                                    <option value="3d-commercial-animation">3D Animation for Commercials/Cinematic</option>
                                    <option value="vfx">VFX for Commercials</option>
                                    <option value="premium-ecommerce-frontend">Premium E-commerce Website/App(only Frontend)</option>
                                    <option value="premium-ecommerce-frontend-3d-product-animation">Premium E-commerce Website + 3D Product Animation</option>
                                    <option value="premium-ecommerce-frontend-backend">Premium E-commerce Website/App(Frontend + Backend)</option>
                                    <option value="cloud-services">Cloud Services Integration/Development</option>
                                    <option value="maintenance">Maintenance/Change Request(for existing clients/projects)</option>
                                    <option value="app-frontend">App(iOS/Android)- Only Frontend</option>
                                    <option value="app-frontend-backend">App(iOS/Android)- Frontend + Backend</option>
                                    <option value="custom-requirement">Custom Requirement (for $50K above budget)</option>
                                </select>
                            </div>
                        </div>
                        <div className="right-estimate-section">
                            <div className="ballpark-figure-grid">
                                <h1 className="box-title">Ballpark Figure</h1>
                                <h1 className="box-range">{ballparkFigure}</h1>
                            </div>
                            <div className="what-you-get-grid">
                                <h1 className="estimate-subtitle">What you Get</h1>
                                <h1 className="what-you-get">{proposalHighlight1}</h1>
                                <h1 className="what-you-get">{proposalHighlight2}</h1>
                                <h1 className="what-you-get">{proposalHighlight3}</h1>
                                <h1 className="what-you-get">{proposalHighlight4}</h1>
                                <h1 className="what-you-get">{proposalHighlight5}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="tell-us-about-project-section">
                        <label htmlFor="about-project" className="item-name">Tell us about the project*</label>
                        <textarea id="about-project" name="about_the_project" className="form-input-area" placeholder={"Tell us more about your project..."}
                                  onChange={handleChangeAboutProject}
                                  value={aboutProject}>
                        </textarea>
                    </div>
                    <button type={"submit"} className="submit">Submit</button>
                </form>
            </div>
            <div className="follow-us-section">
                <FollowUs/>
            </div>
        </div>
    )
}

export default LetsConnect;
