import React from "react";
import "./ClientsList.scss";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";


const ClientsList = () =>{
    return(
        <div className="clients-list">
            <div className="clients-column" >
                <h1 className="clients-section-title">SOME OF OUR CLIENTS</h1>
                <h1 className="clients-section-title">.</h1>
            </div>
            <img src={GSStudio_HomePageData[0].brands} alt="" className="clients-list-img"/>
        </div>
    )
}

export default ClientsList;
