import React from "react";
import FlipBook_HardPage from "./FlipBook_HardPage/FlipBook_HardPage";
import FlipBook_SoftPage from "./FlipBook_SoftPage/FLipBook_SoftPage";
import HTMLFlipBook from "react-pageflip";
import "./FlipBookComponent.scss";
import {GSStudio_HomePageData} from "../../../Pages/HomePage/HomePage_Data";


class FlipBookComponent extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            totalPage: 0,
        };
    }

    vw(coef) {
        return window.innerWidth * (coef/100);
    }
    vh(coef) {
        return window.innerWidth * (coef/100);
    }

    onPage = (e) => {
        this.setState({
            page: e.data,
        });
    };

    // componentDidMount() {
    //     this.setState({
    //         // totalPage: this.flipBook.getPageFlip().getPageCount(),
    //
    //     });
    // }

    render() {
        return (
            <div className={"flipBookBG"}>
                <HTMLFlipBook
                    width={1000}
                    height={800}
                    size="stretch"
                    minWidth={700}
                    // maxWidth={1200}
                    minHeight={700}
                    // maxHeight={1000}
                    maxShadowOpacity={0.5}
                    showCover={true}
                    mobileScrollSupport={false}
                    onFlip={this.onPage}
                    onChangeOrientation={this.onChangeOrientation}
                    onChangeState={this.onChangeState}
                    // style={{backgroundColor: 'green'}}
                    className="flip-book"
                    ref={(el) => (this.flipBook = el)}>

                    <FlipBook_HardPage>
                        <div className="FrontBookHardCover">
                            <h1 className="bookTitle">Lets Explore whats </h1>
                            <h1 className="bookTitle">possible in third Dimension</h1>
                            <div className="overlayScreen"></div>
                            <img src={GSStudio_HomePageData[0].toonShader} alt="" className="MainPageImage"/>
                        </div>
                    </FlipBook_HardPage>
                    <FlipBook_SoftPage number={1}>
                        <div className="textContainerLeft">
                            <div className="mainTextContainer">
                                <h1 className="ProductName">WIREFRAME</h1>
                                <h1 className="ProductName">RENDER</h1>
                            </div>
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">MADE WITH</h1>
                                <h1 className="prodDesc">BLENDER</h1>
                                <h1 className="prodDesc">REALFLOW</h1>
                                <h1 className="prodDesc">HUDINI</h1>
                            </div>
                        </div>
                        <div className="overlayScreen"></div>
                        <img src={GSStudio_HomePageData[0].barrelAgedWireframe} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={2}>
                        <div className="textContainerRight">
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">PREMIUM</h1>
                                <h1 className="prodDesc">CAFFEINE</h1>
                                <h1 className="prodDesc">ENERGY</h1>
                                <h1 className="prodDesc">DRINK</h1>
                            </div>
                            <div className="mainTextContainer">
                                <h1 className="ProductName">BARREL</h1>
                                <h1 className="ProductName">AGED</h1>
                            </div>
                        </div>
                        <div className="dotPanel"></div>
                        <img src={GSStudio_HomePageData[0].blueBerrySplash} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={3}>
                        <div className="textContainerLeft">
                            <div className="mainTextContainer">
                                <h1 className="ProductName">WIREFRAME</h1>
                                <h1 className="ProductName">RENDER</h1>
                            </div>
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">MADE WITH</h1>
                                <h1 className="prodDesc">BLENDER</h1>
                                <h1 className="prodDesc">REALFLOW</h1>
                                <h1 className="prodDesc">HUDINI</h1>
                            </div>
                        </div>
                        <img src={GSStudio_HomePageData[0].appleSplashWireFrame} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={4}>
                        <div className="textContainerRight">
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">PREMIUM</h1>
                                <h1 className="prodDesc">CAFFEINE</h1>
                                <h1 className="prodDesc">ENERGY</h1>
                                <h1 className="prodDesc">DRINK</h1>
                            </div>
                            <div className="mainTextContainer">
                                <h1 className="ProductName">BARREL</h1>
                                <h1 className="ProductName">AGED</h1>
                            </div>
                        </div>
                        <img src={GSStudio_HomePageData[0].appleSplash} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={3}>
                        <div className="textContainerLeft">
                            <div className="mainTextContainer">
                                <h1 className="ProductName">WIREFRAME</h1>
                                <h1 className="ProductName">RENDER</h1>
                            </div>
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">MADE WITH</h1>
                                <h1 className="prodDesc">BLENDER</h1>
                                <h1 className="prodDesc">REALFLOW</h1>
                                <h1 className="prodDesc">HUDINI</h1>
                            </div>
                        </div>
                        <img src={GSStudio_HomePageData[0].aandsWatchWireframe} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={4}>
                        <div className="textContainerRight">
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">PREMIUM</h1>
                                <h1 className="prodDesc">CAFFEINE</h1>
                                <h1 className="prodDesc">ENERGY</h1>
                                <h1 className="prodDesc">DRINK</h1>
                            </div>
                            <div className="mainTextContainer">
                                <h1 className="ProductName">BARREL</h1>
                                <h1 className="ProductName">AGED</h1>
                            </div>
                        </div>
                        <img src={GSStudio_HomePageData[0].aandsWatch} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={3}>
                        <div className="textContainerLeft">
                            <div className="mainTextContainer">
                                <h1 className="ProductName">WIREFRAME</h1>
                                <h1 className="ProductName">RENDER</h1>
                            </div>
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">MADE WITH</h1>
                                <h1 className="prodDesc">BLENDER</h1>
                                <h1 className="prodDesc">REALFLOW</h1>
                                <h1 className="prodDesc">HUDINI</h1>
                            </div>
                        </div>
                        <img src={GSStudio_HomePageData[0].aandsExploded_WireFrame} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_SoftPage number={4}>
                        <div className="textContainerRight">
                            <div className="sideTextContainer">
                                <h1 className="prodDesc">PREMIUM</h1>
                                <h1 className="prodDesc">CAFFEINE</h1>
                                <h1 className="prodDesc">ENERGY</h1>
                                <h1 className="prodDesc">DRINK</h1>
                            </div>
                            <div className="mainTextContainer">
                                <h1 className="ProductName">BARREL</h1>
                                <h1 className="ProductName">AGED</h1>
                            </div>
                        </div>
                        <img src={GSStudio_HomePageData[0].aandsExploded} alt="" className="Page2Image"/>
                    </FlipBook_SoftPage>
                    <FlipBook_HardPage>THE END</FlipBook_HardPage>
                </HTMLFlipBook>

                <div className="container">
                    <div>

                        {/*<button type="button" onClick={this.prevButtonClick}>*/}
                        {/*    Previous page*/}
                        {/*</button>*/}

                        {/*/!*[<span>{this.state.page}</span> of*!/*/}
                        {/*/!*<span>{this.state.totalPage}</span>]*!/*/}

                        {/*<button type="button" onClick={this.nextButtonClick}>*/}
                        {/*    Next page*/}
                        {/*</button>*/}

                    </div>
                    <div>

                        {/*State: <i>{this.state.state}</i>, orientation: <i>{this.state.orientation}</i>*/}

                    </div>
                </div>
            </div>
        );
    }
}

export default FlipBookComponent;
