import React from "react";


const GoldenSparrowContactUsPageShownContext = React.createContext({
    isGoldenSparrowContactUsPageOpen: false,
    showGoldenSparrowContactUsPage: () => {},
    closeGoldenSparrowContactUsPage: () => {}
})

export default GoldenSparrowContactUsPageShownContext;
