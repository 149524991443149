
import React, {useState, Suspense} from "react";
import {BrowserRouter,Routes, Route} from 'react-router-dom';

import GoldenSparrowMainMenuShownContext from "./Context/GoldenSparrowMainMenuShownContext";
import GoldenSparrowMainMenu from "./Widgets/GoldenSparrowMainMenu/GoldenSparrowMainMenu";

import SplashToHomeScreenPage from "./Pages/SplashToHomeScreenPage/SplashToHomeScreenPage";
import GoldenSparrowAboutPageShownContext from "./Context/GoldenSparrowAboutPageShownContext";
import GoldenSparrowAboutPage
    from "./Widgets/ContextWidgets/GoldenSparrowAboutPageModal/GoldenSparrowAboutPage/GoldenSparrowAboutPage";
import HomePage from "./Pages/HomePage/HomePage";
import SplashPreEnterScreen from "./Pages/SplashScreen/PreScreenEnter/SplashPreEnterScreen";
import CircularLoadingScreen from "./Pages/SplashScreen/CircularLoadingScreen/CircularLoadingScreen";
import BarrelAgedProjectPage from "./Pages/BarrelAgedProjectPage/BarrelAgedProjectPage";
import ArnoldAndSonWatchProject from "./Widgets/ArnoldAndSonWatchProject/ArnoldAndSonWatchProject";
import GoldenSparrowShowreelModal from "./Widgets/ContextWidgets/GoldenSparrowShowReelModal/GoldenSparrowShowreelModal";
import GoldenSparrowAnimationReelShownContext from "./Context/GoldenSparrowAnimationReelShownContext";
import GoldenSparrowShowReelPage
    from "./Widgets/ContextWidgets/GoldenSparrowShowReelModal/GoldenSparrowShowReelPage/GoldenSparrowShowReelPage";
import ShowReelProvider from "./Widgets/ContextProvider/ShowreelProvider/ShowreelProvider";

import GoldenSparrowContactUsPageShownContext from "./Context/GoldenSparrowContactUsPageShownContext";
import GoldenSparrowContactUsPage
    from "./Widgets/ContextWidgets/GoldenSparrowContactUsModal/GoldenSparrowContactUsPage/GoldenSparrowContactUsPage";

import GlobalCircularLoadingScreen from "./Pages/SplashScreen/GlobalCircularLoadingScreen/GlobalCircularLoadingScreen";


// import AlaFut_HomePage from "./Pages/AlaFut_HomePage/AlaFut_HomePage";
// import SplashScreenToHomeScreenPage_For_ArnoldAndSon from "./Pages/SplashScreenToHomeScreenPage_For_ArnoldAndSon/SplashScreenToHomeScreenPage_For_ArnoldAndSon";
// import ArnoldAndSonAboutPage from "./Pages/ArnoldAndSon_AboutPage/ArnoldAndSonAboutPage";
// import SplashToBarrelAgedHomeScreen from "./Pages/SplashToHomeScreenPage_For_BarrelAged/SplashToBarrelAgedHomeScreen";

const AlaFut_HomePage = React.lazy(() =>  import('./Pages/AlaFut_HomePage/AlaFut_HomePage'));
const SplashScreenToHomeScreenPage_For_ArnoldAndSon = React.lazy(() => import('./Pages/SplashScreenToHomeScreenPage_For_ArnoldAndSon/SplashScreenToHomeScreenPage_For_ArnoldAndSon'));
const ArnoldAndSonAboutPage = React.lazy(() => import('./Pages/ArnoldAndSon_AboutPage/ArnoldAndSonAboutPage'));
const SplashToBarrelAgedHomeScreen = React.lazy(() => import('./Pages/SplashToHomeScreenPage_For_BarrelAged/SplashToBarrelAgedHomeScreen'));



function App() {

    const [isGoldenSparrowMainMenuOpen, setIsGoldenSparrowMainMenuOpen] = useState(false);
    const [isGoldenSparrowAboutPageOpen, setIsGoldenSparrowAboutPageOpen] = useState(false);
    const [isGoldenSparrowContactUsPageOpen, setIsGoldenSparrowContactUsPageOpen] = useState(false);
    const [isGoldenSparrowAnimationReelOpen, setIsGoldenSparrowAnimationReelOpen] = useState(false);

    const showGoldenSparrowShowreelModalHandler = () =>{
        setIsGoldenSparrowAnimationReelOpen(true);
    }
    const hideGoldenSparrowShowreelModalHandler = () =>{
        setIsGoldenSparrowAnimationReelOpen(false);
    }
    const showGoldenSparrowAboutPageHandler = () =>{
        setIsGoldenSparrowAboutPageOpen(true);
    }
    const hideGoldenSparrowAboutPageHandler = () =>{
        setIsGoldenSparrowAboutPageOpen(false);
    }
    const showGoldenSparrowContactUsPageHandler = () =>{
        setIsGoldenSparrowContactUsPageOpen(true);
    }
    const hideGoldenSparrowContactUsPageHandler = () =>{
        setIsGoldenSparrowContactUsPageOpen(false);
    }
    const showGoldenSparrowMainMainMenuHandler = () => {
        setIsGoldenSparrowMainMenuOpen(true);
    }
    const hideGoldenSparrowMainMainMenuHandler = () =>{
        setIsGoldenSparrowMainMenuOpen(false);
    }

    return (
        <ShowReelProvider>
            <GoldenSparrowMainMenuShownContext.Provider value={{
                isGoldenSparrowMainMenuOpen: isGoldenSparrowMainMenuOpen,
                showGoldenSparrowMainMenu: showGoldenSparrowMainMainMenuHandler,
                closeGoldenSparrowMainMenu: hideGoldenSparrowMainMainMenuHandler
            }}>
                <GoldenSparrowAboutPageShownContext.Provider value={{
                    isGoldenSparrowAboutPageOpen: isGoldenSparrowAboutPageOpen,
                    showGoldenSparrowAboutPage: showGoldenSparrowAboutPageHandler,
                    closeGoldenSparrowAboutPage: hideGoldenSparrowAboutPageHandler
                }}>
                    <GoldenSparrowContactUsPageShownContext.Provider value={{
                        isGoldenSparrowContactUsPageOpen: isGoldenSparrowContactUsPageOpen,
                        showGoldenSparrowContactUsPage: showGoldenSparrowContactUsPageHandler,
                        closeGoldenSparrowContactUsPage: hideGoldenSparrowContactUsPageHandler
                    }}>
                        <GoldenSparrowAnimationReelShownContext.Provider value={{
                            isGoldenSparrowAnimationReelOpen: isGoldenSparrowAnimationReelOpen,
                            showGoldenSparrowAnimationReel: showGoldenSparrowShowreelModalHandler,
                            closeGoldenSparrowAnimationReel: hideGoldenSparrowShowreelModalHandler
                        }}>
                            {
                                isGoldenSparrowAboutPageOpen && <GoldenSparrowAboutPage/>
                            }
                            {
                                isGoldenSparrowMainMenuOpen && <GoldenSparrowMainMenu/>
                            }
                            {
                                isGoldenSparrowAnimationReelOpen && <GoldenSparrowShowReelPage/>
                            }
                            {
                                isGoldenSparrowContactUsPageOpen && <GoldenSparrowContactUsPage/>
                            }
                            <BrowserRouter>
                                <Suspense fallback={<GlobalCircularLoadingScreen/>}>
                                    <Routes>
                                        {/*<Route  path={"/"} element={<SplashPreEnterScreen/>}/>*/}
                                        {/*<Route  path={"/"} element={<HomePage/>}/>*/}
                                        <Route  path={"/"} element={<SplashToHomeScreenPage/>}/>
                                        {/*<Route  path={"/barrel-aged"} element={<BarrelAgedProjectPage/>}/>*/}
                                        <Route  path={"/barrel-aged"} element={<SplashToBarrelAgedHomeScreen/>}/>
                                        <Route path={"/alafut"} element={<AlaFut_HomePage/>}/>
                                        <Route  path={"/arnold-and-son"} element={<SplashScreenToHomeScreenPage_For_ArnoldAndSon/>}/>
                                        <Route  path={"/arnold-and-son-about-us"} element={<ArnoldAndSonAboutPage/>}/>
                                    </Routes>
                                </Suspense>
                            </BrowserRouter>
                        </GoldenSparrowAnimationReelShownContext.Provider>
                    </GoldenSparrowContactUsPageShownContext.Provider>
                </GoldenSparrowAboutPageShownContext.Provider>
            </GoldenSparrowMainMenuShownContext.Provider>
        </ShowReelProvider>
    );
}

export default App;
