

const WideContentList_Data = {

    "project_details" : [
        {
            "id": 0,
            index: 0,
            projectName: "Time Pyramid",
            projectDesc: "Luxury Watch",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FGearsAndPebbles.png?alt=media&token=c2d09256-0e69-4e68-b6dc-0339fd92fb4e"
        },
        {
            "id": 1,
            index: 1,
            projectName: "Alafut",
            projectDesc: "Flavoured Beverage",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FALAFU_RousseRustre_3D.png?alt=media&token=60e36728-528e-40b7-b6ba-a84444cce0cc"
        },
        {
            "id": 2,
            index: 2,
            projectName: "Time Pyramid",
            projectDesc: "Luxury Watch",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FMermaid.png?alt=media&token=31a01f68-d067-49cd-b1a9-a0db382aa49d"
        },
        {
            "id": 3,
            index: 3,
            projectName: "Smartwatch",
            projectDesc: "Bugatti Smartwatch",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FbugattiImg.png?alt=media&token=d9fa5aa3-bfa3-44c2-9281-f77de9a3825e"
        },
        {
            "id": 4,
            index: 4,
            projectName: "SFA",
            projectDesc: "3D Mobile Game",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FKibo.png?alt=media&token=5c53149f-cc57-497a-a145-ba7cb1bc948e"
        },
        {
            "id": 5,
            index: 5,
            projectName: "Barrel Aged",
            projectDesc: "Flavoured Coffee",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2Fshake.png?alt=media&token=795afb23-99f8-471e-8f26-604ecfa6010a"
        },
        {
            "id": 6,
            index: 6,
            projectName: "Time Pyramid",
            projectDesc: "Luxury Watch",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FBlueBerrySplash.png?alt=media&token=0b9b9050-7573-4159-b7c7-d97cc048d3d6"
        },
        {
            "id": 7,
            index: 7,
            projectName: "Barrel Aged",
            projectDesc: "Flavoured Coffee",
            projectImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FFruity.png?alt=media&token=4f1bff91-030e-4c73-a58b-5c1e50bacb5c"
        }

    ]

}

export default WideContentList_Data;


