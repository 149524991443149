import {createContext} from "react";
import React,{useState} from "react";


export const BarrelAgedCursorContext = createContext({
    size: "small",
    imgSource: "",
    text: "Arnold & Son",
    videoSource: "",
    blendMode: "",
    setSize: ()=>{}
})

export default function BarrelAgedCursorManager(props){
    const [size, setSize] = useState("small");
    const [text, setText] = useState("Barrel Aged");
    const [imgSource, setImgSource] = useState("");
    const [videoSource, setVideoSource] = useState("");
    const [blendMode, setBlendMode] = useState("difference");
    const [filterImplementation, setFilterImplementation] = useState("");

    return(
        <BarrelAgedCursorContext.Provider value={{
            size,setSize,
            text, setText,
            imgSource, setImgSource,
            videoSource, setVideoSource,
            blendMode, setBlendMode,
            filterImplementation, setFilterImplementation
        }}>
            {props.children}
        </BarrelAgedCursorContext.Provider>
    )

}
