import React from "react";
import "./WideContentCard.scss";


const WideContentCard = ({projectData}) =>{

    return(
        <div className="scrolling-tile">
            <h1 className="tile-title">{projectData.projectName}</h1>
            <div className="tile-image-wrap">
                <img src={projectData.projectImage} alt="" className="tile-image"/>
            </div>
            <h1 className="footer">{projectData.projectDesc}</h1>
        </div>
    )
}

export default WideContentCard;
