import {createContext} from "react";
import React,{useState} from "react";


export const ArnoldAndSonCursorContext = createContext({
    size: "small",
    imgSource: "",
    text: "Arnold & Son",
    videoSource: "",
    blendMode: "",
    setSize: ()=>{}
})


export default  function ArnoldAndSonCursorManager(props){

    const [size, setSize] = useState("small");
    const [text, setText] = useState("Arnold & Son");
    const [imgSource, setImgSource] = useState("");
    const [videoSource, setVideoSource] = useState("");
    const [blendMode, setBlendMode] = useState("difference");
    const [filterImplementation, setFilterImplementation] = useState("");

    return(
        <ArnoldAndSonCursorContext.Provider value={{
            size,setSize,
            text, setText,
            imgSource, setImgSource,
            videoSource, setVideoSource,
            blendMode, setBlendMode,
            filterImplementation, setFilterImplementation
        }}>
            {props.children}
        </ArnoldAndSonCursorContext.Provider>
    );

}


