import React, {useContext, useEffect, useRef, useState} from "react";
import "./UniversalNavBar.scss";

//Menu Import-----------------------------------------------------------------------------------------------------------
import GoldenSparrowMainMenuShownContext from "../../Context/GoldenSparrowMainMenuShownContext";
// import GSBrandLogo from "../../assets/images/GSBrandLogo.png";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";


//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import GoldenSparrowPageTransition from "../../Widgets/PageTransition/GoldenSparrowPageTransition/GoldenSparrowPageTransition";
gsap.registerPlugin(ScrollTrigger);
//----------------------------------------------------------------------------------------------------------------------


const UniversalNavBar = () =>{

    const ctx = useContext(GoldenSparrowMainMenuShownContext);
    const [isActive, setActive] = useState(false);
    const [playMenuCurtain, setPlayMenuCurtain] = useState(false);
    const GoldenSparrowPageTransitionTl = gsap.timeline();

    const handleGoldenSparrowMenuOpenStatus = () =>{
        if(ctx.isGoldenSparrowMainMenuOpen){
            ctx.closeGoldenSparrowMainMenu();
            setActive(true);
            setPlayMenuCurtain( prevState => !prevState);
        }else{
            ctx.showGoldenSparrowMainMenu();
            setActive(false);
        }
    }

    useEffect(()=>{
        if(ctx.isGoldenSparrowMainMenuOpen){
            setActive(true);
        }else{
            setActive(false);
        }
    },[ctx.isGoldenSparrowMainMenuOpen])


    return(
        <>
            {
                playMenuCurtain ?  <GoldenSparrowPageTransition timeline={GoldenSparrowPageTransitionTl}/> : <></>
            }
            <div className="universal-navbar">
                <div className={"uni-nav-container"}>
                    <button className= {isActive ? "navbar-toggle active" : "navbar-toggle"} id="toggle" type="button" onClick={handleGoldenSparrowMenuOpenStatus}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={"1.75vw"} height={"1.75vw"} viewBox="0 0 200 200">
                            <g strokeWidth="6.5" strokeLinecap="round">
                                <path
                                    d="M72 82.286h28.75"
                                    fill="#009100"
                                    fillRule="evenodd"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                                    fill="none"
                                    stroke="#fff"
                                />
                                <path
                                    d="M72 125.143h28.75"
                                    fill="#009100"
                                    fillRule="evenodd"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                                    fill="none"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 82.286h28.75"
                                    fill="#009100"
                                    fillRule="evenodd"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 125.143h28.75"
                                    fill="#009100"
                                    fillRule="evenodd"
                                    stroke="#fff"
                                />
                            </g>
                        </svg>
                    </button>
                </div>
                <div className="brand-logo">
                    <img src={GSStudio_HomePageData[0].brandlogo} alt="" className="brand-logo-img"/>
                    <div className="brand-container">
                        <h1 className="brand-name-left">Golden</h1>
                        <h1 className="brand-name-right">Sparrow</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UniversalNavBar;

// <div className={"menu-closed"} onClick={ctx.showGoldenSparrowMainMenu}>
//     <h1>OP</h1>
// </div>
