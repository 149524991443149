import React,{useState,useEffect,useRef} from "react";
import "locomotive-scroll/src/locomotive-scroll.scss";
import "./BarrelAgedProjectPage.scss";

//GSAP IMPORTS------------------------------------------------------------------------------------
import LocomotiveScroll from "locomotive-scroll";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import BarrelAgedBottomProgressBar from "../../components/BarrelAgedBottomProgressBar/BarrelAgedBottomProgressBar";
import BrandHomeScreenWidget from "../../Widgets/BarrelAgedProjectWidgets/BrandHomeScreenWidget/BrandHomeScreenWidget";
import FlavoursAndCoffeeScreen
    from "../../Widgets/BarrelAgedProjectWidgets/FlavoursAndCoffeeScreen/FlavoursAndCoffeeScreen";
import ProductFeaturesSection_CoffeeQuality
    from "../../Widgets/BarrelAgedProjectWidgets/ProductFeaturesSection_CoffeeQuality/ProductFeaturesSection_CoffeeQuality";
import ProductFeatures_AgedBarrel
    from "../../Widgets/BarrelAgedProjectWidgets/ProductFeatures_BrewedBarrel/ProductFeatures_AgedBarrel";
import ProductFeature_EasyToMake
    from "../../Widgets/BarrelAgedProjectWidgets/ProductFeature_EasyToMake/ProductFeature_EasyToMake";
import ProductFeatures_AgingTechniques
    from "../../Widgets/BarrelAgedProjectWidgets/ProductFeatures_AgingTechniques/ProductFeatures_AgingTechniques";
import useOnScreen from "../../hooks/useOnScreen";
import IntroTransitionVideoForBarrelAged
    from "../../components/IntroTransitionVideoForBarrelAged/IntroTransitionVideoForBarrelAged";
import BA_HomePage_VideoData from "./DATA_BA_HomePage/BA_HomePage_VideoData";
gsap.registerPlugin(ScrollTrigger, SplitText);
//------------------------------------------------------------------------------------------------

const BarrelAgedProjectPage = () =>{

    const barrelAgedRefContainer = useRef(null)
    const refMainSplashScreenWidget = useRef(null)
    const onScreenSplash = useOnScreen(refMainSplashScreenWidget)
    const refAllFlavoursVideo = useRef(null);
    const refBurningCanVideo = useRef(null);
    const curtainTriggerRef = useRef(null)
    const curtainTriggerRef_Vertical = useRef(null)
    const curtainTopRef = useRef(null)
    const curtainBottomRef = useRef(null)
    const curtainMediaRef = useRef(null)
    const curtainLeftRef = useRef(null)
    const curtainRightRef = useRef(null)
    const curtainMediaRef_Vertical = useRef(null)
    const onScreen = useOnScreen(curtainMediaRef);
    const onScreen_Vertical = useOnScreen(curtainMediaRef_Vertical);
    // const [isProjectLoaded,setIsProjectLoaded ] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            // if(isProjectLoaded){
                const scroller = barrelAgedRefContainer.current;
                const locoScroll = new LocomotiveScroll({
                    el: scroller,
                    smooth: true,
                    direction: "horizontal",
                    multiplier: 6,
                    lerp: 0.03,
                    smartphone: {
                        smooth: true,
                        direction: "horizontal",
                        multiplier: 6,
                        lerp: 0.03,
                    },
                    tablet: {
                        smooth: true,
                        direction: "horizontal",
                        multiplier: 6,
                        lerp: 0.03,
                    }
                });
                locoScroll.on("scroll", ScrollTrigger.update);
                ScrollTrigger.scrollerProxy(scroller, {
                    scrollLeft(value) {
                        return arguments.length
                            ? locoScroll.scrollTo(value, 0, 0)
                            : locoScroll.scroll.instance.scroll.x;
                    },
                    getBoundingClientRect() {
                        return {
                            left: 0,
                            top: 0,
                            width: window.innerWidth,
                            height: window.innerHeight
                        };
                    },
                    pinType: scroller.style.transform ? "transform" : "fixed",
                });
                ScrollTrigger.defaults({
                    scroller: scroller,
                })

                gsap.to('.progress-bar', {
                    value: 100,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: barrelAgedRefContainer.current,
                        scrub: 0.3,
                        horizontal: true,
                    }
                });
                //-------------------------------------------------------------------------------------------------------------------------------
                let openCurtainTL = gsap.timeline({
                    scrollTrigger: {
                        trigger: curtainTriggerRef.current,
                        scrub: true,
                        pin: true,
                        pinSpacing: true,
                        horizontal: true,
                        scroller: barrelAgedRefContainer.current,
                        start: 'left left',
                        end: () => {return '+=' + window.innerWidth   + 'px'},
                        // end: () => "+=200%",
                        invalidateOnRefresh: true,
                    }
                });
                openCurtainTL.to(curtainTopRef.current, {yPercent: -150, ease: 'Power1.easeIn'}, 'openCurtain')
                    .to(curtainBottomRef.current, {yPercent: 150, ease: 'Power1.easeIn'}, 'openCurtain')
                    .to(curtainMediaRef.current, {scale: 1, ease: 'Power1.easeOut'}, 'openCurtain');
                //-------------------------------------------------------------------------------------------------------------------------------

                //-------------------------------------------------------------------------------------------------------------------------------
                let openCurtainTL_Vertical = gsap.timeline({
                    scrollTrigger: {
                        trigger: curtainTriggerRef_Vertical.current,
                        scrub: true,
                        pin: true,
                        pinSpacing: true,
                        horizontal: true,
                        scroller: barrelAgedRefContainer.current,
                        start: 'left -10%',
                        end: () => {return '+=' + window.innerWidth   + 'px'},
                        // end: () => "+=200%",
                        invalidateOnRefresh: true,
                    }
                });
                openCurtainTL_Vertical.to(curtainLeftRef.current, {xPercent: -150, ease: 'Power1.easeIn'}, 'openCurtain')
                    .to(curtainRightRef.current, {xPercent: 150, ease: 'Power1.easeIn'}, 'openCurtain')
                    .to(curtainMediaRef_Vertical.current, {scale: 1, ease: 'Power1.easeOut'}, 'openCurtain');
                //-------------------------------------------------------------------------------------------------------------------------------
                ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
                ScrollTrigger.refresh();
            // }
        })
    },[])

    useEffect(() => {
        setTimeout(()=>{
            // if(isProjectLoaded){
                if(onScreen){
                    setTimeout(startVideo,50)
                }else{
                    setTimeout(stopVideo,3)
                }
            // }
        })
    }, [onScreen]);

    useEffect(() => {
        setTimeout(()=>{
            // if(isProjectLoaded){
                if(onScreen_Vertical){
                    setTimeout(startVideo_Vertical,50)
                }else{
                    setTimeout(stopVide_Vertical,3)
                }
            // }
        })
    }, [onScreen_Vertical]);

    // for Horizontal Curtain
    const startVideo = async () => {
        const video = refAllFlavoursVideo.current;
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
        }
    }
    const stopVideo = async () => {
        const video = refAllFlavoursVideo.current;
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
        }
    }

    // for Vertical Curtain
    const startVideo_Vertical = async () => {
        const video = refBurningCanVideo.current;
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
        }
    }
    const stopVide_Vertical = async () => {
        const video = refBurningCanVideo.current;
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
        }
    }

    const handleRightClick = (event) =>{
        event.preventDefault()
    }

    return(
        <>
            <BarrelAgedBottomProgressBar/>
            <div className={"barrel-aged-project-page"} onContextMenu={handleRightClick}>
                <div data-scroll-container id={"barrel-aged-project-container"} ref={barrelAgedRefContainer}>
                    <section data-bgcolor="#FFF" data-textcolor="#000" data-scroll-section className={"barrel-aged-home-splash-screen"} ref={refMainSplashScreenWidget}>
                        <BrandHomeScreenWidget/>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#032f35" data-scroll-section className={"branding-and-flavours-section"}>
                        <FlavoursAndCoffeeScreen/>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#032f35" data-scroll-section  className={"product-features-section-quality"}>
                        <ProductFeaturesSection_CoffeeQuality/>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#bcb8ad" data-scroll-section className={"product-features-section-aged-barrel"}>
                        <ProductFeatures_AgedBarrel/>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#bcb8ad" data-scroll-section className={"product-features-section-ease-of-making"}>
                        <ProductFeature_EasyToMake/>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#bcb8ad" data-scroll-section className={"product-features-section-aging-techniques"}>
                        <ProductFeatures_AgingTechniques/>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#FFF" data-scroll-section   className="section--curtain section--curtain--horizontal section--curtain--vid curtain-opening-section" ref={curtainTriggerRef}>
                        <div className="floating-title-wrapper">
                            <div className="area-spacer"/>
                            <h1 className="floating-subtitle">Comes in Three Fruity Flavours</h1>
                            <h1 className="floating-title">Fruity Flavours For Punch</h1>
                        </div>
                        <div className="curtain-pin" >
                            <div className="curtain curtain--top" ref={curtainTopRef}>
                            </div>
                            <div className="curtain curtain--bottom" ref={curtainBottomRef}>
                            </div>
                            <div className="curtain-media" ref={curtainMediaRef}>
                                <video  id={"all-flavours-video_background"} playsInline preload={"true"} loop muted className={"all-flavours-video"} ref={refAllFlavoursVideo}
                                       poster="">
                                    <source src={BA_HomePage_VideoData.AAndSHomePageVideoData[2].BarrelAgedVid} type={"video/mp4"}/>
                                </video>
                            </div>
                        </div>
                    </section>
                    <section data-bgcolor="#FFF" data-textcolor="#FFF" data-scroll-section   className="section--curtain section--curtain--vertical section--curtain--vid curtain-opening-section-vertical" ref={curtainTriggerRef_Vertical}>
                        <div className="floating-title-wrapper">
                            <div className="area-spacer"/>
                            <h1 className="floating-subtitle">Its Hot</h1>
                            <h1 className="floating-title">Its Funny Lets Play The Reel</h1>
                        </div>
                        <div className="curtain-pin" >
                            <div className="curtains">
                                <div className="curtain curtain--left" ref={curtainLeftRef}>
                                </div>
                                <div className="curtain curtain--right" ref={curtainRightRef}>
                                </div>
                            </div>
                            <div className="curtain-media" ref={curtainMediaRef_Vertical}>
                                <video id={"burning-can-video_background"} src={BA_HomePage_VideoData.AAndSHomePageVideoData[3].EndingVid}  playsInline preload={"true"} loop muted className={"all-flavours-video"} ref={refBurningCanVideo}
                                       poster="">
                                </video>
                            </div>
                        </div>
                    </section>
                    <section data-bgcolor="#fff" data-textcolor="#bcb8ad" data-scroll-section className={"barrel-aged-footer"}>
                        <div className="footer-box">
                            <div className="footer-img-box">
                            </div>
                            <h1 className="buy-now">Buy</h1>
                            <h1 className="shop-now">Shop <span className="special-font">N</span>ow</h1>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
export default BarrelAgedProjectPage;


