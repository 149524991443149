import React, {useLayoutEffect, useRef} from "react";
import "./ProductFeature_EasyToMake.scss";

import useOnScreen from "../../../hooks/useOnScreen";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import BA_HomePage_ImageData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_ImageData";
gsap.registerPlugin(SplitText);

const ProductFeature_EasyToMake = () =>{


    const refTextEasyToMake = useRef(null);
    const onScreen_TextEasyToMake = useOnScreen(refTextEasyToMake);

    useLayoutEffect(() => {
        if(onScreen_TextEasyToMake){
            const h1s = document.querySelectorAll(".easy-to-make-feature-title");
            const letters = new SplitText(h1s).chars;
            [...h1s].forEach((h1) => {
                h1.style.display = 'block'
            });

            const to = gsap.from(letters, {
                autoAlpha: 0,
                y: 1000,
                rotation: 10,
                duration: 1,
                stagger: 0.05,
                ease: "power3.inOut"
            });
        }
    }, [onScreen_TextEasyToMake]);

    return(
        <div className="easy-to-make-feature">
            <div className="feature-details-pane" ref={refTextEasyToMake}>
                <div className="area-spacer"/>
                <h1 className="feature-desc">Barrel Aged comes in two forms - Ready to shake canned beverage if you like it cold or if you want to make hot brew coffee you can make it with powered form.Whichever you choose you get the same aroma and flavour.</h1>
                <h1 className="easy-to-make-feature-title" > <span className="special-font">M</span>ake it Your own way-<span className="special-font">J</span>ust <span className="special-font">D</span>o it!</h1>
            </div>
            <div className="feature-reference-content-pane">
                <div className="big-content-area">
                    <div className="image-wrapper">
                        <img src={BA_HomePage_ImageData.BAHomePageData[9].MethodOfMakingImage} alt="" className="big-product-img"/>
                    </div>
                </div>
                <div className="small-content-area">
                    <img src={BA_HomePage_ImageData.BAHomePageData[10].MethodShownImage} alt="" className="display-image"/>
                </div>
            </div>
        </div>
    )
}
export default ProductFeature_EasyToMake;
