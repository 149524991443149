import React, {useRef, useEffect, useContext} from "react";

import "./Project_Bugatti.scss";

import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../../../hooks/useOnScreen";
import {GoldenSparrowStudioCursorContext} from "../../../../Widgets/Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";
import {GSStudio_HomePageData} from "../../../../Pages/HomePage/HomePage_Data";
import {HomePage_VideoData} from "../../../../Pages/HomePage/HomePage_VideoData";

gsap.registerPlugin(SplitText);


const Project_Bugatti = () => {

    const ref = useRef(null);
    const refBugattiVideo = useRef(null);
    const onScreen = useOnScreen(ref);
    const refProjectName = useRef(null);
    const refProjectTag = useRef(null);
    const refProjectTag_First = useRef(null);
    const refProjectDesc = useRef(null);

    const {setSize, setText, setVideoSource, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);
    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.brandlogo;
    const swipeIcon = GSHOMEDATA.swipe;
    const leftClickIcon = GSHOMEDATA.leftClick;

    const handleCursorMouseEnter = (Size, Text, BlendMode,imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode) =>{
        setSize(Size)
        setText(Text)
        setImgSource("")
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    useEffect(()=>{
        setTimeout(()=>{
            if(onScreen){
                setTimeout(startVideo,800);
                let iPad = iPadRef.current;
                gsap.fromTo(iPad,
                    {
                        x: 0,
                        y: 0,
                        scale: 0.6,
                        autoAlpha: 0,
                    },{
                        x: 0,
                        y: 0,
                        scale: 1,
                        autoAlpha: 1,
                        duration: 2,
                        stagger: 0.1,
                        ease: "Quint.easeOut"
                    }
                );


                let subTitle = refProjectName.current;
                let splitSubTitle = new SplitText(subTitle, {type: "chars, words"});
                gsap.fromTo(splitSubTitle.chars,
                    {
                        rotateX:30,
                        scale:0,
                        y:50,
                        autoAlpha:0
                    },{
                        rotateX:0,
                        autoAlpha:1,
                        scale:1,
                        y:0,
                        duration: 2,
                        stagger: 0.1,
                        ease: "Elastic.easeOut"
                    }
                );

                let subTitleSmallTag = refProjectTag_First.current;
                let splitSubTitleSmallTag = new SplitText(subTitleSmallTag, {type: "chars, words"});
                gsap.fromTo(splitSubTitleSmallTag.chars,
                    {
                        x: 5,
                        y: 0,
                        autoAlpha: 0,
                    },{
                        x: 0,
                        y: 0,
                        autoAlpha: 1,
                        duration: 1,
                        stagger: 0.1,
                        ease: "Elastic.easeOut"
                    }
                );

                let subTitleSmall = refProjectTag.current;
                let splitSubTitleSmall = new SplitText(subTitleSmall, {type: "chars, words"});
                gsap.fromTo(splitSubTitleSmall.chars,
                    {
                        x: 5,
                        y: 0,
                        autoAlpha: 0,
                    },{
                        x: 0,
                        y: 0,
                        autoAlpha: 1,
                        duration: 1,
                        stagger: 0.1,
                        ease: "Elastic.easeOut"
                    }
                );

                let desc = refProjectDesc.current;
                let splitSubTitle1 = new SplitText(desc, {type: "chars, words"});
                gsap.fromTo(splitSubTitle1.chars,
                    {
                        x: 0,
                        y: 3,
                        autoAlpha: 0,
                    },{
                        x: 0,
                        y: 0,
                        autoAlpha: 1,
                        duration: 0.3,
                        stagger: 0.02,
                        ease: "Quint.easeOut",
                        delay: 0.5
                    }
                )
            }else{
                setTimeout(stopVideo,2)
            }
        })
    },[onScreen])

    const imageContainerRef = useRef(null);
    const iPadRef = useRef(null);
    const imageRef = useRef(null);


    const startVideo = async () => {
        const video =  refBugattiVideo.current //document.querySelector('#video_background_Bugatti');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = refBugattiVideo.current //document.querySelector('#video_background_Bugatti');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }

    const ParallaxImages = (e, target, movement) =>{
        let relX = e.pageX - imageContainerRef.current.getBoundingClientRect().left;
        let relY = e.pageY - imageContainerRef.current.getBoundingClientRect().top;

        gsap.to(target,  {
            x: -((relX - imageContainerRef.current.offsetWidth / 2) / imageContainerRef.current.offsetWidth * movement)*2,
            y: (relY - imageContainerRef.current.offsetHeight / 2) / imageContainerRef.current.offsetHeight * movement
        })
    }

    const handleParallaxImages = (e) =>{
        ParallaxImages(e, imageRef.current, 45);
        ParallaxImages(e, iPadRef.current, -30);
    }

    return(
        <div className="project-bugatti" ref={ref}
             onMouseEnter={() => handleCursorMouseEnter("medium","","difference",BrandLogoURI)}
             onMouseLeave={() => handleCursorMouseLeave("medium","","normal", "")}
        >
            <div className="sectionLeft">
                <h1 className="projectLabel">PROJECT</h1>
                <h1 className="ProjectName" ref={refProjectName}>Bugatti</h1>
                <h1 className="ProjectType" ref={refProjectTag_First}> Modern Frontend + Backend Website Development with</h1>
                <h1 className="ProjectType" ref={refProjectTag}>3D Product Animation</h1>
                <h1 className="ProjectDescription" ref={refProjectDesc}>A unique luxurious  product  e-commerce experience empowered with innovative approach to enhance product value by combining 3D Product animation with SPA technology in digital space.</h1>
                <a className={"ExplorerButton"} href={"https://bugatti-f05a7.web.app/"} target={"_blank"}
                   onMouseEnter={() => handleCursorMouseEnter("medium","","difference",leftClickIcon)}
                   onMouseLeave={() => handleCursorMouseLeave("medium","","normal", BrandLogoURI)}
                >
                    <h1 className="ExploreLink">Explore</h1>
                    <svg width="45" height="11" viewBox="0 0 45 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.6364 9.98889L44 5.98889M44 5.98889L39.6364 1.98889M44 5.98889H1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        </path>
                    </svg>
                    <figure className="button-circle">
                    </figure>
                </a>
            </div>
            <div className="sectionRight" ref={imageContainerRef}
                 onMouseMove={handleParallaxImages}
                 onMouseEnter={() => handleCursorMouseEnter("medium","","normal",swipeIcon)}
                 onMouseLeave={() => handleCursorMouseLeave("medium","","normal", BrandLogoURI)}
            >
                <div className="productParallaxImgBox" >
                    <img src={GSHOMEDATA.bugattiImage} alt="" className="productParallaxImg" ref={imageRef}/>
                </div>
                <div className="tabletScreen" ref={iPadRef}>
                    <video id={"video_background_Bugatti"} className="AssetVideo" loop playsInline preload={"true"}  muted ref={refBugattiVideo}>
                        <source src={HomePage_VideoData[9].bugattiProjectVideo} type="video/mp4"/>
                    </video>
                </div>
            </div>
        </div>
    )

}
export default Project_Bugatti;
