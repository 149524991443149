import React, {useEffect, useLayoutEffect, useRef} from "react";
import "./ProductFeaturesSection_CoffeeQuality.scss";

import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../../hooks/useOnScreen";
import BA_HomePage_ImageData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_ImageData";

gsap.registerPlugin(SplitText);


const ProductFeaturesSection_CoffeeQuality =() =>{


    const refTextCoffeeQuality = useRef(null);
    const onScreen_TextCoffeeQuality = useOnScreen(refTextCoffeeQuality);

    useLayoutEffect(() => {
        if(onScreen_TextCoffeeQuality){
            const h1s = document.querySelectorAll('.coffee-feature-title');
            const letters = new SplitText(h1s).chars;
            [...h1s].forEach((h1) => {
                h1.style.display = 'block'
            });

            const to = gsap.from(letters, {
                autoAlpha: 0,
                y: 1000,
                rotation: 10,
                duration: 1,
                stagger: 0.05,
                ease: "power3.inOut"
            });
        }
    }, [onScreen_TextCoffeeQuality]);

    return(
        <div className="coffee-quality-section">
            <div className="feature-details-pane" ref={refTextCoffeeQuality}>
                <div className="area-spacer"/>
                <h1 className="feature-desc">Extraordinary product requires extraordinary coffee beans.That's why we bring one of the richest coffee beans - HAWAII KONA COFFEE considered as the best coffee bean in the world.</h1>
                <h1 className="coffee-feature-title" > <span className="special-font">R</span>ich <span className="special-font">A</span>roma  Rich <span className="special-font">CO</span>ffee  <span className="special-font">B</span>eans</h1>
            </div>
            <div className="feature-reference-content-pane">
                <div className="big-content-area">
                    <div className="image-wrapper">
                        <img src={BA_HomePage_ImageData.BAHomePageData[5].BarrelAgedFluidShotVideo} alt="" className="big-product-img"/>
                    </div>
                </div>
                <div className="small-content-area">
                        <img src={BA_HomePage_ImageData.BAHomePageData[6].BestCoffeeBean} alt="" className="display-image"/>
                </div>
            </div>
        </div>
    )
}

export default ProductFeaturesSection_CoffeeQuality;
