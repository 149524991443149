import React,{useState,useRef,useEffect} from "react";
import "./FlavoursAndCoffeeScreen.scss";
import useOnScreen from "../../../hooks/useOnScreen";

import BA_HomePage_ImageData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_ImageData";
import BA_HomePage_VideoData from "../../../Pages/BarrelAgedProjectPage/DATA_BA_HomePage/BA_HomePage_VideoData";

const FlavoursAndCoffeeScreen = () =>{

    const ref = useRef(null);
    const refCoffeeVideo = useRef(null);
    const refFluidVideo = useRef(null);
    const refSecondVid = useRef(null);
    const onScreen = useOnScreen(ref);
    const onScreen2 = useOnScreen(refSecondVid);

    useEffect(() => {
        setTimeout(()=>{
            if(onScreen){
                setTimeout(startVideo,2)
            }else{
                setTimeout(stopVideo,1)
            }
        })
    }, [onScreen]);


    useEffect(() => {
        setTimeout(()=>{
            if(onScreen2){
                setTimeout(startVideo2,2)
            }else{
                setTimeout(stopVideo2,1)
            }
        })
    }, [onScreen2]);

    const startVideo = async () => {
        const video = refCoffeeVideo.current;
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = refCoffeeVideo.current;
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const startVideo2 = async () => {
        const video = refFluidVideo.current;
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo2 = async () => {
        const video = refFluidVideo.current;
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }

    return(
        <div className="flavours-and-coffee-screen">
                <div className="branding-container">
                    <div className="brand-title-container">
                        <h1 className="brand-title-shimmering">BARREL AGED</h1>
                    </div>
                    <div className="scrolling-title-container">
                        <h1 className="scrolling-title"> BARREL AGED - CAFE MOKA - FRUITY FLAVOURS - HINT OF AGED LIQUIR BARREL</h1>
                    </div>
                    <div className="brand-spacer"/>
                    <div className="brand-description-widget">
                        <h1 className="brand-description">When caffeine is brewed with slight hint of liquor and given fruity punch real magic is spelled on that cup you are holding.Nothing even comes closed to Barrel Aged when coffee matters to you.</h1>
                    </div>
                    <div className="brand-hero-image-container" >
                        <img src={BA_HomePage_ImageData.BAHomePageData[0].BigSplashImage} alt="" className="brand-hero-image-floating" data-scroll  data-scroll-direction="horizontal" data-scroll-speed="7"/>
                    </div>
                </div>
                <div className="coffee-aroma-container" >
                    <div className="tri-container">
                        <div className="still-image-box" >
                            <img src={BA_HomePage_ImageData.BAHomePageData[1].CoffeeGrindingImage} alt="" className="coffee-image"/>
                        </div>
                        <div className="mixed-box">
                            <div className="sliding-image-box">
                                <img src={BA_HomePage_ImageData.BAHomePageData[2].BarrelImage} alt="" className="sliding-image"/>
                            </div>
                            <div className="small-vid-container" ref={ref}>
                                <video id={"coffee_grinding_video"} playsInline preload={"true"} loop className={"grinding-vid"} ref={refCoffeeVideo}
                                       poster="">
                                    <source src={BA_HomePage_VideoData.AAndSHomePageVideoData[0].CoffeeGrindingVid} type={"video/mp4"}/>
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="quad-container">
                        <div className="coffee-quotes-box">
                            <div className="quote-box">
                                <h1 className="quote-txt">Each can of Barrel Aged goes through process of aging in wooden barrels that stores one of the finest wines, rum whiskey to give you fine blend and then added with fruity flavours of blueberry, green apple and oranges to give you unique blend for your taste buds.</h1>
                            </div>
                            <div className="quote-image-box">
                                <img src={BA_HomePage_ImageData.BAHomePageData[4].TwoCansImage} alt="" className="quote-image"/>
                            </div>
                        </div>
                        <div className="coffee-video-box-container">
                            <div className="coffee-video-box" ref={refSecondVid}>
                                <video id={"fluid_video"} playsInline preload={"true"} loop className={"fluid-vid"} ref={refFluidVideo}
                                       poster="">
                                    <source src={BA_HomePage_VideoData.AAndSHomePageVideoData[1].FluidShotVid} type={"video/mp4"}/>
                                </video>
                            </div>
                            <div className="coffee-image-box">
                                <img src={BA_HomePage_ImageData.BAHomePageData[3].FruityImage} alt="" className="coffee-img"/>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default FlavoursAndCoffeeScreen;
