import React from "react";


const GoldenSparrowAboutPageShownContext = React.createContext({
    isGoldenSparrowAboutPageOpen: false,
    showGoldenSparrowAboutPage: () => {},
    closeGoldenSparrowAboutPage: () => {},
})

export default GoldenSparrowAboutPageShownContext;
