import React, {useContext, useEffect, useRef, useState} from "react";
import "./CircularLoadingScreen.scss";

import {useNavigate} from "react-router-dom";


import {gsap} from "gsap";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

const CircularLoadingScreen = ({pathToRedirect}) =>{

    const refPercent = useRef(null);
    const navigate = useNavigate();


    useEffect(() => {
        setTimeout(()=>{
            var svgCircleProgress1 = document.getElementById("svgCircleProgress1");
            var svgCircleProgress1Path1 = document.getElementById("svgCircleProgressPath1");
            var tween, percentElement = false;

            var tl =gsap.timeline({duration: 1, ease: 'Power4.easeOut'})

            gsap.set(svgCircleProgress1Path1, {drawSVG:0});

            function start(){
                tween = tl.fromTo(svgCircleProgress1Path1,  {
                    drawSVG:"0"
                }, {
                    drawSVG:'0 100%',
                    onUpdate:setPercentage,
                    onComplete: redirectToAnotherRoute,
                    duration: 8
                });
            }

            function setPercentage(){
                if (!percentElement){
                    percentElement = refPercent.current;
                }
                var v = Math.round((tl.progress() * 100));
                //v /= 10; // for use when you want 0.1 % use 1000 above if you do
                percentElement.innerHTML = v + " %";
                percentElement.style.opacity = tl.progress();
            }

            function redirectToAnotherRoute(){
                // refirect to another route
                // setTimeout(start,500);
                navigate(pathToRedirect)
            }

            start();
        })
    }, []);


    return(
        <div className="circular-loading-screen-container">
            <svg id="svgCircleProgress1" className="svgloader" height="400" width="400" viewBox={"0 0 100 100"}>
                <circle id="svgCircleProgressPath1" cx="50" cy="50" r="40" stroke="#3a3530" strokeWidth="1" fill="transparent" transform="rotate(-90, 50, 50)" />
            </svg>
            <div id="percent" ref={refPercent}>0%</div>
            <h1 className="progress-ring-title">GOLDEN SPARROW STUDIO</h1>
            <h1 className="progress-ring-title">Loading...</h1>
        </div>
    )
}

export default CircularLoadingScreen;
