
const FollowUs_Data = {
   "ART_FORUM_DETAILS" : [
       {
           "id": 0,
           index: 0,
           forumName: "CG Society",
           forumLink: "https://goldensparrow88.cgsociety.org/fwn9/time-pyramid-3d-prod",
           forumImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FCrystals.png?alt=media&token=f5cf374f-be27-4be6-b125-712e9b8d3435"
       },
       {
           "id": 1,
           index: 1,
           forumName: "Art Station",
           forumLink: "https://www.artstation.com/artwork/X1Y020",
           forumImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FCrystal_Iceburg_Master2.png?alt=media&token=e46aceb6-a021-4f0b-a22c-4cee88e28007"
       },
       {
           "id": 2,
           index: 2,
           forumName: "Behance",
           forumLink: "https://www.behance.net/gallery/143823187/Time-Pyramid",
           forumImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FCrystal_Iceburg.png?alt=media&token=165753d1-c889-49a2-a5bd-abb2cd0b3e1b"
       },
       {
           "id": 3,
           index: 3,
           forumName: "3D Total",
           forumLink: "https://3dtotal.com/galleries/lytkn/time-pyramid",
           forumImage: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/images%2FMermaid.png?alt=media&token=31a01f68-d067-49cd-b1a9-a0db382aa49d"
       }
   ]
}

export default FollowUs_Data;
