import React,{useState} from "react";
import "./FollowUs.scss";
import ArtBlock from "./ArtBlock/ArtBlock";
import FollowUs_Data from "./FollowUs_Data";
import {GSStudio_HomePageData} from "../../Pages/HomePage/HomePage_Data";


const FollowUs = () =>{

    const [forumData,setForumData] = useState(FollowUs_Data.ART_FORUM_DETAILS);

    return(
        <div className="follow-us-widget">
            <div className="spacer"/>
            <div className="header-section">
                <h1 className="title-header">Our Artwork is Online</h1>
            </div>
            <div className="art-gallary-section">
                {
                    forumData.map(eachForum =>{
                        return(
                            <ArtBlock key={eachForum.id} forumData={eachForum}/>
                        )
                    })
                }
            </div>
            <div className="grid-section">
                <div className="grid-left-part">
                    <img src={GSStudio_HomePageData[0].crystalsIceburg} alt="" className="youtube-img"/>
                    <a className="youtube-link" target={"_blank"}>Youtube</a>
                </div>
                <div className="grid-right-part">
                    <div className="art-circle-group">
                        <h1 className="social-network-header">Follow us on</h1>
                    </div>
                    <div className="social-media-row">
                        <a className="social-media-block" href='https://twitter.com/gscreative556' target="_blank">Twitter</a>
                        <a className="social-media-block" href='https://www.facebook.com/profile.php?id=100081025428884' target="_blank">Facebook</a>
                        <a className="social-media-block" href='https://www.instagram.com/goldensparrowstudio.in/' target="_blank">Instagram</a>
                        {/*<div className="social-media-block">LinkedIn</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FollowUs;



// https://www.artstation.com/sagarmitkari9
// https://goldensparrow88.cgsociety.org/
// https://www.behance.net/sagarmitkari1
