import React, {useContext, useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';
import "locomotive-scroll/src/locomotive-scroll.scss";
import "./HomePage.scss";
import {Email} from "@styled-icons/material-outlined/Email";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//----------------------------------------------------------------------------------------------------------------------
 //Please Refer to https://codesandbox.io/s/lively-sunset-9ifwq?file=/src/App.js to resolve all the react gsap.toArray issues
//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import LocomotiveScroll from "locomotive-scroll";
import SplashScreen from "../../Widgets/SplashScreen/SplashScreen";
import BrandValuesWidget from "../../Widgets/BrandValuesWidget/BrandValuesWidget";
import PersonalIntroWidget from "../../Widgets/PersonalIntroWidget/PersonalIntroWidget";
import WideContentListWidget from "../../Widgets/WideContentListWidget/WideContentListWidget";
import ProjectExplorerWidget from "../../Widgets/ProjectExplorerWidget/ProjectExplorerWidget";
import ProjectsDisplayPlayground from "../../Widgets/ProjectsDisplayPlayground/ProjectsDisplayPlayground";
import UniversalNavBar from "../../components/UniversalNavBar/UniversalNavBar";
import TechnologyPad from "../../Widgets/TechnologyPad/TechnologyPad";
import AllProjectsDisplaySection from "../../components/AllProjectsDisplaySection/AllProjectsDisplaySection";
import DesignImportanceScreen from "../../Widgets/DesignImportanceScreen/DesignImportanceScreen";
import FontFollowCursor from "../../components/FontFollowCursor/FontFollowCursor";
import IntroRotatingVideoTransition from "../../components/IntroRotatingVideoTransition/IntroRotatingVideoTransition";
import useOnScreen from "../../hooks/useOnScreen";
import OurServices from "../../Widgets/OurServices/OurServices";
import OurExpertise from "../../Widgets/OurExpertise/OurExpertise";
import ClientsList from "../../Widgets/ClientsList/ClientsList";
import LetsConnect from "../../Widgets/LetsConnect/LetsConnect";
import GoldenSparrowAboutPageShownContext from "../../Context/GoldenSparrowAboutPageShownContext";
import GoldenSparrowPageTransition
    from "../../Widgets/PageTransition/GoldenSparrowPageTransition/GoldenSparrowPageTransition";
import GoldenSparrowAboutPageModal
    from "../../Widgets/ContextWidgets/GoldenSparrowAboutPageModal/GoldenSparrowAboutPageModal";
import styled from "styled-components";
import {GoldenSparrowStudioCursorContext} from "../../Widgets/Cursors/GoldenSparrowStudio_CustomCursor/GoldenSparrowStudioCursorManager";

//-----------------------------------------------------------------------------------------------------------------------------------------------
    //Project Data---------------------------------------------------------
import {GSStudio_HomePageData} from "./HomePage_Data";
import {HomePage_VideoData} from "./HomePage_VideoData";
//-----------------------------------------------------------------------------------------------------------------------------------------------
gsap.registerPlugin(ScrollTrigger, SplitText);
//----------------------------------------------------------------------------------------------------------------------


const MailIcon = styled(Email)`
      color: black;
      width: 4vw;
`;

const HomePage = () => {

    const ctx = useContext(GoldenSparrowAboutPageShownContext);
    const [isAboutPageOpen,setIsAboutPageOpen] = useState(false);
    const GSHOMEDATA = GSStudio_HomePageData[0];
    const BrandLogoURI = GSHOMEDATA.gsBrandLogoArrowH;
    const MagicIconURI = GSHOMEDATA.magicIcon;
    const SendEmailIcon = GSHOMEDATA.sendEmail;
    const LeftClickIcon = GSHOMEDATA.leftClick;
    const navigateToSocialMedia = useNavigate();

    const mainContainerRef = useRef(null);
    const refTechSection = useRef(null);
    const refRollingTitle = useRef(null);
    const refFooterVideoSection = useRef(null);
    const refFooterVideo = useRef(null);

    const onScreenTechSection = useOnScreen(refTechSection)
    const onScreenFooterSection = useOnScreen(refFooterVideoSection)

    const refCopyEmailAddress = useRef(null);

    let locoScroll = null;
    const vh = (coef) => window.innerHeight * (coef/100);

    const handleRouteToSocialMediaSite = (site) =>{
        navigateToSocialMedia(site)
    }

    const notifySuccess = () =>{
        toast.success('Email Copied!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const notifyFailed = () =>{
        toast.error('Failed to Copy Email.Click Again.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const { setSize, setText, setVideoSource, setImgSource, setBlendMode } = useContext(GoldenSparrowStudioCursorContext);

    const handleCursorMouseEnter = (Size, Text, BlendMode, imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setBlendMode(BlendMode)
    }
    const handleCursorMouseLeave = (Size, Text, BlendMode, imageSource) =>{
        setSize(Size)
        setText(Text)
        setImgSource(imageSource)
        setVideoSource("")
        setBlendMode(BlendMode)
    }

    const handleGoldenSparrowAboutPageOpenStatus  = () =>{
        if(ctx.isGoldenSparrowAboutPageOpen){
            ctx.closeGoldenSparrowAboutPage();
            setIsAboutPageOpen(prevState => !prevState);
            // setPlayMenuCurtain( prevState => !prevState)
        }else{
            ctx.showGoldenSparrowAboutPage()
            setIsAboutPageOpen(prevState => !prevState);
        }
    }

    useEffect(() => {
        setTimeout(()=>{
            if(onScreenFooterSection){
                setTimeout(startVideo,5)
            }else {
                setTimeout(stopVideo,1)
            }
        })
    }, [onScreenFooterSection]);

    useEffect(() => {
        if(onScreenTechSection){
            var tlAnimText = gsap.timeline({paused:true, duration: 3, ease: "Power4.easeInOut"}),
                techSplitText = new SplitText(refRollingTitle.current, {type:"words,chars"}),
                techchars = techSplitText.chars; ///an array of all the divs that wrap each character
            tlAnimText
                .from(techchars,  { y: gsap.utils.wrap([-50, 100]),  rotation:  gsap.utils.wrap([-360, 180, 360]), transformOrigin: "0% 50% -50", ease: 'power.inOut', duration: 2, stagger: -0.01 }, 0)
                .fromTo(refRollingTitle.current , { yPercent: 301},{yPercent: 0,  ease: 'power.inOut',duration: 3 }, 0)
            tlAnimText.play()
        }
    }, [onScreenTechSection]);

    const startVideo = async () => {
        const video = document.querySelector('#footer-video');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }
    const stopVideo = async () => {
        const video = document.querySelector('#footer-video');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {
            // do stuff in case your video is unavailable to play/autoplay
        }
    }

    useEffect(() => {
        setTimeout(()=>{
            const scroller = mainContainerRef.current;
            locoScroll = new LocomotiveScroll({
                el: scroller,
                multiplier: 10,
                smooth: true,
                direction: "horizontal",
                lerp: 0.03,
                smartphone: {
                    smooth: true,
                    direction: "horizontal",
                },
                tablet: {
                    smooth: true,
                    direction: "horizontal",
                }
            });
            locoScroll.on("scroll", ScrollTrigger.update);
            setTimeout(()=> {
                locoScroll.update();
            },100)

            ScrollTrigger.scrollerProxy(scroller, {
                 scrollTop(value) {
                   return arguments.length
                     ? locoScroll.scrollTo(value, 0, 0)
                     : locoScroll.scroll.instance.scroll.y;
                 },
                scrollLeft(value) {
                    return arguments.length
                        ? locoScroll.scrollTo(value, 0, 0)
                        : locoScroll.scroll.instance.scroll.x;
                },
                getBoundingClientRect() {
                    return {
                        left: 0,
                        top: 0,
                        width: window.innerWidth,
                        height: window.innerHeight
                    };
                },
                pinType: scroller.style.transform ? "transform" : "fixed"
            });

            ScrollTrigger.defaults({
                scroller: scroller
            })
            gsap.set('section', { backgroundColor: (index, target) => { return target.getAttribute('data-bgcolor') },
                color: (index, target) => { return target.getAttribute('data-textcolor') }})

            gsap.to('progress', {
                value: 100,
                ease: 'none',
                scrollTrigger: {
                    trigger: "#main-container",
                    scrub: 0.3,
                    horizontal: true,
                }
            });
            gsap.to(".second-wrap", {
                scrollTrigger: {
                    trigger: ".first-trigger",
                    start: () => "left left",
                    end: () => "+=" + document.querySelector('.second-wrap').scrollWidth*1.85,
                    pin: false,
                    pinSpacing: false,
                    // anticipatePin: 1,
                    scrub: true,
                    horizontal: true,
                    invalidateOnRefresh: true,
                    // markers: true
                },
                x: () => { return (document.querySelector('.second-wrap').scrollWidth - window.innerWidth) },
                ease: "none"
            });

            gsap.to(".third-wrap", {
                scrollTrigger: {
                    trigger: ".second-trigger",
                    start: () => "left left",
                    end: () => "+=" + document.querySelector('.third-wrap').scrollWidth,
                    pin: false,
                    pinSpacing: false,
                    // anticipatePin: 1,
                    scrub: true,
                    horizontal: true,
                    invalidateOnRefresh: true,
                    // markers: true
                },
                x: () => { return (document.querySelector('.third-wrap').scrollWidth - window.innerWidth) },
                ease: "none"
            });

            gsap.to(".anim-wrap", {
                scrollTrigger: {
                    trigger: ".vertical",
                    start: () => "left left",
                    end: () => "+=" + document.querySelector('.anim-wrap').scrollHeight,
                    pin: true,
                    pinSpacing: true,
                    // anticipatePin: 1,
                    scrub: true,
                    horizontal: true,
                    invalidateOnRefresh: true
                    //markers: true
                },
                y: () => { return -(document.querySelector('.anim-wrap').scrollHeight - window.innerHeight) },
                ease: "none"
            });

            gsap.to(".second-anim-wrap", {
                scrollTrigger: {
                    trigger: ".second-vertical",
                    start: () => "left left",
                    end: () => "+=" + document.querySelector('.second-anim-wrap').scrollHeight,
                    pin: true,
                    pinSpacing: true,
                    // anticipatePin: 1,
                    scrub: true,
                    horizontal: true,
                    invalidateOnRefresh: true
                    //markers: true
                },
                y: () => { return -(document.querySelector('.second-anim-wrap').scrollHeight - window.innerHeight) },
                ease: "none"
            });

            gsap.set(".ver-section .inner-container .layered", {zIndex: (i, target, targets) => targets.length - i});

            gsap.utils.toArray('[data-section-parallax] [data-scroll-section-inner]').forEach(sectionParallax => {
                // locoScroll.direction = "vertical"
                ScrollTrigger.create({
                    trigger: sectionParallax,
                    start: "left left",
                    end: '+=100%',
                    pin: true,
                    pinSpacing: false,
                    horizontal: true,
                    scroller: '[data-scroll-container]',
                    pinType: 'transform',
                });
            });

            gsap.to(".scrolling-panel", {
                y: vh(-170),
                ease: "none",
                scrollTrigger: {
                    trigger: "#last-panel-to-scroll",
                    start: "left left",
                    end: "+=100%",
                    scrub: true,
                    pin: true,
                    pinSpacing: true,
                    horizontal: true,
                    scroller: '[data-scroll-container]',
                }
            });
            ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
            ScrollTrigger.refresh();
        })
    }, []);

    const handleCopyToClipboard = async() => {
        if(!refCopyEmailAddress.current) return;
            const text = refCopyEmailAddress.current.textContent;
        try{
            await navigator.clipboard.writeText(text);
            await notifySuccess()
        }catch (error){
            await notifyFailed()
        }
    }

    const handleRightClick = (event) =>{
        event.preventDefault()
    }

    useEffect(() => {
            document.addEventListener('contextmenu', handleRightClick);
    },[]);

    return(
        <>
            <UniversalNavBar/>
            <progress max="100" value="0">
            </progress>
            <IntroRotatingVideoTransition/>
            <div data-scroll-container id={"main-container"} ref={mainContainerRef}>
                <div className="content">
                    <section data-bgcolor="#032f35" data-textcolor="#bcb8ad" className={"first-wrap"}>
                        <SplashScreen/>
                    </section>
                    <section data-bgcolor="#bcb8ad" data-textcolor="#032f35" className={"second-wrap"}>
                        <BrandValuesWidget/>
                        <div className="patcher second-trigger">
                        </div>
                    </section>
                    <section data-bgcolor="#032f35" data-textcolor="#bcb8ad" className={"third-wrap"}>
                        <PersonalIntroWidget/>
                    </section>
                    <section data-bgcolor="#032f35" data-textcolor="#bcb8ad" className={"forth-wrap"}>
                        <WideContentListWidget/>
                    </section>
                    <section data-bgcolor="#fff7eb" data-textcolor="#bcb8ad" className="vertical">
                        <div className="anim-wrap">
                            <div className="inner-section first-vertical-inner">
                                <ProjectExplorerWidget/>
                            </div>
                            <div className="inner-section center">
                                <AllProjectsDisplaySection/>
                                <div className="bottom-text-section-vertical-panel-first-batch"  ref={refTechSection}
                                     onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",BrandLogoURI)}
                                     onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                                >
                                    <h1 className="tech-message" ref={refRollingTitle}>TECHNOLOGIES</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-bgcolor="#bcb8ad" data-textcolor="#032f35" className={"continued-horizontal-section-2 technologies-section-wrap"}>
                        <TechnologyPad/>
                    </section>
                    <section data-bgcolor="#032f35" data-textcolor="#bcb8ad" className={"continued-horizontal-section-2 reveal-idea-block"}>
                        <DesignImportanceScreen/>
                    </section>
                    <section data-bgcolor="#bcb8ad" data-textcolor="#032f35" className={"continued-horizontal-section-2 last-horizontal-area"}>
                        <div className="served-them-container" >
                            <h1 className="served-them-text">We Worked</h1>
                            <h1 className="served-them-text2">FOR</h1>
                            <h1 className="served-them-text3">THEM</h1>
                        </div>
                    </section>
                    <section className={"ver-section red"}   data-section-parallax>
                        <div data-scroll-section-inner className={"inner-container"}>
                            <div className={"layered"}>
                                <ClientsList/>
                            </div>
                        </div>
                    </section>
                    <section className={"ver-section green"}   data-section-parallax>
                        <div data-scroll-section-inner className={"inner-container"}>
                           <div className="layered">
                               <OurServices/>
                           </div>
                        </div>
                    </section>
                    <section  className={"ver-section blue"}  data-section-parallax>
                        <div data-scroll-section-inner className={"inner-container"}>
                            <div className={"layered"}>
                                <OurExpertise/>
                            </div>
                        </div>
                    </section>
                    <section data-bgcolor="#fff" data-textcolor="#bcb8ad" className="second-vertical">
                        <div className="second-anim-wrap">
                            <div className="inner-section lets-connect-section">
                                <LetsConnect/>
                            </div>
                        </div>
                    </section>
                    <section data-bgcolor="#032f35" data-textcolor="#bcb8ad" className={"continued-horizontal-section-2 footer-section-GSS"}>
                         {/*<GoldenSparrowPageTransition timeline={GoldenSparrowPageTransitionTl}/>*/}
                        <div className="inner-section last-panel" id={"last-panel-to-scroll"}>
                            <div className="wrapper">
                                <div className="footer-header-section">
                                </div>
                                <div className="scrolling-panel" >
                                    <div className="left-footer-section">
                                        <div className="line1">
                                            <h1 className="text">GET IN</h1>
                                        </div>
                                        <div className="line2">
                                            <img src={GSHOMEDATA.GSBrandLogo} alt="" className="logo"/>
                                            <h1 className="text-2">TOUCH</h1>
                                        </div>
                                        <div className="contact-email-row">
                                            <MailIcon/>
                                            <div className="email-interaction" ref={refCopyEmailAddress} onClick={handleCopyToClipboard}
                                                 onMouseEnter={()=> handleCursorMouseEnter("medium","","normal",SendEmailIcon)}
                                                 onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                                            >
                                                studio.goldensparrow@gmail.com
                                                <ToastContainer
                                                    position="bottom-center"
                                                    autoClose={5000}
                                                    hideProgressBar={false}
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    pauseOnFocusLoss
                                                    draggable
                                                    pauseOnHover
                                                    bodyClassName={"toastBody"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-footer-section">
                                        <div className="contact-grid">
                                            <div className="contact-row">
                                                <div className="grid-block">
                                                    <h1 className="head-line">CONTACT US</h1>
                                                    <h1 className="text-link">studio.goldensparrow@gmail.com</h1>
                                                    <h1 className="text-line">+91-9307688492</h1>
                                                </div>
                                                <div className="grid-block"
                                                     onMouseEnter={()=> handleCursorMouseEnter("medium","","difference",LeftClickIcon)}
                                                     onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                                                >
                                                    <h1 className="head-line">FOLLOW US</h1>
                                                    <a className="text-link" href='https://twitter.com/gscreative556' target="_blank">Twitter</a>
                                                    <a className="text-link" href='https://www.facebook.com/profile.php?id=100081025428884' target="_blank">Facebook</a>
                                                    <a className="text-link" href='https://www.instagram.com/goldensparrowstudio.in/' target="_blank">Instagram</a>
                                                </div>
                                            </div>
                                            <div className="contact-row">
                                                <div className="grid-block"
                                                     onMouseEnter={()=> handleCursorMouseEnter("medium","","difference",LeftClickIcon)}
                                                     onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                                                >
                                                    <h1 className="head-line">TRENDING ON</h1>
                                                    <a className="text-link" target={"_blank"}>Youtube</a>
                                                    <a className="text-link" href={"https://www.behance.net/sagarmitkari1"} target={"_blank"}>Behance</a>
                                                    <a className="text-link" href={"https://www.artstation.com/sagarmitkari9"} target={"_blank"}>ArtStation</a>
                                                    <a className="text-link" href={"https://dribbble.com/goldensparrowstudio"} target={"_blank"}>Dribble</a>
                                                    <a className="text-link" href={"https://in.pinterest.com/studiogoldensparrow"} target={"_blank"}>Pinterest</a>
                                                    <a className="text-link" href={"https://goldensparrow88.cgsociety.org/"} target={"_blank"}>CGSociety</a>
                                                    <a className="text-link" href={"https://3dtotal.com/goldensparrowstudio"} target={"_blank"}>3DTotal</a>
                                                </div>
                                                <div className="grid-block">
                                                    <h1 className="head-line">LOCATION</h1>
                                                    <h1 className="text-link">Plot No 10, Karve Nagar</h1>
                                                    <h1 className="text-link">Pune - 411052, India</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fixed-panel">
                                    <div className="footer-spacer"/>
                                    <div className="footer-video-section" ref={refFooterVideoSection}>
                                        <video src={HomePage_VideoData[0].godRaysVideo} playsInline={true} id={"footer-video"}
                                               webkit-playsinline="true" preload="auto" muted="muted" loop
                                               className="footer-video-background" ref={refFooterVideo}>
                                        </video>
                                    </div>
                                    <div className="footer-branding-section">
                                        <div className="important-links-section">
                                            <div className="left-section">
                                            </div>
                                            <div className="right-section"
                                                 onMouseEnter={()=> handleCursorMouseEnter("medium","","difference",LeftClickIcon)}
                                                 onMouseLeave={()=> handleCursorMouseLeave("medium","","normal",BrandLogoURI)}
                                            >
                                                <div className="row-container">
                                                    <h1 className="text-link" onClick={handleGoldenSparrowAboutPageOpenStatus}>About US</h1>
                                                </div>
                                                <div className="row-container">
                                                    <h1 className="text-link">Get an Estimate</h1>
                                                </div>
                                                <div className="row-container">
                                                    <h1 className="text-link">Our Services</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer-brand-logo">
                                            <img src={GSHOMEDATA.GSBrandLogo} alt="" className="brand-logo-footer"/>
                                            <h1 className="footer-brand-name">Golden Sparrow Studio</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-bgcolor="#5ed75c" data-textcolor="#bcb8ad" className={"continued-horizontal-section-2 section-ending"}>
                        <div>
                            <h1><span>Horizontal</span> <span>Scroll</span> <span>Section</span></h1>
                            <p>with Locomotive Scroll</p>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
//data-scroll data-scroll-direction="vertical" data-scroll-speed="20" data-scroll-target="#last-panel-to-scroll"
export default HomePage;
