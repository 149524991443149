import React from "react";


const GoldenSparrowAnimationReelShownContext = React.createContext({
    isGoldenSparrowAnimationReelOpen: false,
    showGoldenSparrowAnimationReel: () => {},
    closeGoldenSparrowAnimationReel: () => {},
})

export default GoldenSparrowAnimationReelShownContext;
