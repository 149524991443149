import React, {useState, useEffect, useRef, useContext} from "react";
import GoldenSparrowMainMenuModal from "./GoldenSparrowMainMenuModal/GoldenSparrowMainMenuModal";
import "./GoldenSparrowMainMenu.scss";
import GoldenSparrowMainMenuShownContext from "../../Context/GoldenSparrowMainMenuShownContext";
import BrandLogoVideo from "../../assets/videos/Golden_Sparrow_Logo.mp4";


//GSAP IMPORTS----------------------------------------------------------------------------------------------------------
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import useOnScreen from "../../hooks/useOnScreen";
import GoldenSparrowPageTransition from "../PageTransition/GoldenSparrowPageTransition/GoldenSparrowPageTransition";
import GoldenSparrowAboutPageShownContext from "../../Context/GoldenSparrowAboutPageShownContext";
import GoldenSparrowContactUsPageShownContext from "../../Context/GoldenSparrowContactUsPageShownContext";
gsap.registerPlugin(ScrollTrigger, SplitText);

//----------------------------------------------------------------------------------------------------------------------



const GoldenSparrowMainMenu = () =>{

    const ctx = useContext(GoldenSparrowMainMenuShownContext);
    const ctx_About = useContext(GoldenSparrowAboutPageShownContext);
    const ctx_ContactUS = useContext(GoldenSparrowContactUsPageShownContext)

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);

    const GoldenSparrowPageTransitionTl = gsap.timeline();

    useEffect(() => {
        setTimeout(()=>{
            if(onScreen){
                setTimeout(startVideo,10);
            }else{
                setTimeout(stopVideo,10)
            }
        })
    }, [onScreen]);

    const startVideo = async () => {
        const video = document.querySelector('#video_background_for_GSSMenu');
        try {
            await video.load();
            await video.play();
            video.setAttribute('autoplay', "true");
        } catch (err) {

        }
    }
    const stopVideo = async () => {
        const video = document.querySelector('#video_background_for_GSSMenu');
        try {
            await video.load();
            await video.pause();
            video.currentTime = 0;
            video.setAttribute('autoplay', "false");
        } catch (err) {

        }
    }
    const handleGoldenSparrowAboutPageOpenStatus  = () =>{
        if(ctx_About.isGoldenSparrowAboutPageOpen){
            ctx_About.closeGoldenSparrowAboutPage();
        }else{
            ctx.closeGoldenSparrowMainMenu()
            ctx_About.showGoldenSparrowAboutPage()
        }
    }

    const handleGoldenSparrowContactUsPageStatus = () =>{
        if(ctx_ContactUS.isGoldenSparrowContactUsPageOpen){
            ctx_ContactUS.closeGoldenSparrowContactUsPage()
        }else{
            ctx.closeGoldenSparrowMainMenu()
            ctx_ContactUS.showGoldenSparrowContactUsPage()
            console.log("closing!!!")

        }
    }


    return(
        <GoldenSparrowMainMenuModal>
            <GoldenSparrowPageTransition timeline={GoldenSparrowPageTransitionTl}/>
            <div  className="golden-sparrow-main-menu" ref={ref}>
                <video id={"video_background_for_GSSMenu"}  playsInline muted loop preload={"true"} className={"video_bg_for_GSSMenu"}
                       poster="">
                    <source src={BrandLogoVideo}/>
                </video>
                <div className="menu-options-container">
                    <div className="main-links-container">
                        {/*<h1 className="menu-link" >St<span className="special">o</span>ry</h1>*/}
                        {/*<h1 className="menu-link" id={"toStroke"}><span className="special">S</span>ervices</h1>*/}
                        {/*<h1 className="menu-link" id={"toStroke1"}> <span className="special" >Our </span><span className="special-no-rotation">P</span>rojects</h1>*/}
                        <h1 className="menu-link" onClick={handleGoldenSparrowAboutPageOpenStatus}><span className="special">A</span>bout  us</h1>
                    </div>
                    <div className="sub-links-container">
                        {/*<h1 className="menu-link"><span className="special">L</span>ets work together</h1>*/}
                        {/*<h1 className="menu-link">Learn <span className="special">O</span>ur <span className="special">P</span>rocess</h1>*/}
                        <h1 className="menu-link" onClick={handleGoldenSparrowContactUsPageStatus}>Contact Us</h1>
                        {/*<h1 className="menu-link">Our art & Social Network</h1>*/}
                    </div>
                </div>
            </div>
        </GoldenSparrowMainMenuModal>
    )
}

export default GoldenSparrowMainMenu;
