import React from "react";


const GoldenSparrowMainMenuShownContext = React.createContext({
    isGoldenSparrowMainMenuOpen: false,
    showGoldenSparrowMainMenu: () => {},
    closeGoldenSparrowMainMenu: () => {},
})

export default GoldenSparrowMainMenuShownContext;
