

export const HomePage_VideoData = [
    {
        id: 0,
        godRaysVideo: "https://firebasestorage.googleapis.com/v0/b/barrelaged-db9ed.appspot.com/o/videos%2FGodRays.mp4?alt=media&token=6cd0882d-c98b-47ad-811e-27bd200a6d5a"
    },
    {
        id: 1,
        barrelAgedReel: "https://firebasestorage.googleapis.com/v0/b/barrelaged-410d9.appspot.com/o/videos%2FBarrel_QuadHD.mp4?alt=media&token=4ccc39d7-3757-4fb5-b822-160ff7fbc648"
    },
    {
        id: 2,
        aandsReel: "https://firebasestorage.googleapis.com/v0/b/aands-f0d61.appspot.com/o/videos%2FAAndSReel_MedQuality.mp4?alt=media&token=e15721d2-6516-4455-9140-50a50d22a59f"
    },
    {
        id:3,
        greenNebula: "https://firebasestorage.googleapis.com/v0/b/goldensparrow-2a1bc.appspot.com/o/videos%2FGreenNebula.mp4?alt=media&token=c1c10e32-d753-416b-93f4-0e4dbef73e5b"
    },
    {
        id:4,
        IntroSplashGSLogo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/videos%2FIntroSplash_GSLogo.mp4?alt=media&token=92b75109-15b6-4f6b-864a-ae71c921df11"
    },
    {
        id:5,
        goldenSparrowLogoVideo: "https://firebasestorage.googleapis.com/v0/b/goldensparrowstudio-87a37.appspot.com/o/videos%2FGolden_Sparrow_Logo.mp4?alt=media&token=1f3d19ec-9de0-4a2e-ba99-5907591d30ea"
    },
    {
        id:6,
        barrelAgedProjectVideo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/videos%2FAAndSon_FullHD_IPad0001-0943.mp4?alt=media&token=7a38e6b9-4ade-49c0-8f6e-060f93e2524a"
    },
    {
        id:7,
        aandsProjectVideo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-8ffbc.appspot.com/o/videos%2FAAndS_IPad.webm?alt=media&token=535c61cf-1ae1-4a6e-be65-fe0d336d18f0"
    },
    {
        id:8,
        sfaProjectVideo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-bf173.appspot.com/o/videos%2FSFA_IPad.webm?alt=media&token=ac52316a-2712-4f37-aff5-45bc64319c2b"
    },
    {
        id:9,
        bugattiProjectVideo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-bf173.appspot.com/o/videos%2FBugatiIPad.mp4?alt=media&token=fc9d053e-7cae-4280-b5c2-642bcd0fff14"
    },
    {
        id:10,
        alafutProjectVideo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-49f47.appspot.com/o/videos%2FAlafutIPad.mp4?alt=media&token=ea5f7189-034d-4b7c-8439-39aefb4f86af"
    },
    {
        id:11,
        brainVideo: "https://firebasestorage.googleapis.com/v0/b/gsstudio-1022f.appspot.com/o/videos%2FBrain_1.mp4?alt=media&token=43750592-acd9-4bc5-a32a-8d2a20d24f23"
    }

]
