import {createContext} from "react";
import React,{useState} from "react";


export const GoldenSparrowStudioCursorContext = createContext({
    size: "small",
    imgSource: "",
    text: "GS",
    videoSource: "",
    blendMode: "",
    setSize: ()=>{}
})


export default  function GoldenSparrowStudioCursorManager(props){

    const [size, setSize] = useState("small");
    const [text, setText] = useState("GS");
    const [imgSource, setImgSource] = useState("");
    const [videoSource, setVideoSource] = useState("");
    const [blendMode, setBlendMode] = useState("difference");
    const [filterImplementation, setFilterImplementation] = useState("");

    return(
        <GoldenSparrowStudioCursorContext.Provider value={{
            size,setSize,
            text, setText,
            imgSource, setImgSource,
            videoSource, setVideoSource,
            blendMode, setBlendMode,
            filterImplementation, setFilterImplementation
        }}>
            {props.children}
        </GoldenSparrowStudioCursorContext.Provider>
    );

}


